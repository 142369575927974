* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --blogPagePrimary: #fda400;
  --blogPageSecondary: #101012;
}

.blog-page-header-container {
  display: flex;
  gap: 95px;
  padding-top: 95px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 70px;
}
.blog-page-header-title {
  text-align: center;
}

.blog-page-orange-color {
  color: var(--blogPagePrimary);
  font-weight: 600;
  line-height: 24px;
}
.blog-page-title {
  font-size: 48px;
  font-weight: 600;
  color: var(--blogPageSecondary);
}
.blog-page-sub-title {
  font-size: 20px;
  color: var(--blogPageSecondary);
}

/* blog page card container start  */
.blog-page-card-container {
  width: 64%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px 32px;
}
.blog-page-card-nameAndDate {
  color: var(--blogPagePrimary);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.blog-page-card-title {
  color: var(--blogPageSecondary);
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
}
.blog-page-card-subtitle {
  color: var(--blogPageSecondary);
  line-height: 24px;
}
.blog-page-card-category-btn1 {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #b9e6fe;
  background: #f0f9ff;
}
.blog-page-card-category-btn2 {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #fcceee;
  background: #fdf2fa;
}
.blog-page-card-category-btn3 {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #e9d7fe;
  background: #f9f5ff;
}
/* blog page card container End  */
.blog-page-newsletter-container {
  width: 65%;
  margin: 96px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-page-newsletter-subscribe-btn {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 20px;
  background-color: #31449b;
  border: 1px solid #31449b;
  border-radius: 6px;
}
