.fs-10 {
  font-size: $fs-10;
}
.fs-12 {
  font-size: $fs-12;
}
.fs-14 {
  font-size: $fs-14;
}

.fs-16 {
  font-size: $fs-16;
}

.fs-17 {
  font-size: $fs-17;
}

.fs-18 {
  font-size: $fs-18;
}

.fs-20 {
  font-size: $fs-20;
}
.fs-22 {
  font-size: $fs-22;
}

.fs-23 {
  font-size: $fs-23;
}
.fs-24 {
  font-size: $fs-24;
}

.fs-26 {
  font-size: $fs-26;
}

.fs-29 {
  font-size: $fs-29;
}
.fs-34 {
  font-size: $fs-34;
}

.fs-38 {
  font-size: $fs-38;
}

.fs-44 {
  font-size: $fs-44;
}

.fs-58 {
  font-size: $fs-58;
}

.fw-400 {
  font-weight: $fw-400;
}

.fw-500 {
  font-weight: $fw-500;
}

.fw-600 {
  font-weight: $fw-600;
}

.fw-700 {
  font-weight: $fw-700;
}

.ff-satisfy {
  font-family: $ff-satisfy;
}

.color-blue-black {
  color: $cl-blue-black;
}

.bg-light-black {
  background-color: $cl-light-black;
}

.bg-blue-black {
  background-color: $cl-blue-black;
}
.bg-white {
  background-color: $cl-white;
}
.bg-light-white {
  background-color: $bg-light-white;
}

.color-blue {
  color: $cl-blue;
}

.color-white {
  color: $cl-white;
}

.color-black {
  color: $cl-black;
}
.color-light-black {
  color: $cl-light-black;
}

.color-orange {
  color: $cl-orange;
}
.color-gray {
  color: $cl-gray;
}
.txt-gray {
  color: $txt-gray;
}

.bg-orange {
  background-color: $cl-orange;
}

.bg-light-orange {
  background-color: $cl-orange;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 400px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 12px;
  }
  .fs-16 {
    font-size: 14px;
  }
  .fs-17 {
    font-size: 14px;
  }
  .fs-18 {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 16px;
  }
  .fs-22 {
    font-size: 16px;
  }
  .fs-23 {
    font-size: 13px;
  }
  .fs-24 {
    font-size: 18px;
  }
  .fs-26 {
    font-size: 16px;
  }
  .fs-29 {
    font-size: 16px;
  }
  .fs-34 {
    font-size: 18px;
  }
  .fs-38 {
    font-size: 20px;
  }
  .fs-44 {
    font-size: 25px;
  }
  .fs-58 {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 14px;
  }
  .fs-17 {
    font-size: 14px;
  }
  .fs-18 {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 16px;
  }
  .fs-22 {
    font-size: 18px;
  }
  .fs-23 {
    font-size: 13px;
  }
  .fs-24 {
    font-size: 20px;
  }
  .fs-26 {
    font-size: 20px;
  }
  .fs-29 {
    font-size: 16px;
  }
  .fs-34 {
    font-size: 20px;
  }
  .fs-38 {
    font-size: 22px;
  }
  .fs-44 {
    font-size: 25px;
  }
  .fs-58 {
    font-size: 30px;
  }
}

@media (min-width: 576px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 15px;
  }
  .fs-17 {
    font-size: 17px;
  }
  .fs-18 {
    font-size: 15px;
  }
  .fs-20 {
    font-size: 17px;
  }
  .fs-22 {
    font-size: 18px;
  }
  .fs-23 {
    font-size: 17px;
  }
  .fs-24 {
    font-size: 20px;
  }
  .fs-29 {
    font-size: 18px;
  }
  .fs-34 {
    font-size: 20px;
  }
  .fs-38 {
    font-size: 21px;
  }
  .fs-44 {
    font-size: 28px;
  }
  .fs-58 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 15px;
  }
  .fs-17 {
    font-size: 15px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .fs-20 {
    font-size: 17px;
  }
  .fs-22 {
    font-size: 20px;
  }
  .fs-23 {
    font-size: 16px;
  }
  .fs-26 {
    font-size: 22px;
  }
  .fs-29 {
    font-size: 22px;
  }
  .fs-34 {
    font-size: 22px;
  }
  .fs-38 {
    font-size: 24px;
  }
  .fs-44 {
    font-size: 30px;
  }
  .fs-58 {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .fs-17 {
    font-size: 17px;
  }
  .fs-18 {
    font-size: 15px;
  }
  .fs-20 {
    font-size: 17px;
  }
  .fs-22 {
    font-size: 20px;
  }
  .fs-23 {
    font-size: 18px;
  }
  .fs-24 {
    font-size: 24px;
  }
  .fs-26 {
    font-size: 24px;
  }
  .fs-29 {
    font-size: 23px;
  }
  .fs-34 {
    font-size: 26px;
  }
  .fs-38 {
    font-size: 28px;
  }
  .fs-44 {
    font-size: 36px;
  }
  .fs-58 {
    font-size: 58px;
  }
}

@media (min-width: 1200px) {
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .fs-17 {
    font-size: 17px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .fs-20 {
    font-size: 18px;
  }
  .fs-22 {
    font-size: 20px;
  }
  .fs-23 {
    font-size: 23px;
  }
  .fs-26 {
    font-size: 26px;
  }
  .fs-29 {
    font-size: 29px;
  }
  .fs-34 {
    font-size: 34px;
  }
  .fs-38 {
    font-size: 38px;
  }
  .fs-44 {
    font-size: 44px;
  }
  .fs-58 {
    font-size: 58px;
  }
}
