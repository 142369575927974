.flight-search-header {
  min-height: 100vh;
}

.book-flight-form {
  padding: 28px 20px;
  background-color: $cl-white;
  border-radius: 22px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.06);
}

.heading-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.width-80 {
  width: 80%;
}
.width-59 {
  width: 59%;
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  .select-tab {
    padding: 10px 0;
    border: none;
    background-color: transparent;
    // border-bottom: 2px solid #ececec;
    color: $txt-gray;

    &.select-tab.active {
      color: $cl-orange;
      border-bottom: 2px solid $cl-orange;

      svg {
        path {
          fill: $cl-orange;
          stroke: $cl-orange;
        }
      }
    }
  }
}
.midline {
  // position: absolute;
  width: 24px;
  margin-top: 2%;
  left: -12px;
  height: 1px;
  background: #8592a6;
  transform: translateX(-50%);
}
.rdrCalendarWrapper-date-picker {
  position: absolute;
  z-index: 1;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
}
// .book-flight-form {
.switch-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: $cl-blue;
  position: absolute;
  top: 50px;
  right: -42px;
}
// }

.switch-icon-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: $cl-blue;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.switch-icon-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #31449b;
  position: absolute;
  top: 47px;
  right: -42px;
}
.recent-searches-tab {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;

  .recent-tab {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 12px 18px;
    background-color: #fff7e8;
    border-radius: 50px;
  }
}
.booking-slot-close-btn {
  padding: 5px;
  border-radius: 50%;
  background-color: rgba(191, 189, 189, 0.612);
  margin-top: 33px;
  margin-right: -14px;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
}
.tabcontent {
  display: none;
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    background-image: url('../../../../public/Assets/icons/drop-down-arrow.svg');
    background-repeat: no-repeat;
    background-position: right center;

    option {
      width: 100%;
    }
  }
}
.airport-name {
  font-size: 12px;
  font-weight: bold;
  color: #2f3542;
  display: flex;
}
.popular-flight-slider {
  &.mb-100 {
    margin-bottom: 100px;
  }
  .flight-details-btns {
    .popular-flight-details-btn {
      border: none;
      border-bottom: 2px solid transparent;
      outline: none;
      background-color: transparent;
      padding: 10px 58px;

      &.popular-flight-details-btn.active {
        border-bottom: 2px solid $cl-orange;
      }
    }
  }

  .popular-flights-cards {
    .popular-flight-card {
      border-radius: 14px;
      border: 1px solid #0000000a;
      overflow: hidden;

      .popular-flight-card-details {
        padding: 15px 12px 20px 12px;
      }
    }
  }
}

.date-picker-title {
  font-size: 16px;
  color: #777777;
}

// .bottom-95 {
//   top: 95px;
// }

// .bottom-0 {
//   top: 0;
// }

// .container {
//   position: relative;
// }

.show-nights {
  position: absolute;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  scroll-behavior: smooth;
}

.border-top-gray {
  border-top: 1px solid #0000001a;
}

.date-picker-bottom-title {
  font-size: 12px !important;
  color: #777777;
}

/* Optional: Adjust the styling when hovering over the container */
// .container:hover .show-nights {
//   top: 120%; /* Set the desired distance from the date picker on hover */
// }

@media (max-width: 768px) {
  .width-80 {
    width: 100%;
  }
  .width-59 {
    width: 100%;
  }
  .switch-icon {
    top: 91px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }

  .switch-icon-2 {
    top: 91px;
    transform: rotate(90deg);
    left: 45%;
  }
  .switch-icon-3 {
    top: 91px;
    transform: translateX(-50%) rotate(90deg);
    left: 50%;
  }
  .booking-slot-close-btn {
    // position: absolute;
    top: 4px;
    right: 0;
    z-index: 22;
    margin: 0;
  }
}

@media (max-width: 576px) {
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    padding: 0 0 0 0;
    width: 100%;

    .fs-20 {
      font-size: 14px;
    }
  }

  .popular-flight-details-btn {
    padding: 10px 40px;
  }

  .popular-flight-slider {
    .flight-details-btns {
      .popular-flight-details-btn {
        padding: 10px 40px;
      }
    }
  }
}

@media (min-width: 768px) {
  .flight-search-bg-img {
    padding-top: 100px;
  }
}
