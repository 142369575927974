.hero-bg-blue {
  width: 100%;
  height: 320px;
  background-color: $cl-blue;
  position: absolute;
  top: 0;
}

.hotel-booking {
  .hotel-booking-dtls {
    padding: 20px 0 6px 0;
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
    .dates-and-rooms {
      margin-top: 23px;
      border-top: 2px solid #0000000d;
      .dates {
        padding: 27px 23px;
        margin-top: 6px;
      }
      .rooms {
        padding: 27px 23px;
        margin-top: 6px;
        border-left: 2px solid #0000000d;
      }
    }
  }
  .guest-info-form {
    background-color: $cl-white;
    border-radius: 8px;
    padding: 24px 23px 41px 23px;
    box-shadow: 3px 4px 26px 0px #0000000d;
    margin-top: 18px;
    ::placeholder {
      opacity: 70%;
    }
    .phone-number {
      select {
        border: 0;
        outline: 0;
        width: 72px;
      }
    }
    .smoking-prefrence {
      width: 25%;
    }
    .bed {
      width: 25%;
    }
    .limit-time-offer {
      padding: 11px;
      border-radius: 8px;
      background-color: #fda4001c;
      margin-top: 42px;
    }
    .final-step-btn {
      padding: 19px;
      width: 100%;
      border-radius: 8px;
      background-color: $cl-blue;
      border: none;
      outline: none;
      margin-top: 11px;
      transition: 0.2s;
      &:hover {
        background-color: $cl-orange;
      }
    }
  }
}

.explrar-point {
  padding: 8px 11px 8px 7px;
  background-color: $cl-white;
  border-radius: 8px;
  margin-top: 19px;
  box-shadow: 3px 4px 26px 0px #0000000d;
}

@media (max-width: 992px) {
  .hotel-booking {
    .hotel-booking-dtls {
      .dates-and-rooms {
        .dates {
          padding: 17px 11px;
        }
        .rooms {
          padding: 17px 11px;
          border-left: 0;
        }
      }
    }
    .guest-info-form {
      .smoking-prefrence {
        width: 40%;
      }
      .bed {
        width: 40%;
      }
    }
  }
}
@media (max-width: 768px) {
  .hotel-booking {
    .hotel-booking-dtls {
      .dates-and-rooms {
        .dates {
          padding: 17px 14px;
        }
        .rooms {
          padding: 17px 14px;
          border-top: 2px solid #0000000d;
          border-left: 0;
        }
      }
    }
    .guest-info-form {
      .phone-number {
        select {
          border: 0;
          outline: 0;
          width: 80px;
        }
      }
    }
  }
  .input-decoration {
    padding: 11px 9px;
  }
}

@media (max-width: 576px) {
  .hotel-booking {
    .guest-info-form {
      padding: 24px 11px 27px 11px;
      .smoking-prefrence {
        width: 50%;
      }
      .bed {
        width: 50%;
      }
    }
  }
}
@media (max-width: 410px) {
  .hotel-booking {
    .guest-info-form {
      .smoking-prefrence {
        width: 100%;
        margin-top: 10px;
      }
      .bed {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
@media (min-width: 410px) {
  .hotel-booking {
    .guest-info-form {
      .flex-xs-row {
        flex-direction: row !important;
      }
    }
  }
}
