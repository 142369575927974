* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-page-latest-news-about-container {
  margin-top: 96px;
  width: 80%;
  margin-left: 18%;
}
.blog-page-latest-news-container {
  display: flex;
  gap: 48px 32px;
}
