$success: #1a4fc0;
$error: #ffd000;
$white: #fff;

.alert {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  .success {
    background-color: $success;
    color: $white;
  }
  .error {
    background-color: $error;
    color: $white;
  }
}
