body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-menu .active {
  background-color: rgba(253, 164, 0, 0.1098039216);
  color: #fda400;
  font-weight: 700;
}
.dropDown-menu .active {
  color: #fda400;
  font-weight: 700;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* Ar Explrar page scroll */
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

.bucksLinks .active {
  text-decoration: underline;
  color: #fda400;
}
