.color-dark-green {
  color: #288b54;
}

.fs-13 {
  font-size: 13px;
}

.booking-confirmed-dtls {
  .manage-booking-btn {
    padding: 19px 70px;
    background-color: $cl-blue;
    border-radius: 6px;
  }

  .resend-email-btn {
    padding: 18px 70px;
    border: 1px solid #31449b;
    border-radius: 6px;
  }

  .w-40p {
    width: 40%;
  }

  .w-60p {
    width: 60%;
  }

  .booking-confirmation-number {
    padding: 27px 22px;
    margin-top: 42px;
    border: 2px solid #0000001a;
    border-radius: 8px;
  }

  .superior-king-room {
    padding: 20px 0 13px 0;
    border-radius: 8px;
    border: 2px solid #0000001a;
    margin-top: 22px;
  }

  .border-top-gray {
    padding: 13px 0;
    border-top: 2px solid #0000000d;
  }

  .cancel-booking-btn {
    padding: 8px 39px;
    border-radius: 6px;
    border: 1px solid #31449b;
    margin-top: 14px;
  }

  .booking-details-bg {
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
    margin-top: 32px;

    .hotel-check-in-out-dtls {
      margin: 15px 16px 22px 16px;
      padding: 16px 13px;
      border: 1px solid #0000001a;
      border-radius: 8px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #0000000d;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #0000000d;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    .border-top-gray-dotted {
      border-top: 2px dotted #0000000d;
    }
  }

  .top-15 {
    top: 15px;
  }
}

@media (max-width: 768px) {
  .w-mds-100 {
    width: 100px;
  }

  .booking-confirmed-dtls {
    .manage-booking-btn {
      padding: 13px 40px;
      background-color: $cl-blue;
      border-radius: 6px;
    }

    .resend-email-btn {
      padding: 12px 40px;
      border: 1px solid #31449b;
      border-radius: 6px;
    }
  }

  .fs-32 {
    font-size: 25px;
  }
}

@media (max-width: 576px) {
  .booking-confirmed-dtls {
    .manage-booking-btn {
      padding: 13px;
      background-color: $cl-blue;
      border-radius: 6px;
      width: 100%;
      text-align: center;
    }

    .resend-email-btn {
      padding: 12px;
      border: 1px solid #31449b;
      border-radius: 6px;
      width: 100%;
      text-align: center;
    }
    .booking-confirmation-number {
      padding: 20px 10px;
      margin-top: 42px;
      border: 2px solid #0000001a;
      border-radius: 8px;
    }

    .superior-king-room {
      padding: 20px 0 13px 0;
      border-radius: 8px;
      border: 2px solid #0000001a;
      margin-top: 22px;
    }
  }
}
