* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.customer-Support-Faq-main-container {
  display: flex;
  gap: 20px;
}

.customer-Support-Faq-container {
  background-color: #f2f4fe;
  padding: 45px 27px 22px 27px;
}

.customer-support-faq-subTitle {
  color: #31449b;
  font-weight: 600;
}
.customer-support-faq-subTitleText {
  color: #101012;
  line-height: 20px;
  margin-top: 13px;
  margin-bottom: 23px;
}

.customer-support-faq-subTitleTextSub {
  color: #e62c2c;
  font-weight: 600;
  padding: 23px 0 23px 0;
}

.customer-support-faq-button {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fda400;
  color: #fff;
  padding: 13px 21px 13px 21px;
}

.customer-Support-Faq-button-Container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.customer-support-faq-main-container-option {
  display: flex;
  justify-content: center;
  align-items: center;
}
