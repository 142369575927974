#hotel-search-result-header {
  .overlay {
    position: fixed;
    width: 0;
    height: 100%;
    background-color: #000000b5;
    z-index: 2;
  }

  .hero-bg-blue {
    width: 100%;
    height: 320px;
    background-color: $cl-blue;
  }

  .hotel-compare-btn {
    z-index: 1;
    font-size: 18px;
    box-shadow: 0px 18px 46px 10px rgb(0 0 0 / 35%);
    background-color: $cl-orange;
    padding: 14px 20px;
    border-radius: 6px;
    border: none;
    outline: none;
    bottom: 20px;
    right: 100px;
  }
}
.sort-dropdown {
  position: absolute;
  width: 234px;
  background-color: var(--color-white);
  padding: 23px 20px;
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
  border-radius: 11px;
  z-index: 1;
}
.hotel-room-book-form {
  padding: 34px 21px;
  background-color: $cl-white;
  border-radius: 10px;
  position: relative;
  margin-top: 54px;
  box-shadow: 3px 4px 26px 0px #0000000d;

  .hotel-search-btn {
    padding: 16px 50px;
    background-color: $cl-blue;
    border: none;
    outline: none;
    border-radius: 6px;
  }
}

.hotel-view {
  margin-top: 19px;

  .hotel-sider-filters {
    position: sticky;
    top: 0;
    z-index: 10;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: $cl-white;
    border-radius: 8px;
    border-bottom: 1px solid #0000001a;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $cl-orange;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f4f4f4;
      border-radius: 5px;
    }

    .view-map {
      position: relative;

      img {
        border-radius: 8px;
        border: 1px solid #0000001a;
      }

      .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .view-map-btn-position {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 163px;

        .view-map-btn {
          padding: 5px 13px;
          border-radius: 5px;
          background-color: $cl-blue;

          &:hover {
            background-color: $cl-orange;
          }
        }
      }
    }

    .rating-price-distence-amenities {
      border: 1px solid #0000001a;
      border-radius: 8px;
      padding: 22px 16px 40px 11px;
      background-color: $cl-white;

      .guest-rating {
        width: 54px;
        padding: 10px 5px;
        border: 1px solid #0000001a;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
      }

      .price-range {
        background: #dee2e6;
        border-radius: 28px;
        height: 2.72px;
        width: 100%;
        outline: none;
        transition: 450ms ease-in;
        -webkit-appearance: none;
        cursor: pointer;

        &::-webkit-slider-thumb {
          width: 9px;
          -webkit-appearance: none;
          height: 9px;
          border-radius: 50%;
          accent-color: red;
          background: $cl-blue;
        }
      }

      .amenities {
        padding: 10px;
        border: 1px solid #0000001a;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }

  .hotels {
    .hotel-price-distance-rating {
      background-color: $cl-white;
      border-radius: 8px;

      .hotel-tabs {
        position: relative;
        margin: 0px 9px 0 0;

        .filter-show-btn-width {
          .filter-show-btn {
            padding: 8px 20px;
            margin-bottom: 20px;
            border: 1px solid #0000001a;
            outline: none;
            background-color: transparent;
            border-radius: 8px;
            position: static;
          }
        }

        .most-popular-btn {
          text-align: center;
          position: relative;
          z-index: 1;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 10px 0;
          border-right: 1px solid #0000000d;
          width: 70%;
        }

        .lowest-price-btn {
          text-align: center;
          position: relative;
          z-index: 1;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 10px 0;
          border-right: 1px solid #0000000d;
          width: 100%;
        }

        .distance-btn {
          text-align: center;
          position: relative;
          z-index: 1;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 10px 0;
          border-right: 1px solid #0000000d;
          width: 70%;
        }

        .star-rating-btn {
          text-align: center;
          position: relative;
          z-index: 1;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 10px 0;
          border-right: 1px solid #0000000d;
          width: 70%;
        }

        .tab-menu-btn {
          border: none;
          outline: none;
          background-color: transparent;
          padding-left: 10px;

          .line-1 {
            height: 2px;
            width: 25px;
            display: inline-block;
            background-color: $cl-blue-black;
          }

          .line-2 {
            height: 2px;
            width: 25px;
            display: inline-block;
            background-color: $cl-blue-black;
          }

          .line-3 {
            height: 2px;
            width: 25px;
            display: inline-block;
            background-color: $cl-blue-black;
          }
        }
      }
    }

    .hotel-view-cards {
      padding: 12px;
      background-color: #fbfbfd;
      margin-top: 27px;
      border-radius: 8px;
      position: relative;

      .compare-hotel-position {
        position: absolute;
        bottom: 11px;
        left: 15px;

        input[type='checkbox'] {
          opacity: 0;
          position: absolute;
          z-index: 1;
        }

        .custom-checkbox {
          width: 13px;
          height: 13px;
          background-color: transparent;
          border: 2px solid $cl-white;
          position: relative;
          border-radius: 3px;
          background-color: rgb(0, 0, 0);

          &::before {
            content: '\2714';
            font-size: 9px;
            font-weight: 900;
            color: $cl-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
          }
        }

        input[type='checkbox']:checked + .custom-checkbox::before {
          opacity: 1;
        }
      }

      .guest-rating {
        padding: 3px 15px;
        border-radius: 21px;
        background-color: $cl-blue;
        margin: 2px 9px;
      }

      .color-green {
        color: #319b3c;
      }

      .color-red {
        color: #ea3434;
      }

      .sale-persent {
        background-color: #ea3434;
        padding: 5px 6px 4px 6px;
      }

      .save-btn {
        padding: 15px 18px;
        background-color: $cl-orange;
        border-radius: 6px;
      }

      .view-hotel-btn {
        padding: 14.5px 30px;
        border-radius: 6px;
        background-color: $cl-blue;

        &:hover {
          color: white;
        }
      }

      .sold-out-btn {
        padding: 7px 30px;
        border-radius: 6px;
        background-color: #ea3434;

        &:hover {
          color: white;
        }
      }

      .dropdown-page {
        height: 47px;
        width: 47px;
        border-radius: 50%;
        background-color: $cl-white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@media (max-width: 1400px) {
  #hotel-search-result-header {
    .hotel-compare-btn {
      bottom: 20px;
      right: 50px;
    }
  }

  .hotel-view {
    .hotels {
      .hotel-view-cards {
        .save-btn {
          padding: 12.5px 18px;
        }

        .sold-out-btn {
          padding: 11px 30px;
        }
      }

      .hotel-price-distance-rating {
        .hotel-tabs {
          .distance-tab {
            &::before {
              right: -27%;
            }
          }

          .rating-tab {
            &::before {
              right: -32%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .hotel-room-book-form {
    .input-decoration {
      width: 100%;
    }
  }

  .hotel-view {
    margin-top: 19px;

    .hotel-sider-filters {
      position: fixed;
      top: 0;
      z-index: 2;
      width: 290px;
      background-color: $cl-white;
      left: -100%;
      top: 0;
      transition: 0.4s;
    }

    .hotels {
      .hotel-price-distance-rating {
        border-bottom: 1px solid #0000000c;
        padding: 22px 0;
      }

      .hotel-view-cards {
        .save-btn {
          padding: 12.5px 18px;
        }

        .sold-out-btn {
          padding: 10px 30px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #hotel-search-result-header {
    .hotel-compare-btn {
      font-size: 15px;
    }
  }

  .hotel-view {
    .hotels {
      .hotel-view-cards {
        .save-btn {
          padding: 12.5px 18px;
        }

        .sold-out-btn {
          padding: 13px 30px;
        }
      }

      .hotel-price-distance-rating {
        .hotel-tabs {
          .distance-tab {
            &::before {
              right: -27%;
              width: 0px;
            }

            &::after {
              right: -27%;
              width: 0px;
            }
          }

          .rating-tab {
            &::before {
              right: -32%;
              width: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #hotel-search-result-header {
    .hotel-compare-btn {
      bottom: 20px;
      right: 30px;
      padding: 12px 17px;
    }
  }

  .hotel-room-book-form {
    padding: 16px;

    .input-decoration {
      padding: 10px 13px;
    }
  }

  .hotel-view {
    .hotels {
      position: relative;

      .filter-show-btn-width {
        border-left: 1px solid #0000001a;

        .filter-show-btn {
          margin-bottom: 0 !important;
        }
      }

      .hotel-view-cards {
        .dropdown-page {
          height: 41px;
          width: 41px;
        }
      }

      .hotel-price-distance-rating {
        .hotel-tabs {
          .most-popular-btn {
            text-align: left;
            border-right: 0;
            width: 100%;
          }

          .lowest-price-btn {
            text-align: left;
            border-right: 0;
            width: 100%;
          }

          .distance-btn {
            text-align: left;
            border-right: 0;
            width: 100%;
          }

          .star-rating-btn {
            text-align: left;
            border-right: 0;
            width: 100%;
          }

          .price-rating-drop-down {
            width: 50%;
            border: 1px solid #0000001a;
            border-radius: 6px;
            background-color: $cl-white;
            padding: 10px;
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  #hotel-search-result-header {
    .hotel-compare-btn {
      bottom: 20px;
      right: 30px;
      padding: 10px 15px;
    }
  }

  .hotels {
    .hotel-view-cards {
      .fs-26 {
        font-size: 18px;
      }

      .fs-14 {
        font-size: 12px;
      }
    }
  }

  .hotel-view {
    .hotels {
      .hotel-price-distance-rating {
        .hotel-tabs {
          position: relative;

          .price-rating-drop-down {
            width: 100%;
          }
        }
      }

      .hotel-view-cards {
        .save-btn {
          padding: 8px 10px;

          img {
            width: 16px;
          }
        }

        .sold-out-btn {
          padding: 8px 15px;
        }

        .view-hotel-btn {
          padding: 10px 8px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .hotels {
    .hotel-price-distance-rating {
      background-color: $cl-white;
      border-radius: 8px;

      .hotel-tabs {
        position: relative;
        margin: 0px 9px 0 0;

        .hotel-sort-dd {
          // position: absolute;
          // z-index: 10;
          // background-color: $cl-white;
          // padding: 10px;
          // width: 130px;
          // border: 1px solid #0000002c;
          // border-radius: 4px;
          position: absolute;
          width: 234px;
          background-color: var(--color-white);
          padding: 23px 20px;
          border: 1px solid #00000014;
          box-shadow: -5px 4px 20px 0px #00000017;
          border-radius: 11px;
          z-index: 10;
        }
      }
    }
  }
}
