.clear-cart-btn {
  padding: 11px 21px;
  border-radius: 4px;
  background-color: #fda4001c;
}

.brs-all-6 {
  border-radius: 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.color-red {
  color: #ec1414;
}

.hotel-cart-dtls {
  background-color: $cl-white;
  box-shadow: 3px 4px 26px 0px #0000000d;
  margin-top: 46px;
  border-radius: 8px;

  .hotel-cart-card {
    padding-left: 13px;

    .ptb-20 {
      padding-top: 20px;
      padding-bottom: 23px;
    }

    .hotel-rating {
      background-color: $cl-blue;
      padding: 1px 9px 0px 10px;
      border-radius: 21px;
    }

    .sale-off {
      background-color: #ec1414;
      padding: 5px 6px 4px 6px;
      display: inline-block;
      margin-top: 31px;
    }

    .quantity {
      border-left: 2px solid #0000000d;
      height: 100%;

      .sub-total {
        border-top: 2px solid #0000000d;
        padding: 15px 5px 15px 10px;
        width: 100%;
      }
    }
  }

  .save-and-remove-from-cart {
    padding: 6px 0;
    border-top: 2px solid #0000000d;

    .save-later {
      padding: 26px 10px;
      border-right: 2px solid #0000000d;
    }

    .remove-cart {
      padding: 26px 10px;
    }
  }
}

.event-cart-dtls {
  background-color: $cl-white;
  box-shadow: 3px 4px 26px 0px #0000000d;
  margin-top: 46px;
  border-radius: 8px;

  .event-cart-card {
    padding-left: 13px;

    .ptb-20 {
      padding-top: 20px;
      padding-bottom: 23px;
    }

    .quantity {
      border-left: 2px solid #0000000d;
      height: 100%;

      .sub-total {
        border-top: 2px solid #0000000d;
        padding: 15px 5px 15px 10px;
        width: 100%;
      }
    }
  }

  .save-and-remove-from-cart {
    padding: 6px 0;
    border-top: 2px solid #0000000d;

    .save-later {
      padding: 26px 10px;
      border-right: 2px solid #0000000d;
    }

    .remove-cart {
      padding: 26px 10px;
    }
  }
}

.cart-items-price-details {
  padding: 26px 0 40px 0;
  box-shadow: 3px 4px 26px 0px #0000000d;
  background-color: $cl-white;
  border-radius: 8px;
  margin-top: 46px;

  .apply-promocode-popup {
    padding: 29px 19px;
    max-width: 594px;
    box-shadow: 3px 4px 26px 0px #0000000d;
    background-color: $cl-white;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 100%;
    display: none;

    ::placeholder {
      opacity: 70%;
    }

    .apply-code-btn {
      margin-top: 32px;
      border: none;
      outline: none;
      padding: 19px 10px;
      border-radius: 6px;
      background-color: $cl-blue;
      width: 100%;
    }
  }

  .price-dtls {
    border-bottom: 2px solid #0000000d;
    padding-bottom: 26px;
  }

  .sub-total-price-checkout {
    border-top: 2px dashed #0000000d;

    .continue-shopping {
      border: 1px solid $cl-blue;
      padding: 18px 10px;
      border-radius: 6px;
      width: 100%;
    }

    .checkout {
      background-color: $cl-blue;
      padding: 19px 10px;
      border-radius: 6px;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .hotel-cart-dtls {
    margin-top: 20px;
  }

  .event-cart-dtls {
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .border-top-gray {
    border-top: 2px solid #0000000d;
  }
}

@media (max-width: 768px) {
  .hotel-cart-dtls {
    .hotel-cart-card {
      .sale-off {
        margin-top: 12px;
      }

      .quantity {
        .sub-total {
          padding: 15px 4px 15px 4px;
        }

        .w-40px {
          width: 40px;
        }
      }
    }

    .save-and-remove-from-cart {
      .save-later {
        padding: 16px 12px;
      }

      .remove-cart {
        padding: 16px 10px;
      }
    }
  }

  .event-cart-dtls {
    .event-cart-card {
      .quantity {
        .sub-total {
          padding: 15px 4px 15px 4px;
        }

        .w-40px {
          width: 40px;
        }
      }
    }

    .save-and-remove-from-cart {
      .save-later {
        padding: 16px 12px;
      }

      .remove-cart {
        padding: 16px 10px;
      }
    }
  }

  .cart-items-price-details {
    padding: 26px 0 30px 0;
    margin-top: 30px;

    .price-dtls {
      padding-bottom: 20px;
    }

    .sub-total-price-checkout {
      .continue-shopping {
        padding: 13px 10px;
      }

      .checkout {
        padding: 14px 10px;
      }
    }
  }
}

@media (max-width: 576px) {
  .padding-0 {
    padding: 0;
  }

  .clear-cart-btn {
    padding: 7px 15px;
  }

  .hotel-cart-dtls {
    .hotel-cart-card {
      .quantity {
        border-left: 0;

        .sub-total {
          border: 0;
          border-left: 2px solid #0000000d;
          padding: 15px 4px 15px 4px;
          width: 50%;
        }

        .w-40px {
          width: 30px;
        }
      }
    }
  }

  .event-cart-dtls {
    .event-cart-card {
      .quantity {
        border-left: 0;

        .sub-total {
          border: 0;
          border-left: 2px solid #0000000d;
          padding: 15px 4px 15px 4px;
          width: 50%;
        }

        .w-40px {
          width: 30px;
        }
      }
    }
  }
}
