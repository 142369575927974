* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-decoration: none !important;
  scroll-behavior: smooth;
  box-sizing: border-box;
  // font-family: $ff-inter;
}

a {
  display: inline-block;
  text-decoration: none;
  color: $cl-blue-black;
}

li {
  list-style-type: none;
}

a:hover {
  color: $cl-orange;
}

::placeholder {
  color: $cl-blue-black;
}

p {
  margin-bottom: 0 !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

select option {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1294117647);
  box-shadow: -5px 4px 20px 0px #00000017;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../../../public/Assets/icons/drop-down-arrow.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

Select option[disabled]:first-child {
  color: $cl-blue-black;
  font-style: italic;
  opacity: 70%;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}
