@import './layout/variables';
@import './layout/layout';
@import './components/buttons';
// @import './components/navbar';
// @import './components/normal-slider';
@import './components/slider-with-tabs';
@import './components/currency-popup-styles';
@import './components/custom-bucks';
// @import './pages-scss/login';
// @import './pages-scss/homepage.scss';
@import './pages-scss/trip-itnerary';
@import './layout/basic-classes';
@import './mui/mui';
@import './pages-scss/flight-search-page';
@import './pages-scss/depart-flight-selection';
@import './pages-scss/return-flight-selection';
@import './pages-scss/flight-book-passanger-stage';
@import './pages-scss/flight-book-addOn-stage';
@import './pages-scss/flight_ticket_pay';
@import './pages-scss/hotel-search-result';
@import './pages-scss/map';
@import './pages-scss/hotel-details';
@import './pages-scss/hotel-compare';
@import './pages-scss/hotel-booking-details';
@import './pages-scss/hotel-review-pay';
@import './pages-scss/events';
@import './pages-scss/local-tours';
@import './pages-scss/events-details';
@import 'pages-scss/profile';
@import './pages-scss/profile';
@import './pages-scss/hotel-booking-confirmation-page';
@import './pages-scss/flight-booking-confirmation';
@import './pages-scss/car-rental';
@import './pages-scss/car-rental-search-result';
@import './pages-scss/cart';
@import './pages-scss/empty-cart';
@import './pages-scss/cart-after-promo-code';
@import 'pages-scss/my-trips';
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
@import './pages-scss/change-flight';
@import './pages-scss/change-flight-confirm';
@import './pages-scss/change-flight-payment';
@import './pages-scss/cancel-flight';
@import './pages-scss/cancel-flight-confirm';
@import './pages-scss/flightchange-ticketpage';
@import './responsive.scss';
@import './pages-scss/chatbot';
@import './pages-scss/tour-guide';
@import './pages-scss/alert';
@import './pages-scss/ar-explrar';

* {
  margin: 0;
  padding: 0 auto;
  scroll-behavior: smooth;
  text-decoration: none;
  list-style-type: none !important;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

main {
  overflow: hidden;
}

body {
  // font-family: 'Inter', sans-serif !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

body::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #fda400;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #f4f4f4;
}

:root {
  --bs-primary-color: #fda400;
  --bs-secondary-color: #31449b;
  --bs-white-color: #ffffff;
  --bs-xblack-color: #101012;
  --bs-black-color: #101012;
  --bs-gray-color: #585859;
  --fs-xxsm: 12px;
  --fs-xsm: 14px;
  --fs-sm: 16px;
  --fs-md: 18px;
  --fs-base: 20px;
  --fs-lg: 22px;
  --fs-xl: 24px;
  --fs-2xl: 26px;
  --fs-3xl: 28px;
  --fs-4xl: 32px;
  --fs-5xl: 36px;
  --fs-6xl: 44px;
  --fw-normal: 400;
  --fw-base: 500;
  --fw-medium: 600;
  --fw-bold: 700;
  --fw-x-bold: 800;
  /*======= FONTS-SIZES =======*/
  --fs_sm: 16px;
  --fs_md: 18px;
  --fs_base: 20px;
  --fs_lg: 26px;

  /*======= FONTS-WEIGHTS =======*/
  --fw_normal: 400;
  --fw_medium: 500;
  --fw_bold: 600;
  --fw_xtr_bold: 700;

  /*========= COLORS =========*/
  --color_blue_black: #101012;
  --color_blue: #31449b;
  --color_white: #ffffff;
  --color_yellow: #fda400;
  --color_light_white: #fbfbfd;
  --color_gray: rgba(16, 16, 18, 0.27);

  /*======== FONT-FAMILY ========*/
  --ff_inter: 'Poppins', sans-serif;
}

.fs-xxsm {
  font-size: var(--fs-xxsm);
}

.fs-xsm {
  font-size: var(--fs-xsm);
}

.fs-sm {
  font-size: var(--fs-sm);
}

.fs-md {
  font-size: var(--fs-md);
}

.fs-base {
  font-size: var(--fs-base);
}

.fs-lg {
  font-size: var(--fs-lg);
}

.fs-xl {
  font-size: var(--fs-xl);
}

.fs-2xl {
  font-size: var(--fs-2xl);
}

.fs-3xl {
  font-size: var(--fs-3xl);
}

.fs-4xl {
  font-size: var(--fs-4xl);
}

.fs-5xl {
  font-size: var(--fs-5xl);
}

.fs-6xl {
  font-size: var(--fs-6xl);
}

.fw-normal {
  font-weight: var(--fw-normal);
}

.fw-base {
  font-weight: var(--fw-base);
}

.fw-medium {
  font-weight: var(--fw-medium);
}

.fw-bold {
  font-weight: var(--fw-bold);
}

.fw-x-bold {
  font-weight: var(--fw-x-bold);
}

.cl-xblack {
  color: var(--bs-xblack-color);
}

.cl-white {
  color: var(--bs-white-color);
}

.cl-gray {
  color: var(--bs-gray-color);
}

.line-height-176 {
  line-height: 176.5%;
}

a {
  text-decoration: none !important;
  /* color: var(--bs-xblack-color) !important; */
  display: inline-block;
}

header {
  display: flex;
  flex-direction: column;
}

.logo {
  height: 47px;
  width: 169px;
}

.pointer-events-none {
  pointer-events: none;
}

nav {
  background-color: var(--bs-white-color);
}

.nav-border-bottom {
  border-bottom: 1px solid #f2f2f2;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  flex-direction: row-reverse;
  border: none !important;
}

.css-i4bv87-MuiSvgIcon-root:not(.whiteSvgIcon) {
  fill: #fda400;
}

.nav-links {
  font-size: var(--fs-base);
  color: var(--bs-gray-color) !important;
  font-weight: var(--fw-medium);
}

.nav-link:hover {
  color: var(--bs-primary-color) !important;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a:hover {
  color: var(--bs-primary-color) !important;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.p-pointer {
  cursor: pointer;
}

.p-text {
  cursor: text;
}

.nav-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  font-size: var(--fs-6xl);
  font-weight: var(--fw-bold);
  margin-bottom: 15px;
  line-height: 127%;
}

input {
  border: none;
  outline: none;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.search-input {
  background-color: var(--bs-white-color);
  padding: 18px 16px;
  border-radius: 6px;
  box-shadow: -1px 0px 18px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
}

.primary-btn {
  font-size: var(--fs-18);
  font-weight: var(--fw-medium);
  background-color: var(--bs-secondary-color);
  color: var(--bs-white-color) !important;
  padding: 14px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
}

.primary-btn:hover {
  color: var(--bs-white-color) !important;
  background-color: var(--bs-primary-color);
}

.sec-btn {
  font-size: var(--fs-18);
  font-weight: var(--fw-medium);
  background-color: var(--bs-secondary-color);
  color: var(--bs-white-color) !important;
  padding: 17px 70px;
  text-align: center;
  border-radius: 6px;
}

.sec-btn:hover {
  color: var(--bs-white-color) !important;
  background-color: var(--bs-primary-color);
}

.save-trav-btn {
  all: unset;
  background-color: var(--bs-primary-color);
  color: var(--bs-white-color) !important;
  padding: 10px 50px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transform: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--bs-secondary-color);
  }
}
.cancel-trav-btn {
  all: unset;
  background-color: #a1a1a1;
  color: var(--bs-white-color) !important;
  padding: 10px 50px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transform: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--bs-secondary-color);
  }
}

.hero-img {
  width: 100%;
  margin-bottom: 60px;
}

.highlighter {
  top: -6px;
  left: -14px;
}

.man-img {
  bottom: -71px;
  z-index: 1;
  left: 0px;
}

.aeroplane-img {
  top: 20px;
  z-index: 1;
  left: 0px;
}

.hero-review-img {
  top: -134px;
  left: -112px;
}

.hero-bg-img-1 {
  right: 0;
  top: 0;
  height: 500px;
}

.hero-bg-img-2 {
  right: 250px;
  bottom: 50px;
  /* height: 500px; */
}

.d_none {
  display: none;
}

.hero-bg-img-3 {
  left: 0;
  top: 373px;
  /* height: 500px; */
}

.hero-arrow-img {
  right: 112px;
  top: 131px;
}

.input-error {
  border-color: #d23939 !important;
}

.date-error {
  border: 1px solid #d23939;
  border-radius: 5px;
}

.highlighted-text {
  color: var(--bs-primary-color);
}

// h2 {
//   font-size: var(--fs-6xl) !important;
//   font-weight: var(--fw-bold) !important;
//   color: var(--bs-xblack-color) !important;
// }

.card-section {
  text-align: center;
}

.cards {
  display: flex;
}

.card {
  border: none !important;
  text-align: center;
  width: 25% !important;
}

.card img {
  height: 78px;
  width: 76px;
}

.card h5 {
  font-size: var(--fs-base);
  font-weight: var(--fw-bold);
  color: var(--bs-xblack-color);
  padding-top: 33px;
}

.card p {
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
  color: var(--bs-gray-color);
  padding-top: 5px;
}

.heading {
  text-align: center;
}

.heading p {
  color: var(--bs-primary-color);
  font-size: var(--fs-2xl);
  font-weight: var(--fw-bold);
}

.line-height-43 {
  line-height: 43px;
}

.line-height-151 {
  line-height: 151%;
}

.trip-img2-position {
  transform: translateY(-12%);
}

.height-700 {
  height: 700px;
}

footer {
  //   background-image: url("../../../public/Assets/Img/svg/bg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -1px;
}

.card-2 {
  background-color: var(--bs-white-color);
  padding: 37px 25px 38px 25px;
  border-radius: 19px;
  border: 1px solid rgba(0, 0, 0, 0.11);
}

.line-height-26 {
  line-height: 26px;
}

footer label {
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
  color: var(--bs-xblack-color);
}

footer .footer-input {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 22px 15px 22px 19px;
}

footer input::placeholder {
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
  color: var(--bs-xblack-color);
}

.footer-mail-btn {
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
  color: var(--bs-white-color);
  width: 100%;
  background-color: var(--bs-secondary-color);
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 17px 0;
}

.footer-mail-btn:hover {
  color: var(--bs-white-color) !important;
  background-color: var(--bs-primary-color);
}

.mobile-img {
  height: 600px;
  bottom: 0;
  left: -116px;
  position: absolute;
}

/*
.tip-card {
  /* background: #ffffff;
  box-shadow: 4px 4px 30px rgba(253, 164, 0, 0.19);
  border-radius: 14px;
  padding: 24px 42px !important;
  height: fit-content;
  width: 627px;
  position: absolute;
  top: 150px;
  left: 45px;
}*/
/* .tip-card-2 {
   background: #ffffff;
  box-shadow: 4px 4px 30px rgba(253, 164, 0, 0.19);
 border-radius: 14px;
  padding: 24px 42px !important;
  height: fit-content;
  width: 622px;
  position: absolute;
  top: 285px;
  right: 250px;
} */

.feature-section-bg {
  position: absolute;
  right: -348px;
  top: -185px;
  z-index: -1;
}

.feature-section-bg-2 {
  position: absolute;
  left: -348px;
  top: -185px;
  z-index: -1;
  -transform: scaleX(-1);
}

.height-100vh {
  height: 100vh;
}

.height-800 {
  height: 800px;
}

.laptop-bg-left {
  bottom: 2px;
  left: -291px;
  z-index: -1;
}

.laptop-bg-right {
  bottom: 2px;
  right: -291px;
  z-index: -1;
  transform: scaleX(-1);
}

.main-img-shadow {
  box-shadow: -5px 4px 75px rgba(253, 164, 0, 0.31);
  border-radius: 45px;
}

.bg-green {
  background-color: #7bb1a2;
}

.bg-pink {
  background-color: #bf72ac;
}

.bg-orange {
  background-color: var(--bs-primary-color);
}

.green-box {
  top: 101px;
  left: -87px;
  padding: 24px 48px;
}

.orange-box {
  top: -61px;
  left: 238px;
  padding: 24px 48px;
}

.pink-box {
  bottom: 45px;
  left: -105px;
  padding: 24px 48px;
}

.field-error {
  position: absolute;
  color: #e71d36;
  font-weight: 600;
  font-size: 16px;
}

div.after-element::before {
  content: '1';
  font-size: 220px;
  font-weight: 700;
  color: #101012;
  position: absolute;
  right: 60px;
  top: -211px;
  opacity: 3%;
}

div.after-element-2::before {
  content: '2';
  font-size: 220px;
  font-weight: 700;
  color: #101012;
  position: absolute;
  right: 293px;
  top: 50px;
  opacity: 3%;
}

div.after-element-3::before {
  content: '3';
  font-size: 220px;
  font-weight: 700;
  color: #101012;
  position: absolute;
  right: 428px;
  top: -194px;
  opacity: 3%;
}

div.after-element-4::before {
  content: '4';
  font-size: 220px;
  font-weight: 700;
  color: #101012;

  position: absolute;
  left: 0;
  top: -280px;
  opacity: 3%;
}

/* our key features section decoration elements */
.circle-elements-1 {
  top: 198px;
  left: 180px;
}

.circle-elements-2 {
  top: 53px;
  right: 200px;
}

.dots-element {
  left: -24px;
  top: 359px;
}

.two-dots {
  bottom: 490px;
  left: 45px;
}

.single-circle {
  bottom: 50px;
  right: 50px;
}

/* AR Explrar section decoration elements */
.united-circle {
  top: 0px;
  left: 800px;
}

.circle-element-1 {
  top: -133px;
  left: 520px;
}

.circle-elements-3 {
  top: 200px;
  right: 0px;
  transform: scaleX(-1);
}

.down-arrow {
  bottom: 80px;
  left: 600px;
}

.dots-element-1 {
  bottom: 0;
  left: 200px;
}

/* AR Explrar section decoration elements */

.dot-1 {
  top: -164px;
  right: 244px;
}

.dot-2 {
  bottom: -232px;
  left: 55px;
}

.connecter-line {
  bottom: -289px;
  left: 400px;
  z-index: -1;
}

.transform-x1 {
  transform: scaleX(-1);
}

.dot-3 {
  bottom: -105px;
  left: 257px;
}

.dot-4 {
  top: -125px;
  left: -49px;
}

.dot-5 {
  top: -73px;
  right: 201px;
}

.responsive-img {
  display: none;
}

.footer-logo {
  height: 60px;
  /* width: 142px; */
}

.footer-contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul {
  padding-inline-start: 0px;
  padding-left: 0px !important;
}

/* .col-md-5 {
  width: 50% !important;
} */
/**********Home page**********/
.popular-attraction .card-padding {
  height: 100%;
  min-height: 100px;
}

.top-attractions .card-padding {
  height: 100%;
  min-height: 100px;
}

.popular-hotels .card-padding {
  height: 100%;
  min-height: 175px;
}

/*******planMyTrip********/
.planMyTrip {
  background-color: #f6f6f6;

  .planMyTrip .fs-20 {
    font-size: 15px;
  }

  .popular-hotels {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  .no-padding .MuiBox-root {
    padding: 0;
  }
  .popular-hotels h2 {
    display: none;
  }

  .MuiTypography-root {
    padding: 0;
  }

  .popular-hotels .slick-slide img {
    height: 150px;
  }

  .tabs-column {
    background-color: #fff;
    padding: 15px;

    .MuiTabs-flexContainer button {
      border: 1px solid var(--color-blue);
      padding: 5px 0px;
    }

    button svg {
      width: 35px !important;
      height: 35px !important;
    }

    .MuiButtonBase-root.Mui-selected {
      background-color: var(--color-blue);
      svg path {
        fill: var(--color-white);
      }
    }
  }

  .tabs-content {
    ul li {
      margin-bottom: 10px;
      span {
        color: #009400;
        font-weight: 500;
      }
    }
    ul li svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
.common-pd {
  padding-top: 70px;
  padding-bottom: 70px;
}

/*******planMyTrip End********/
/****************************/
/* responsive */

@media screen and (max-width: 768px) {
  .field-error {
    font-size: 13px;
  }

  .back-btn-top {
    margin-top: -20px;
  }

  .responsive-img {
    display: block;
    width: 100%;
    padding-top: 30px;
  }

  .lazy-card-img {
    height: 220px !important;
  }

  .cont {
    padding-top: 30px;
  }

  .cont p {
    font-size: 16px !important;
  }

  .map-search-btn {
    width: 100%;
  }

  .hero-img {
    display: none;
  }

  h1 {
    font-size: var(--fs-4xl) !important;
    /* font-weight: var(--fw-bold) !important;
    margin-bottom: 15px !important;
    line-height: 127% !important; */
  }

  #hero {
    text-align: center;
  }

  .highlighter {
    top: -5px;
    left: -10px;
    height: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .highlighter {
    left: -16px;
    height: 65px;
    top: -9px;
  }

  .cont p {
    font-size: 18px;
  }

  .dots-element {
    display: none;
  }

  .for-responsive p {
    font-size: 20px;
  }

  h3 {
    font-size: 30px !important;
  }

  div.after-element::before {
    font-size: 180px !important;
    right: 10px;
    top: -125px;
  }

  .circle-element-1 {
    display: none;
  }

  .mobile-img {
    height: 500px;
    bottom: -206px;
    left: -57px;
  }

  .after-element-2 p {
    font-size: 22px;
  }

  /* .tip-card-2 {
    height: fit-content;
    width: 470px;
    top: 234px;
    right: 158px;
  } */
  .dot-1 {
    top: -98px;
    right: 190px;
  }

  div.after-element-3::before {
    font-size: 180px;
    right: 42%;
    top: -194px;
  }
}

@media screen and (max-width: 1200px) {
  .map-search-btn {
    width: 100%;
  }

  .mobile-img {
    height: 400px;
    bottom: -206px;
    left: -57px;
  }

  .after-element-2 p {
    font-size: 18px;
  }

  .line-height-43 {
    line-height: 30px;
  }

  div.after-element-2::before {
    font-size: 180px !important;
    right: 171px;
    top: 25%;
  }

  .dots-element-1 {
    bottom: 176px;
  }

  /* .tip-card {
    height: fit-content;
    width: 500px;
    top: 127px;
    left: 45px;
  } */

  .tip-card h3 {
    font-size: 26px !important;
    margin: 0;
  }

  .height-800 {
    width: 100%;
    height: unset;
  }

  .feature-section-bg {
    display: none;
  }

  .feature-section-bg-2 {
    display: none;
  }

  .tip-card-2 h3 {
    font-size: 26px !important;
    margin: 0;
  }

  /* .tip-card-2 {
    height: fit-content;
    width: 463px;
    top: 234px;
    right: 80px;
  } */
  .dot-1 {
    top: -98px;
    right: 190px;
  }

  div.after-element-3::before {
    font-size: 180px;
    right: 42%;
    top: -194px;
  }

  .resp-lt .col-6 {
    width: 100%;
    text-align: center;
  }

  .loyalty-program-img {
    width: 100%;
    margin-top: 140px;
  }

  .orange-box {
    top: 10%;
    right: 10%;
    left: unset;
  }

  .green-box {
    top: 30%;
    left: -10%;
  }

  .pink-box {
    bottom: -6%;
    left: 30%;
  }

  div.after-element-4::before {
    font-size: 180px;
    right: 42%;
    top: -194px;
    left: unset;
  }

  footer {
    background-color: var(--bs-xblack-color);
    background-image: none;
  }

  .card-2 p:first-child {
    font-size: 20px;
  }

  .footer-input {
    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    padding: 16px 15px 16px 16px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  /* .form button {
    margin-top: 0 !important;
  } */

  footer h1 {
    color: var(--bs-white-color) !important;
    font-size: 30px !important;
    line-height: 45px !important;
  }

  .dot-5 {
    display: none;
  }

  .dot-4 {
    display: none;
  }

  .footer-form {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 36px !important;
  }

  .highlighter {
    left: -16px;
    height: 59px;
    top: -9px;
  }

  .responsive-img {
    display: block;
    width: 100%;
    padding-top: 30px;
  }

  .hero-img {
    display: none;
  }

  .hero-review-img {
    display: none;
  }

  .cont {
    padding-top: 30px;
  }

  .cont p {
    font-size: 18px;
  }

  header {
    height: unset;
  }

  .card {
    width: 50% !important;
    margin-top: 20px !important;
  }

  .height-700 {
    width: 100%;
    height: unset;
    margin-top: 40px;
  }

  h3 {
    font-size: 28px !important;
  }

  .after-element p {
    font-size: 18px;
  }

  .circle-elements-2 {
    top: 53px;
    right: 50px;
  }

  .circle-elements-1 {
    top: 140px;
    left: 50px;
  }

  .two-dots {
    display: none;
  }

  div.after-element::before {
    font-size: 130px !important;
    right: 100px;
    top: -112px;
  }

  .height-100vh {
    height: unset;
  }

  .circle-elements-3,
  .down-arrow,
  .united-circle,
  .dots-element-1 {
    display: none;
  }

  .mobile-img {
    height: 350px;
    position: unset;
    padding-top: 20px;
  }

  .f-col-reverse {
    flex-direction: column-reverse;
  }

  .f-col {
    flex-direction: column;
  }

  .after-element-2 p {
    font-size: 18px;
  }

  div.after-element-2::before {
    font-size: 130px !important;
    right: 44%;
    top: -63px;
  }

  .line-height-43 {
    line-height: 26px;
  }

  .tip-card {
    /* width: 75% !important; */
    margin: 48px 0;
  }

  .tip-card h3 {
    font-size: 24px !important;
  }

  .connecter-line {
    display: none;
  }

  .tip-card-2 h3 {
    font-size: 20px !important;
    margin: 0;
  }

  .dot-1 {
    top: -80px;
    right: 123px;
  }

  .dot-2 {
    left: -39px;
  }

  .laptop-bg-left,
  .laptop-bg-right {
    height: 750px;
  }

  .responsive-txt p {
    font-size: 20px;
  }

  div.responsive-txt {
    width: 100% !important;
  }

  div.after-element-3::before {
    font-size: 130px;
    right: 45%;
    top: -40%;
  }

  .orange-box {
    top: 0%;
    right: 10%;
    left: unset;
  }

  .green-box {
    top: 30%;
    left: -10%;
  }

  .pink-box {
    bottom: -6%;
    left: 30%;
  }

  .passengers-box {
    z-index: 2;
  }
  .orange-box,
  .green-box,
  .pink-box {
    padding: 16px 30px;
  }

  div.after-element-4::before {
    font-size: 130px;
    right: 45%;
    top: -52%;
    left: unset;
  }

  .footer-form {
    flex-direction: column-reverse;
  }

  .footer-form .col-md-5 {
    text-align: left !important;
  }

  .footer-form .col-6 {
    width: 100% !important;
  }

  .card-2 {
    margin-top: 20px;
  }

  footer h1 {
    font-size: 24px !important;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .responsive-img {
    display: block;
    width: 100%;
    padding-top: 30px;
  }

  .cont {
    padding-top: 30px;
  }

  .cont p {
    font-size: 16px !important;
  }
  .hero-bg-img {
    background-size: 100% 60% !important;
  }
  .hero-img {
    display: none;
  }

  h1 {
    font-size: var(--fs-4xl) !important;
    /* font-weight: var(--fw-bold) !important;
    margin-bottom: 15px !important;
    line-height: 127% !important; */
  }

  #hero {
    text-align: center;
  }

  .highlighter {
    top: -5px;
    left: -10px;
    height: 50px;
  }

  div.after-element::before {
    font-size: 60px !important;
    right: 47%;
    top: -83px;
  }

  .circle-elements-1 {
    top: 91px;
    left: 6px;
  }

  .circle-elements-2 {
    top: 14px;
    right: 17px;
  }

  .heading p {
    padding-top: 0 !important;
  }

  .heading .pb-4 {
    padding-bottom: 0 !important;
  }

  .mobile-img {
    /* height: 350px;
    bottom: -206px;
    left: -57px; */
    position: unset;
    padding-top: 20px;
    /* width: 100%;
    height: unset; */
  }

  .to-100.col-6 {
    width: 100% !important;
    text-align: center;
  }

  div.after-element-2::before {
    font-size: 60px !important;
    right: 46%;
    top: 0%;
  }

  /* .tip-card {
    height: fit-content;
    width: 176px;
    top: 62px;
    left: 9px;
    padding: 14px 23px !important;
  } */
  .tip-card h3 {
    font-size: 22px !important;
    margin: 0;
  }

  /* .tip-card-2 {
    height: fit-content;
    width: 174px;
    top: 136px;
    right: 9px;
    padding: 14px 23px !important;
  } */
  .dot-1 {
    top: -80px;
    right: 71px;
  }

  .laptop-bg-left,
  .laptop-bg-right {
    height: 360px;
  }

  .laptop-bg-left {
    left: -115px;
  }

  .laptop-bg-right {
    right: -115px;
  }

  .responsive-txt p {
    font-size: 20px;
  }

  div.after-element-3::before {
    font-size: 60px;
    right: 45%;
    top: -15%;
  }

  .pink-box {
    bottom: -6%;
    right: 10%;
    left: unset;
  }

  .green-box {
    bottom: -6%;
    left: 10%;
    top: unset;
  }

  div.after-element-4::before {
    font-size: 60px;
    right: 45%;
    top: -40%;
    left: unset;
  }

  footer h1 {
    font-size: 22px !important;
    line-height: 35px !important;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .popup {
    width: 75%;
  }

  .tip-card h3 {
    font-size: 18px !important;
  }

  .nav-links {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .highlighter {
    left: -12px;
    height: 50px;
  }

  .card-section .row {
    margin: 10px 0 !important;
  }

  /* .tip-card {
    height: fit-content;
    width: 226px;
    top: 39px;
    left: 9px;
    padding: 14px 23px !important;
  } */
  .tip-card h3 {
    font-size: 16px !important;
    margin: 0;
  }

  /* .tip-card-2 {
    height: fit-content;
    width: 198px;
    top: 83px;
    right: 10px;
    padding: 14px 23px !important;
  } */
  .toresp {
    height: 30%;
    top: -48px;
    right: 88px;
  }

  .custom-mg-pd {
    margin: 30px 0;
  }

  .orange-box,
  .green-box,
  .pink-box {
    padding: 6px 16px;
  }

  // .orange-box,
  // .green-box,
  // .pink-box,
  // p {
  //   font-size: 12px !important;
  // }

  .pink-box {
    bottom: -8%;
  }

  .green-box {
    bottom: -8%;
  }

  .orange-box {
    top: 5%;
  }

  .mobile-img {
    width: 100%;
    height: unset;
  }
}

/* popup */
.popup {
  position: absolute;
  top: 61%;
  left: 52%;
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  /* max-width: 380px; */
  width: 100%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) scale(1.2);
  transition:
    top 0s 0.2s ease-in-out,
    opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}

.popup-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.popup-header span {
  font-size: 21px;
  font-weight: 600;
}

.popup p {
  margin: 20px 0;
}

.close {
  color: #878787;
  font-size: 17px;
  background: #f2f3fb;
  height: 33px;
  width: 33px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 650px) {
  .popup {
    width: 75%;
  }

  .close {
    font-size: 15px;
  }

  .popup-header span {
    font-size: 18px;
  }
}

/* login page css */

.fs_sm {
  font-size: var(--fs_sm);
}

.fs_md {
  font-size: var(--fs_md);
}

.fs_base {
  font-size: var(--fs_base);
}

.fs_lg {
  font-size: var(--fs_lg);
}

.fw_normal {
  font-weight: var(--fw_normal);
}

.fw_medium {
  font-weight: var(--fw_medium);
}

.fw_bold {
  font-weight: var(--fw_bold);
}

.fw_xtr_bold {
  font-weight: var(--fw_xtr_bold);
}

.color_blue_black {
  color: var(--color_blue_black);
}

.color_blue {
  color: var(--color_blue) !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.color_gray {
  color: var(--color_gray);
}

.color_yellow {
  color: var(--color_yellow);
}

.color_white {
  color: var(--color_white);
}

.bg_color_white {
  background-color: var(--color_white);
}

.color_light_white {
  background-color: var(--color_light_white);
}

.ff_inter {
  font-family: var(--ff_inter);
}

a {
  display: inline-block;
}

a:hover {
  color: var(--color_yellow);
}

::placeholder {
  color: var(--color_blue_black);
}

.login_page {
  width: 538px;
  margin: 50px auto 0 auto;
  padding: 38px 26px 31px 26px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #fff;
}

.border_bottom {
  border-bottom: 1px solid #f2f2f2;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

.input_border {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px 16px;
}

.login_btn {
  border-radius: 6px;
  border: 1px solid var(--color_blue);
  background-color: var(--color_blue);
  padding: 17px 28px;
}

.line {
  width: 178px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.17);
  display: inline-block;
}

#eye2 {
  display: none;
}
#eye4 {
  display: none;
}
#eye6 {
  display: none;
}

@media (max-width: 576px) {
  :root {
    --fs_sm: 14px;
    --fs_md: 14px;
    --fs_base: 14px;
    --fs_lg: 20px;
  }

  .line {
    width: 100px;
  }

  .login_page {
    margin: 0 16px;
    border: none;
    padding: 24px 16px 31px;
  }

  .login_btn {
    padding: 9px 28px;
  }

  .input_border {
    padding: 16px 16px;
  }

  .nav_logo {
    width: 94px;
  }

  .py_xsm_20 {
    padding: 20px 12px !important;
  }

  .w_xsm_18 {
    width: 18px;
  }
}

@media (min-width: 576px) {
  .input_border {
    padding: 14px 16px;
  }

  .nav_logo {
    width: 120px;
  }

  .login_page {
    margin: 25px auto 0 auto;
    padding: 24px 16px 31px 14px;
  }
}

@media (min-width: 992px) {
  .input_border {
    padding: 20px 16px;
  }

  .nav_logo {
    width: 142px;
  }

  .login_page {
    margin: 35px auto 0 auto;
    padding: 38px 26px 31px 26px;
  }
}

/* OTP popup */

.fs_sm {
  font-size: var(--fs_sm);
}

.fs_md {
  font-size: var(--fs_md);
}

.fs_base {
  font-size: var(--fs_base);
}

.fs_lg {
  font-size: var(--fs_lg);
}

.fw_normal {
  font-weight: var(--fw_normal);
}

.fw_medium {
  font-weight: var(--fw_medium);
}

.fw_bold {
  font-weight: var(--fw_bold);
}

.fw_xtr_bold {
  font-weight: var(--fw_xtr_bold);
}

.color_blue_black {
  color: var(--color_blue_black);
}

.color_blue {
  color: var(--color_blue);
}

.color_gray {
  color: var(--color_gray);
}

.color_yellow {
  color: var(--color_yellow);
}

.color_white {
  color: var(--color_white);
}

.bg_color_white {
  background-color: var(--color_white);
}

.color_light_white {
  background-color: var(--color_light_white);
}

.ff_inter {
  font-family: var(--ff_inter);
}

a {
  display: inline-block;
}

a:hover {
  color: var(--color_yellow);
}

::placeholder {
  color: var(--color_blue_black);
}

.otp {
  width: 538px;
  margin: 0px auto 0 auto;
  padding: 38px 26px 31px 26px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
}

#otp-div {
  z-index: 1;
  position: absolute !important;
  margin-top: -40px;
  margin-left: -26px;
  background: rgb(255, 255, 255);
}

.border_bottom {
  border-bottom: 1px solid #f2f2f2;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

.otp_border {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 30px;
  width: 99px;
  text-align: center;
}

.Resend-otp {
  padding-right: 85px;
}

#eye2 {
  display: none;
}
#eye4 {
  display: none;
}
#eye6 {
  display: none;
}

.mini_container {
  width: 538px;
  margin: 50px auto 0 auto;
  padding: 38px 26px 31px 26px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
}

.verify_btn {
  padding: 17px 28px;
  background-color: var(--color_blue);
  border: 1px solid #31449b;
  border-radius: 6px;
}

@media (max-width: 576px) {
  :root {
    --fs_sm: 12px;
    --fs_md: 14px;
    --fs_base: 14px;
    --fs_lg: 20px;
  }

  .nav_logo {
    width: 94px;
  }

  .py_xsm_20 {
    padding: 20px 12px !important;
  }

  .verify_btn {
    padding: 10px 28px;
  }

  .otp_border {
    padding: 20px;
    width: 70px;
  }

  .otp {
    border: none;
    margin: 0px 16px;
    padding: 38px 13px 31px 13px;
  }

  .w_xsm_18 {
    width: 18px;
  }

  .mini_container {
    margin: 0 16px;
    border: none;
    padding: 24px 0px 31px 0px;
  }
}

@media (min-width: 576px) {
  .nav_logo {
    width: 120px;
  }

  .verify_btn {
    padding: 13px 28px;
  }

  .otp_border {
    padding: 20px;
    width: 70px;
  }

  .Resend-otp {
    padding-right: 31px;
  }

  .mini_container {
    margin: 25px auto 0 auto;
    padding: 24px 16px 31px 14px;
  }
}

@media (min-width: 762px) {
  .otp_border {
    padding: 30px;
    width: 99px;
  }

  .Resend-otp {
    padding-right: 41px;
  }

  .verify_btn {
    padding: 17px 28px;
  }
}

@media (min-width: 992px) {
  .nav_logo {
    width: 142px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* confirm-mail */
.confirm-mail-h1 {
  color: #88b04b;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.confirm-mail-p {
  color: #404f5e;
  font-size: 20px;
  margin: 0;
}

.confirm-mail-card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.verification-mail {
  width: auto;
  margin: 22px auto 0;
}

/* subscribe list table */
table {
  z-index: 1;
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: rgb(185 188 191 / 20%) 0px 0px 24px 5px;
  border-radius: 5px;

  thead {
    th {
      padding: 10px 16px;
      text-align: left;
      background-color: #f3f3f5;
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 16px;
        border-bottom: 1px solid #eee;
      }

      .action {
        display: flex;

        button {
          margin-right: 10px;
          cursor: pointer;
          background-color: #f3f3f5;
          border: none;
          padding: 5px 10px;
          border-radius: 2px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  overflow: auto;

  .res-head {
    display: none;
  }

  @media screen and (max-width: 767px) {
    background-color: transparent;
    box-shadow: none;

    thead {
      display: none;
    }

    tbody {
      tr {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        background-color: #fff;
        padding: 50px 10px 10px 10px;
        position: relative;
        background-color: #fff;
        box-shadow: rgb(185 188 191 / 20%) 0px 0px 24px 5px;

        td {
          display: flex;
          background-color: transparent;
          padding: 0;
          margin-bottom: 5px;
          margin-right: 16px;
          border: none;
          flex-wrap: wrap;

          .res-head {
            display: block;
            font-weight: 700;
            margin-right: 5px;
          }

          &:first-child {
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }

        .action {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}

/* home page css */

:root {
  /*============ font-sizes ============*/
  --fs-12: 12px;
  --fs-16: 16px;
  --fs-17: 17px;
  --fs-18: 18px;
  --fs-20: 20px;
  --fs-23: 23px;
  --fs-29: 29px;
  --fs-32: 32px;
  --fs-38: 38px;
  --fs-44: 44px;
  --fs-58: 58px;

  /*============ font-weight ============*/
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /*============ font-family ============*/
  --ff-inter: 'Poppins', sans-serif;
  --ff-satisfy: 'Satisfy', cursive;
  --ff-poppins: 'Poppins', sans-serif;

  /*============== colors ==============*/
  --color-blue-black: #101012;
  --color-blue: #31449b;
  --color-white: #ffffff;
  --color-light-white: #f5f6f6;
  --color-black: #000000;
  --color-light-black: #14161b;
  --color-orange: #fda400;
  --color-light-orange: #edad352e;
}

.fs-12 {
  font-size: var(--fs-12);
}

.fs-16 {
  font-size: var(--fs-16);
}

.fs-17 {
  font-size: var(--fs-17);
}

.fs-18 {
  font-size: var(--fs-18);
}

.fs-20 {
  font-size: var(--fs-20);
}

.fs-23 {
  font-size: var(--fs-23);
}

.fs-32 {
  font-size: var(--fs-32);
}

.fs-29 {
  font-size: var(--fs-29);
}

.fs-38 {
  font-size: var(--fs-38);
}

.fs-44 {
  font-size: var(--fs-44);
}

.fs-58 {
  font-size: var(--fs-58);
}

.fw-400 {
  font-weight: var(--fw-400);
}

.fw-500 {
  font-weight: var(--fw-500);
}

.fw-600 {
  font-weight: var(--fw-600);
}

.fw-700 {
  font-weight: var(--fw-700);
}

.ff-inter {
  // font-family: var(--ff-inter);
}

.ff-satisfy {
  font-family: var(--ff-satisfy);
}

.color-blue-black {
  color: var(--color-blue-black);
}

.bg-light-black {
  background-color: var(--color-light-black);
}

.bg-blue-black {
  background-color: var(--color-blue-black);
}

.color-blue {
  color: var(--color-blue);
}

.color-white {
  color: var(--color-white);
}

.color-black {
  color: var(--color-black);
}

.color-orange {
  color: var(--color-orange);
}

.bg-orange {
  background-color: var(--color-orange);
}

.bg-light-orange {
  background-color: var(--color-orange);
}

p {
  margin-bottom: 0 !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

a {
  display: inline-block;
}

.display-none {
  display: none;
}

.ps-20 {
  padding-left: 20px;
}

.mt-13 {
  margin-top: 13px;
}

.country-drop-down {
  background-color: var(--color-white);
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
  border-radius: 11px;
  padding: 20px;
  top: 42px;
  z-index: 101;
}

.currency-drop-down {
  background-color: var(--color-white);
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
  border-radius: 11px;
  padding: 20px;
  top: 35px;
  z-index: 101;
}

.profile-drop-down {
  background-color: var(--color-white);
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
  border-radius: 11px;
  padding: 20px;
  top: 35px;
  z-index: 101;
}

.profile-border-bottm {
  border-bottom: 1px solid #0000000d;
}

.sign-in-btn {
  border: 1px solid #31449b;
  padding: 10px 28px;
  border-radius: 6px;
  transition: 0.3s;
}

.sign-in-btn:hover {
  border: 1px solid var(--color-orange);
  background-color: var(--color-orange);
  color: var(--color-white) !important;
}

.create-account-btn {
  border: 1px solid #31449b;
  padding: 10px 28px;
  border-radius: 6px;
  background-color: var(--color-blue);
  transition: 0.3s;
}

.create-account-btn:hover {
  border-color: var(--color-orange);
  background-color: var(--color-orange);
  color: var(--color-white) !important;
}

.user-proflie-btn {
  padding: 5px 9px;
  border-radius: 7px;
  border: 1px solid var(--color-orange);
}

.line-1 {
  width: 20px;
  height: 2px;
  border-radius: 6px;
  background-color: var(--color-orange);
  display: inline-block;
}

.line-2 {
  width: 20px;
  height: 2px;
  border-radius: 6px;
  margin: 4px 0;
  background-color: var(--color-orange);
  display: inline-block;
}

.line-3 {
  width: 12px;
  height: 2px;
  border-radius: 6px;
  background-color: var(--color-orange);
  display: inline-block;
}

.second-nav-btn {
  padding: 10px 21px;
  border-radius: 4px;
}

.second-nav-dropdown {
  position: absolute;
  width: 234px;
  background-color: var(--color-white);
  padding: 23px 20px;
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
  border-radius: 11px;
  z-index: 1;
}

.orange-color-hover:hover {
  color: var(--color-orange);
}

.hero-bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

header {
  min-height: 86vh;
}

.hero-form {
  padding: 45px 43px 34px 43px;
  border: 3px solid var(--color-orange);
  background-color: var(--color-white);
  border-radius: 22px;
  width: 81.5%;
  margin: 0 auto;
}

header input {
  border: none;
  outline: none;
  background-color: transparent;
}

header input::placeholder {
  color: var(--color-blue-black);
}
.d-flex.align-items-center.input-decoration.w-50.custom-w-100 {
  border: none !important;
  padding: 0px 0px 0px 29px !important;
}

.input-decoration {
  background-color: var(--color-white);
  border: 1px solid #00000021;
  padding: 17px 16px !important;
  border-radius: 5px;
  outline: none;
}
.input-decoration-event {
  background-color: var(--color-white);
  border: 1px solid #00000021;
  // padding: 17px 16px !important;
  border-radius: 5px;
  outline: none;
}
.event-date-main-div {
  margin-top: 0px !important;
}
.events-hero-bg .d-flex.align-items-center.city-dropdown {
  width: auto;
  cursor: pointer;
}
p.fs-14.fw-500.color-white.white-space-nowrap.event-search-history {
  width: 100% !important;
}
.events-hero-bg .MuiList-root.MuiList-padding.css-h4y409-MuiList-root {
  width: 100%;
}
.events-hero-bg .white-space-nowrap {
  width: 50%;
}
.events-hero-bg p.fs-14.fw-500.color-white.white-space-nowrap.mt-2.mt-md-0 {
  width: auto !important;
}

.form-submit-btn {
  background-color: var(--color-blue);
  padding: 17px 0;
  transition: 0.4s;
  border: 1px solid var(--color-blue);
  border-radius: 6px;
}

.form-submit-btn:hover {
  background-color: var(--color-orange);
  border: 1px solid var(--color-orange);
}

select option {
  background-color: white;
  border: 1px solid #00000014;
  box-shadow: -5px 4px 20px 0px #00000017;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  //   background-image: url("../../../public/Assets/Img/svg/drop-down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

Select option[disabled]:first-child {
  color: var(--color-blue-black);
  font-style: italic;
  opacity: 70%;
}

.slider-left-arrow {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-orange);
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.slider-left-arrow:hover {
  background-color: var(--color-orange);
}

.slider-left-arrow:hover .slider-arrow1 {
  stroke: var(--color-white) !important;
}

.slider-right-arrow {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-orange);
  border-radius: 50%;
  cursor: pointer;
}

.slider-right-arrow-back {
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-blue);
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--color-blue);
  transform: rotate(0deg);
}

.slider-right-arrow:hover {
  background-color: var(--color-orange);
}

.slider-right-arrow:hover .slider-arrow2 {
  stroke: var(--color-white);
}

.slider-right-arrow-back:hover {
  border: 1px solid var(--color-orange);
  background-color: var(--color-orange);
}

.top-destination-card {
  padding: 11px 11px 21px 11px;
  border-radius: 14px;
  border: 1px solid #0000000a;
  height: 100%;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-dots li button:before {
  content: '' !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 11.82px !important;
  height: 5.41px !important;
  border-radius: 20px !important;
  background-color: var(--color-blue);
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  width: 39.92px !important;
  height: 5.41px !important;
  background-color: var(--color-orange);
  z-index: 1;
  transition: 0.2s;
  left: -14px;
}

.slick-dots li.slick-active button:before .slick-dots li {
  margin: 0 20px !important;
}

.slick-dots li {
  width: 29px !important;
  margin: 0 auto !important;
}
.popular-hotels {
  background-color: #f6f6f6;
}
.commonPd {
  padding-top: 65px;
  padding-bottom: 65px;
}
.popular-attractions-btn {
  padding: 8px 16px;
  border-radius: 3px;
  border: 1px solid #0000000d;
  background-color: var(--color-white);
}

.popular-attractions-btn.active {
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: var(--fw-700);
}

.popular-attractions-btn:first-child {
  margin-left: 0 !important;
}

.more-explore-btn {
  box-shadow: 2px 4px 16px 0px #0000000d;
  background-color: var(--color-white);
  padding: 10px 28px;
}

.popular-attraction-card {
  border: 1px solid #e0e0e0;
  border-radius: 14px;
  overflow: hidden;
  height: 100%;
}
.flight-search-bg-img {
  background-image: url('../../../public/Assets/Img/png/flight-search-page-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 50%;
}
.hotel-search-bg-img {
  background-image: url('../../../public/Assets/Img/png/hotel-search-page-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 50%;
}
.card-padding {
  padding: 20px 15px 20px 15px;
}

.rating-bg {
  background-image: url('../../../public/Assets/Img/svg/rateing-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 7px 6px 7px 14px;
  right: -1px;
  top: 20px;
}
.des-input-decoration {
  background-color: $cl-white;
  border: 1px solid #00000021;
  padding: 6px 16px !important;
  border-radius: 5px;
  outline: none;
}
.white-sapce-no-wrap {
  white-space: nowrap;
}
.beach-bg {
  background-image: url('../../../public/Assets/Img/png/beach-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 70vh;
}

.video-position {
  width: 43%;
  bottom: -85px;
}

.card-width {
  width: 222px;
}

.w-69 {
  width: 69%;
}

.bg-light-white {
  background-color: var(--color-light-white);
  // min-height: 100vh;
}

.pt-170 {
  padding-top: 170px;
}

.offer-card {
  padding: 0px 0px 0px 50px;
  background-color: var(--color-white);
  border-radius: 13px;
  box-shadow: 4px 4px 23px 0px #00000017;
}

.sale-percent-off-bg {
  background-image: url('../../../public/Assets/Img/svg/offer-percent-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 9px 100px;
  background-position: right;
  text-align: end;
}

.offer-expire-bg {
  padding: 13px 100px;
  width: 400px;
  margin: 0 0 0 auto;
  background: linear-gradient(
      93.34deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0.03) 100%
    ),
    linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.06) 101.47%
    );
  border-radius: 9px;
}

.offer-card-side-bg {
  background-image: url('../../../public/Assets/Img/svg/offer-card-side-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 54px 25px 61px 0;
}

.white-snowflake-position-1 {
  right: 16px;
  top: 19px;
}

.white-snowflake-position-2 {
  left: 55px;
  bottom: 18px;
}

.light-blue-snowflake-position {
  top: 26px;
  left: 490px;
}

.blue-snowflake-position {
  top: 94px;
  left: 739px;
}

.review-card {
  box-shadow: 2px 4px 14px 0px #0000000f;
  padding: 34px 28px 49px 28px;
  background-color: var(--color-white);
  border: 1px solid #0000000a;
}

.review-profile-picture-position {
  transform: translate(-50%, -45%);
  left: 50%;
}
.flight-search-bg-img {
  background-image: url('../../../public/Assets/Img/png/flight-search-page-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 70%;
}
.hotel-search-bg-img {
  background-image: url('../../../public/Assets/Img/png/hotel-search-page-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 70%;
}
.brs-8 {
  border-radius: 8px;
}
.h-80 {
  height: 80px;
}

.footer-bottom-border {
  border: 1px solid #ffffff0d;
}

.opacity-30 {
  opacity: 30%;
}

.opacity-70 {
  opacity: 70%;
}

.befor-signin-none {
  display: none;
}

.citys::-webkit-scrollbar {
  display: none;
}

.play-store-btn {
  width: 185px;
  height: 61px;
}

@media (max-width: 1200px) {
  .bg-xsm-xl-black {
    background-color: var(--color-light-black);
    min-height: calc(100vh - 85px);
    position: absolute;
    width: 100%;
    left: -100%;
    z-index: 100;
    transition: 0.2s;
  }

  .shownav {
    left: 0;
  }

  .second-nav-dropdown {
    width: 100%;
    background-color: transparent;
    padding: 0px 30px 24px 30px;
    position: relative;
    box-shadow: none;
    border: 0;
  }

  .second-nav-dropdown a {
    color: var(--color-white);
  }

  .second-nav-btn p {
    color: var(--color-white);
  }

  .second-nav-btn {
    padding: 20px 19px 30px 19px;
    border-radius: 0;
  }

  .second-nav-border-bottom {
    border-bottom: 1px solid #202020;
  }

  .trips-dd-arrow-rotate {
    transform: rotate(180deg);
    transition: 0.1s;
  }

  .transport-dd-arrow-rotate {
    transform: rotate(180deg);
    transition: 0.1s;
  }

  .experience-dd-arrow-rotate {
    transform: rotate(180deg);
    transition: 0.1s;
  }

  .food-dd-arrow-rotate {
    transform: rotate(180deg);
    transition: 0.1s;
  }

  .more-dd-arrow-rotate {
    transform: rotate(180deg);
    transition: 0.1s;
  }

  .line1 {
    transform: rotate(-45deg);
    transition: 0.4s;
  }

  .line2 {
    width: 0 !important;
    margin: 0 !important;
    transition: 0.2s;
  }

  .line3 {
    width: 20px;
    transform: rotate(45deg);
    transition: 0.4s;
    position: relative;
    bottom: 4px;
  }

  .play-store-btn {
    width: 150px;
    /* height: 61px; */
  }
}
// .date-decoration {
//   padding: 0 !important;
// }

@media (max-width: 992px) {
  .offer-expire-bg {
    width: 200px;
    text-align: center;
  }
  // .date-decoration p,
  // div,
  // span {
  //   font-size: 14px !important;
  // }
  .new-york-btn-width {
    min-width: 113.39px;
    height: 50.5px;
  }
}

@media (max-width: 768px) {
  // .list-group-city {
  //   position: relative !important;
  // }

  .user-proflie-btn {
    padding: 0;
    border-radius: 0;
    border: none;
  }

  .bg-xsm-xl-black {
    min-height: calc(100vh - 141px);
  }

  .mt-13 {
    margin-top: 6px;
  }

  .hero-form {
    padding: 30px 25px 20px 25px;
    border: 3px solid var(--color-orange);
    background-color: var(--color-white);
    border-radius: 22px;
    width: 90.5%;
    margin: 0 auto;
  }

  .line-1 {
    width: 17px;
    height: 2px;
    border-radius: 6px;
    background-color: var(--color-orange);
    display: inline-block;
  }

  .line-2 {
    width: 17px;
    height: 2px;
    border-radius: 6px;
    margin: 4px 0;
    background-color: var(--color-orange);
    display: inline-block;
  }

  .line-3 {
    width: 10px;
    height: 2px;
    border-radius: 6px;
    background-color: var(--color-orange);
    display: inline-block;
  }

  .line3 {
    transform: rotate(45deg);
    width: 17px !important;
    transition: 0.3s;
  }

  .play-store-btn {
    width: 110px;
  }

  .download-app-section .row {
    flex-direction: column-reverse;
    text-align: center;
  }

  .download-app-section .row .col-6 {
    width: 100%;
  }

  .phone-img {
    width: 300px !important;
  }

  .pt-170 {
    padding-top: 60px !important;
  }

  .download-app-buttons {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  :root {
    --fs-12: 12px;
    --fs-16: 14px;
    --fs-17: 14px;
    --fs-18: 14px;
    --fs-20: 16px;
    --fs-23: 13px;
    --fs-29: 16px;
    --fs-38: 20px;
    --fs-44: 25px;
    --fs-58: 30px;
  }

  .nav-logo {
    width: 94px;
  }

  .w-xsm-23 {
    width: 23px;
  }

  .w-xsm-24 {
    width: 24px;
  }

  .w-xsm-21 {
    width: 21px;
  }

  .store-img {
    width: 19px;
  }

  .sign-in-btn {
    padding: 10px 70px;
    border-radius: 6px;
    transition: 0.3s;
  }

  .create-account-btn {
    border: 1px solid #31449b;
    padding: 10px 28px;
  }

  .ps-20 {
    padding-left: 16px;
  }

  .card-padding {
    padding: 20px 9px 29px 9px;
  }

  .video-position {
    width: 95%;
  }

  .beach-bg {
    min-height: 55vh;
  }

  .sale-percent-off-bg {
    padding: 1px 20px;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .offer-expire-bg {
    width: 123px;
    padding: 9px 0px;
    text-align: center;
  }

  .offer-card-side-bg {
    padding: 16px 7px 23px 0;
  }

  .offer-card {
    padding: 0px 0px 0px 6px;
  }

  .bg-xsm-xl-black {
    min-height: calc(100vh - 124px);
  }

  header {
    min-height: 62vh;
  }

  .pt-320 {
    padding-top: 300px;
  }

  .pt-320-2 {
    padding-top: 230px;
  }

  .shownav {
    min-height: calc(100vh - 55px);
  }

  .country-drop-down {
    top: 37px;
  }

  .profile-drop-down {
    left: -167px;
    width: 200px;
  }

  .new-york-btn-width {
    min-width: 108.77px;
    height: 49px;
  }

  .play-store-btn {
    width: 95px;
  }
  .hero-bg-img {
    background-size: 145%;
  }
}

@media (max-width: 400px) {
  :root {
    --fs-12: 12px;
    --fs-16: 14px;
    --fs-17: 14px;
    --fs-18: 14px;
    --fs-20: 16px;
    --fs-23: 13px;
    --fs-29: 16px;
    --fs-38: 20px;
    --fs-44: 25px;
    --fs-58: 30px;
  }

  .sign-in-btn {
    padding: 10px 52px;
    border-radius: 6px;
    transition: 0.3s;
  }

  .pt-320 {
    padding-top: 330px;
  }

  .pt-320-2 {
    padding-top: 260px;
  }

  .hero-form {
    padding: 25px 11px 20px 11px;
  }

  .play-store-btn {
    width: 90px;
  }

  .offer-section h4 {
    font-size: 18px !important;
  }
}

@media (min-width: 576px) {
  :root {
    --fs-12: 12px;
    --fs-16: 15px;
    --fs-17: 17px;
    --fs-18: 15px;
    --fs-20: 17px;
    --fs-23: 17px;
    --fs-29: 18px;
    --fs-38: 21px;
    --fs-44: 28px;
    --fs-58: 40px;
  }

  .nav-logo {
    width: 120px;
  }

  .ps-20 {
    padding-left: 13px;
  }

  .video-position {
    width: 90%;
  }

  .offer-card-side-bg {
    padding: 16px 25px 23px 0;
  }

  .sale-percent-off-bg {
    padding: 9px 34px;
    display: inline-block;
  }

  .offer-expire-bg {
    padding: 9px 10px;
    width: 148px;
  }

  .offer-card {
    padding: 0px 0px 0px 6px;
  }

  .pt-320 {
    padding-top: 130px;
  }

  .pt-320-2 {
    padding-top: 55px;
  }

  .profile-drop-down {
    left: -94px;
    width: 220px;
    top: 43px;
  }
}

@media (min-width: 768px) {
  :root {
    --fs-12: 12px;
    --fs-16: 15px;
    --fs-17: 15px;
    --fs-18: 18px;
    --fs-20: 17px;
    --fs-23: 16px;
    --fs-29: 22px;
    --fs-38: 24px;
    --fs-44: 30px;
    --fs-58: 48px;
  }

  .video-position {
    width: 58%;
  }

  .pt-170 {
    padding-top: 66px;
  }

  .w-300 {
    width: 300px;
  }

  .sale-percent-off-bg {
    padding: 1px 50px;
  }

  .offer-expire-bg {
    width: 200px;
    text-align: center;
  }

  .offer-card-side-bg {
    padding: 54px 25px 61px 0;
  }

  .offer-card {
    padding: 0px 0px 0px 50px;
  }

  .fs-md-29 {
    font-size: var(--fs-29);
  }

  .hero-form {
    padding: 38px 36px 27px 36px;
    border: 3px solid var(--color-orange);
    background-color: var(--color-white);
    border-radius: 22px;
    width: 90.5%;
    margin: 0 auto;
  }

  .profile-drop-down {
    left: -20px;
  }

  .pt-320 {
    padding-top: 50px;
  }
}

@media (min-width: 992px) {
  :root {
    --fs-12: 12px;
    --fs-16: 16px;
    --fs-17: 17px;
    --fs-18: 15px;
    --fs-20: 17px;
    --fs-23: 18px;
    --fs-29: 23px;
    --fs-38: 28px;
    --fs-44: 36px;
    --fs-58: 58px;
  }

  .nav-logo {
    width: 142px;
  }

  .ps-20 {
    padding-left: 20px;
  }

  .video-position {
    width: 43% !important;
    bottom: -140px;
  }

  .sale-percent-off-bg {
    padding: 9px 85px;
    text-align: end;
  }

  .offer-expire-bg {
    padding: 13px 37px;
    width: 265px;
  }

  .hero-form {
    padding: 45px 43px 34px 43px;
    border: 3px solid var(--color-orange);
    background-color: var(--color-white);
    border-radius: 22px;
    width: 81.5%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  :root {
    --fs-12: 12px;
    --fs-16: 16px;
    --fs-17: 17px;
    --fs-18: 18px;
    --fs-20: 18px;
    --fs-23: 23px;
    --fs-29: 29px;
    --fs-38: 38px;
    --fs-44: 44px;
    --fs-58: 58px;
  }

  .second-nav-dropdown {
    top: 52px;
  }

  .second-nav-btn:hover {
    background-color: var(--color-light-orange);
    color: var(--color-black);
  }

  .nav-dropdown-btn {
    padding: 5px 10px;
    transition: 0.2s ease-out;
  }

  .nav-dropdown-btn:hover {
    padding: 10px 15px;
    border-radius: 4px;
    background-color: var(--color-light-orange);
    transition: 0.2s ease-in-out;
  }

  .w-300 {
    width: 525px;
  }

  .pt-170 {
    padding-top: 170px;
  }

  .sale-percent-off-bg {
    padding: 9px 100px;
    background-position: right;
    text-align: end;
  }

  .offer-expire-bg {
    padding: 13px 100px;
    width: 400px;
  }

  .profile-drop-down {
    left: 0px;
  }
}

li {
  cursor: pointer;
}

.boxes-container {
  min-height: 100vh;
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 251, 255, 0.6);
}

.boxes {
  height: 32px;
  width: 32px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin-top: 32px;
  -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes .box {
  width: 32px;
  height: 32px;
  top: 0px;
  left: 0;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-animation: box1 1s linear infinite;
  animation: box1 1s linear infinite;
}

.boxes .box:nth-child(2) {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-animation: box2 1s linear infinite;
  animation: box2 1s linear infinite;
}

.boxes .box:nth-child(3) {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: box3 1s linear infinite;
  animation: box3 1s linear infinite;
}

.boxes .box:nth-child(4) {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-animation: box4 1s linear infinite;
  animation: box4 1s linear infinite;
}

.boxes .box > div {
  background: #5c8df6;
  --translateZ: 15.5px;
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5c8df6;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  top: 0;
  left: 0;
  background: #5c8df6;
}

.boxes .box > div:nth-child(2) {
  background: #145af2;
  right: 0;
  --rotateY: 90deg;
}

.boxes .box > div:nth-child(3) {
  background: #447cf5;
  --rotateX: -90deg;
}

.boxes .box > div:nth-child(4) {
  background: #dbe3f4;
  top: 0;
  left: 0;
  --translateZ: -90px;
}

.no-flights {
  width: 100%;
  height: 220px;
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

.header_border_line {
  border: 1px solid #f2f2f2;
  width: 100%;
  position: absolute;
  left: 0;
}

.nav-tab {
  opacity: 70%;
}

.nav-tab:hover {
  opacity: 100%;
}

.lazy-card-img {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
  object-position: 0 0;
}

.list-group-city {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  padding: 10px;
  background: white;
  border-radius: 0 0 7px 7px;
  display: block;
  max-width: 450px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.list-group-city::-webkit-scrollbar {
  width: 10px;
}

.list-group-city::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.hotel-result-scroll {
  margin-top: 25px;
}

.hotel-result-scroll::-webkit-scrollbar {
  width: 10px;
}

.hotel-result-scroll::-webkit-scrollbar-thumb {
  height: 8rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.list-group-city::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

.list-group-city li {
  list-style: block;
  background: white;
  padding: 10px;
  border-radius: 5px;
  color: #2f3542;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #dfe4ea;
  }
}

.passenger-drop-down {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

.setIndex {
  z-index: 1 !important;
}

.rdrCalendarWrapper-d-none {
  display: none !important;
}

.rdrCalendarWrapper-z-index {
  z-index: 1;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
}

.fligth-result-page-vh {
  min-height: 40vh !important;
}

.hotel-result-page-vh {
  min-height: 25vh !important;
}

.hotel-view-page-vh {
  min-height: 30vh !important;
}

.flight-result-page-vh {
  min-height: 0vh !important;
}

.button-sort-active {
  border-bottom: 3px solid #fda400 !important;
}

.offer-card-1 {
  margin-right: 9px;
  padding: 8px 15px 43px 25px;
  background: radial-gradient(
    272.82% 71.87% at 25.79% 35.1%,
    #5970d7 0%,
    #31449b 100%
  );
  border-radius: 8px;
  height: 100%;
}

.offer-card-1 .offer-timer-bg {
  padding: 61px 60px 72px 60px;
  background-image: url('../../../public/Assets/icons/offer-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.hotel-stars label span svg {
  width: 40px !important;
}
.offer-card-1 .book-now-btn {
  padding: 16px 48px;
  background-color: #fda400;
  border-radius: 6px;
  margin-right: 19px;
}

.offer-card-1 .book-now-btn:hover {
  color: #ffffff;
}

.offer-card-2 {
  margin-left: 9px;
  padding: 8px 15px 43px 25px;
  background: radial-gradient(
    272.82% 71.87% at 25.79% 35.1%,
    #d75959 0%,
    #9c3232 100%
  );
  border-radius: 8px;
  height: 100%;
}

.offer-card-2 .offer-timer-bg {
  padding: 61px 60px 72px 60px;
  background-image: url('../../../public/Assets/icons/offer-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.offer-card-2 .book-now-btn {
  padding: 16px 48px;
  background-color: #fda400;
  border-radius: 6px;
  margin-right: 19px;
}

.offer-card-2 .book-now-btn:hover {
  color: #ffffff;
}

.top-cities .top-cities-card {
  border: 1px solid rgba(0, 0, 0, 0.0392156863);
  padding: 11px 11px 20px 11px;
  background-color: #ffffff;
  border-radius: 14px;
}

.popular-hotels .popular-hotels-card {
  border: 1px solid #e0e0e0;
  border-radius: 14px;
  background-color: #ffffff;
  overflow: hidden;
}

.popular-hotels .popular-hotels-card .card-padding {
  padding: 20px 12px 22px 12px;
}

.search-label-hotel {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #8592a6;
  position: unset;
  flex-basis: content;
  flex-shrink: 0;
  max-width: 50%;
  font-weight: 600;
}

@media (max-width: 1400px) {
  .offer-card-1 .offer-timer-bg {
    padding: 50px;
  }

  .offer-card-2 .offer-timer-bg {
    padding: 50px;
  }
}

@media (max-width: 1200px) {
  .offer-card-1 .offer-timer-bg {
    padding: 40px;
    margin-bottom: 10px !important;
  }

  .offer-card-1 .book-now-btn {
    padding: 12px 40px;
  }

  .offer-card-2 .offer-timer-bg {
    padding: 40px;
    margin-bottom: 10px !important;
  }

  .offer-card-2 .book-now-btn {
    padding: 12px 40px;
  }
}

@media (max-width: 992px) {
  .offer-card-1 .offer-timer-bg {
    padding: 28px 23px 33px 25px;
    margin-bottom: 10px !important;
  }

  .offer-card-1 .book-now-btn {
    padding: 12px 21px;
  }

  .offer-card-2 .offer-timer-bg {
    padding: 28px 23px 33px 25px;
    margin-bottom: 10px !important;
  }

  .offer-card-2 .book-now-btn {
    padding: 12px 21px;
  }
}

@media (max-width: 768px) {
  .search-label-hotel {
    display: none;
  }

  .offer-card-1 {
    padding: 8px 15px 30px 25px;
    margin: 0;
  }

  .offer-card-2 {
    padding: 8px 15px 30px 25px;
    margin: 0;
  }

  .top-cities .top-cities-card p {
    font-size: 14px;
  }
}

#hotel-search-result-header .overlay {
  position: fixed;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7098039216);
  z-index: 2;
}

#hotel-search-result-header .hero-bg-blue {
  width: 100%;
  height: 320px;
  background-color: #31449b;
}

@media (min-width: 768px) {
  .hotel-search-bg-img {
    padding-top: 100px;
  }
}

.hotel-room-book-form {
  padding: 25px 21px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 0px;
  position: relative;
  box-shadow: 3px 4px 26px 0px rgba(0, 0, 0, 0.0509803922);
}

.hotel-room-book-form .hotel-search-btn {
  padding: 16px 50px;
  background-color: #31449b;
  border: none;
  outline: none;
  border-radius: 6px;
}

.hotel-view {
  margin-top: 19px;
}

.hotel-view .hotel-sider-filters {
  position: sticky;
  top: 0;
  z-index: 0;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1019607843);
}

.hotel-view .hotel-sider-filters::-webkit-scrollbar {
  width: 6px;
}

.hotel-view .hotel-sider-filters::-webkit-scrollbar-thumb {
  background-color: #fda400;
  border-radius: 6px;
}

.hotel-view .hotel-sider-filters::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.hotel-view .hotel-sider-filters .view-map {
  position: relative;
}

.hotel-view .hotel-sider-filters .view-map img {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
}

.hotel-view .hotel-sider-filters .view-map .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.hotel-view .hotel-sider-filters .view-map .view-map-btn-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 163px;
}

.hotel-view
  .hotel-sider-filters
  .view-map
  .view-map-btn-position
  .view-map-btn {
  padding: 5px 13px;
  border-radius: 5px;
  background-color: #31449b;
}

.hotel-view
  .hotel-sider-filters
  .view-map
  .view-map-btn-position
  .view-map-btn:hover {
  background-color: #fda400;
  cursor: pointer;
}

.hotel-view .hotel-sider-filters .rating-price-distence-amenities {
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 8px;
  padding: 22px 16px 40px 11px;
  background-color: #ffffff;
  overflow: hidden;
}

.hotel-view
  .hotel-sider-filters
  .rating-price-distence-amenities
  .guest-rating {
  width: 54px;
  padding: 10px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.guest-rating-active {
  background-color: #31449b;
  opacity: 1;
  color: white;
}

.hotel-view .hotel-sider-filters .rating-price-distence-amenities .price-range {
  background: #dee2e6;
  border-radius: 28px;
  height: 2.72px;
  width: 100%;
  outline: none;
  transition: 450ms ease-in;
  appearance: none;
  cursor: pointer;
}

.hotel-view
  .hotel-sider-filters
  .rating-price-distence-amenities
  .price-range::-webkit-slider-thumb {
  width: 9px;
  -webkit-appearance: none;
  height: 9px;
  border-radius: 50%;
  accent-color: red;
  background: #31449b;
}

.hotel-view .hotel-sider-filters .rating-price-distence-amenities .amenities {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 3px;
  cursor: pointer;
}

.hotel-view .hotels .hotel-price-distance-rating {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px 0 22px 0;
  z-index: 1;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs {
  position: relative;
  margin: 0px 9px;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgba(16, 16, 18, 0.0901960784);
  bottom: 0px;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .filter-show-btn {
  padding: 8px 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  outline: none;
  background-color: transparent;
  border-radius: 8px;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .price-tab {
  padding: 0 0px 16px 0px;
  width: 15%;
  position: relative;
  z-index: 1;
  border: none;
  outline: none;
  background-color: transparent;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .distance-tab {
  padding: 0 0px 16px 0px;
  width: 17%;
  position: relative;
  z-index: 1;
  border: none;
  outline: none;
  background-color: transparent;
}

.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .price-tab::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.0509803922);
  left: -35%;
  bottom: 10px;
}

.hotel-sort-button-active {
  padding: 14.5px 20px;
  border-radius: 6px;
  background-color: #31449b;
}

// .hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .price-tab::before {
//   content: "";
//   position: absolute;
//   width: 1px;
//   height: 90%;
//   background-color: rgba(0, 0, 0, 0.0509803922);
//   right: -35%;
//   bottom: 10px;
// }
.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .rating-tab {
  padding: 0 0px 16px 0px;
  width: 100px;
  position: relative;
  z-index: 1;
}

.hotel-view
  .hotels
  .hotel-price-distance-rating
  .hotel-tabs
  .rating-tab
  select {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

.hotel-view
  .hotels
  .hotel-price-distance-rating
  .hotel-tabs
  .rating-tab::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.0509803922);
  right: -45%;
  bottom: 10px;
  z-index: 2;
}
.react-tel-input .form-control {
  font-size: 18px !important;
  // font-family: $ff-inter !important;
  margin-left: 0 !important;
  padding-left: 120px !important;
  border: 1px solid rgba(0, 0, 0, 0.1294117647) !important;
}
.react-tel-input .selected-flag .arrow {
  border: 0 !important;
  background-image: url('../../../public/Assets/icons/drop-down-arrow.svg');
  background-position: center center;
  width: 100% !important;
  height: 100% !important;
  top: 2px !important;
  left: 25px !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.react-tel-input .selected-flag .arrow.up {
  transform: rotate(180deg);
}
.react-tel-input .selected-flag .flag {
  left: 25px !important;
  transform: scale(1.3);
}

.react-tel-input .country-list {
  background-color: white !important;
  width: 100% !important;
  margin-top: 2px !important;
  border-radius: 8px !important;
  max-height: 317px !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1294117647);
}
.react-tel-input .flag-dropdown {
  position: relative !important;
  border: 0 !important;
}
.react-tel-input .selected-flag {
  position: absolute !important;
  height: 60px !important;
  width: 95px !important;
  background-color: white;
  top: -60px;
  border: 1px solid rgba(0, 0, 0, 0.1294117647);
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px !important;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.react-tel-input .country-list .search-box {
  width: 100% !important;
  margin-left: 0 !important;
  padding: 15px 10px 15px 55px !important;
  position: relative;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1294117647) !important;
}
.react-tel-input .country-list .country-name {
  font-size: 15px !important;
  color: #101012 !important;
  font-weight: 500;
  margin: 0 4px 0 15px !important;
}
.react-tel-input .flag {
  transform: scale(1.2);
}
.react-tel-input .country-list .search {
  z-index: 1;
}
.react-tel-input .country-list .country .dial-code {
  font-size: 15px !important;
  color: #101012 !important;
  font-weight: 500;
}
.react-tel-input .country-list .search::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: url('../../../public/Assets/icons/search-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  top: 15px;
  left: 16px;
}
.react-tel-input .country-list .country {
  background-color: white !important;
  padding: 15px 16px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1294117647);
  &:hover {
    background-color: #eae8e886 !important;
  }
}
.react-tel-input .country-list .country:nth-child(2) {
  border-top: none !important;
}
.react-tel-input .country-list .search {
  padding: 0 !important;
}
.react-tel-input .country-list .search-emoji {
  display: none !important;
}
.hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .guest-rating-tab {
  padding: 0 0px 16px 0px;
  width: 150px;
  position: relative;
  z-index: 1;
  margin-right: 50px;
}

.hotel-view
  .hotels
  .hotel-price-distance-rating
  .hotel-tabs
  .guest-rating-tab
  select {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

.hotel-view .hotels .hotel-view-cards {
  padding: 12px;
  background-color: #fbfbfd;
  margin-top: 40px;
  border-radius: 8px;
}

.hotel-view .hotels .hotel-view-cards .h-275 {
  height: 275px;
}

.hotel-view .hotels .hotel-view-cards .guest-rating {
  padding: 2px 10px;
  border-radius: 21px;
  background-color: #31449b;
  margin: 0 11px 0px 8px;
}

.hotel-view .hotels .hotel-view-cards .save-btn {
  padding: 15px 18px;
  background-color: #fda400;
  border-radius: 6px;
}

.hotel-view .hotels .hotel-view-cards .view-hotel-btn {
  padding: 14.5px 20px;
  border-radius: 6px;
  background-color: #31449b;
}

.hotel-view .hotels .hotel-view-cards .view-hotel-btn:hover {
  color: white;
}

.hotel-guest-card-w {
  max-width: 360px !important;
  width: 300px;
}

// .hotel-search-input-card-w {
//   width: 400px !important;
// }

@media (max-width: 1400px) {
  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .distance-tab::before {
    right: -27%;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .rating-tab::before {
    right: -32%;
  }

  .hotel-search-input-card-w {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .hotel-room-book-form .input-decoration {
    width: 100%;
  }

  .hotel-view {
    margin-top: 19px;
  }

  .hotel-view .hotel-sider-filters {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 290px;
    background-color: #ffffff;
    left: -100%;
    top: 0;
    transition: 0.4s;
  }

  .hotel-view .hotels .hotel-price-distance-rating {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0470588235);
    padding: 22px 0;
  }

  .hotel-view .hotels .hotel-view-cards .save-btn {
    padding: 12.5px 18px;
  }

  .hotel-guest-card-w {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .distance-tab::before {
    right: -27%;
    width: 0px;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .distance-tab::after {
    right: -27%;
    width: 0px;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .rating-tab::before {
    right: -32%;
    width: 0px;
  }
}

@media (max-width: 768px) {
  .hotel-room-book-form {
    padding: 16px;
  }

  .hotel-room-book-form .input-decoration {
    padding: 10px 13px;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .guest-rating-tab {
    margin-right: 0px;
  }
}

@media (max-width: 576px) {
  .hotels .hotel-view-cards .fs-26 {
    font-size: 18px;
  }

  .hotels .hotel-view-cards .fs-14 {
    font-size: 12px;
  }

  .hotel-view .hotels .hotel-price-distance-rating .hotel-tabs {
    position: relative;
  }

  .hotel-view .hotels .hotel-price-distance-rating .hotel-tabs::after {
    height: 0;
  }

  .hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .price-btn {
    width: 50%;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .filter-show-btn-width {
    width: 50%;
    border-left: 1px solid rgba(0, 0, 0, 0.1019607843);
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .filter-show-btn-width
    .filter-show-btn {
    margin-bottom: 0;
  }

  .hotel-room-book-form .hotel-search-btn {
    width: 100%;
  }
  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .price-rating-drop-down {
    position: absolute;
    width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1019607843);
    border-radius: 6px;
    background-color: #ffffff;
    top: 61px;
    padding: 10px;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.1s;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .price-rating-drop-down
    .price-tab {
    padding: 5px 0;
    text-align: start;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .price-rating-drop-down
    .distance-tab {
    padding: 5px 0;
    text-align: start;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .price-rating-drop-down
    .rating-tab {
    padding: 5px 0;
  }

  .hotel-view
    .hotels
    .hotel-price-distance-rating
    .hotel-tabs
    .price-rating-drop-down
    .guest-rating-tab {
    padding: 5px 0;
    width: 100%;
  }

  .hotel-view .hotels .hotel-price-distance-rating .hotel-tabs .price-dd-show {
    transform: scaleY(1);
  }

  .hotel-view .hotels .hotel-view-cards .save-btn {
    padding: 8.5px 13px;
  }

  .hotel-view .hotels .hotel-view-cards .view-hotel-btn {
    padding: 11px 14px;
  }
}

/*# sourceMappingURL=style.css.map */
/* -----car-rental------*/
.switch-icon-car-rental {
  top: 15px;
  right: 310px;
}

.car-search-book-button {
  border: 0;
  outline: 0;
  padding: 13px 30px;
  background-color: #31449b;
  border-radius: 6px;
}

.car-card mx-2 card1 {
  width: fit-content;
}

.slick-prev {
  left: -20px;
  top: 36%;
}

.slick-next {
  right: -25px;
  top: 36%;
}

.css-1gjgmky-MuiToggleButtonGroup-root
  .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 5px 0px;
  margin-right: 10px;
}

.css-1gjgmky-MuiToggleButtonGroup-root {
  display: contents !important;
}

.css-1gjgmky-MuiToggleButtonGroup-root
  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #f2f2f2 !important;
  background-color: rgba(0, 0, 0, 0.08);
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  box-shadow: none;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 8px 1px 16px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  padding: 1px;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  margin-top: 20px !important;
}

.car-detail-expand {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
}

.car-detail-expand > div:first-child {
  width: 100%;
}

.car-detail-expand > div:last-child {
  background-color: #fff;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 3px 4px 36px 0px rgba(0, 0, 0, 0.0705882353);
}

.car-card {
  width: 100%;
  box-shadow: 3px 4px 36px 0px rgba(0, 0, 0, 0.0705882353);
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
}

.car-card-upper {
  box-shadow: none;
}

.car-card .inner-padding {
  padding: 30px 25px 54px 37px;
}

.car-result-card {
  border: 1px solid rgba(16, 16, 18, 0.1019607843);
  padding: 25px 26px 55px 26px;
  border-radius: 8px;
  margin-top: 11px;
  background-color: unset;
}

.car-detail-card > div:first-child {
  margin: 0 !important;
}

.car-contain {
  bottom: 822px;
  position: relative;
}

.rating-car {
  font-size: 52px;
  padding-top: 15px;
}

.blue-box-car-rental {
  height: 495px;
}

@media (max-width: 1410px) {
  .car-contain {
    bottom: 660px;
  }

  .rating-car {
    font-size: 45px !important;
    padding-top: 15px;
  }
}

@media (max-width: 1200px) {
  .car-search-sidebar {
    display: none;
  }

  .blue-box-car-rental {
    height: 345px;
  }

  .car-contain {
    bottom: 725px;
  }

  .car-result-card {
    margin-top: 35px;
  }
}
.react-time-picker {
  .react-time-picker__wrapper {
    border: none;
  }
  .react-time-picker__button {
    display: none;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: transparent;
  }
}

@media (min-width: 992px) {
  .gap-lg-custom {
    gap: 2rem !important;
  }
}
@media (max-width: 575px) {
  .custom-w-100 {
    width: 100% !important;
  }
  .custom-flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 768px) {
  .custom-top-100 {
    top: 100px;
  }
}
// header styles for smaller screens
@media only screen and (max-width: 429px) {
  .menu-items {
    .currency-drop-down {
      padding-left: 8px !important;
    }

    .country-drop-down {
      padding-left: 8px !important;
    }

    .profile-drop-down {
      padding-left: 14px !important;
    }

    li div {
      padding-left: 3px !important;
    }

    li div img {
      margin-left: 2px !important;
    }

    li:nth-child(4) {
      margin-left: 4px !important;
    }
  }

  .header-nav {
    width: 120px;
    column-gap: 0;

    .menu {
      margin-right: 0px !important;
    }
  }

  // profile button
  .profile-btn {
    a {
      img {
        max-width: 25px;
      }
    }
  }
}

@media (max-width: 575px) {
  .events-hero-bg .d-flex.align-items-center.city-dropdown {
    background-color: rgba(255, 255, 255, 0.1607843137);
    border-radius: 30px;
    margin-bottom: 20px;
    width: 100%;
    height: 47px;
  }
  .events-hero-bg .white-space-nowrap {
    width: 77%;
  }
  .events-hero-bg
    .MuiCollapse-root.MuiCollapse-vertical.meal-box.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root {
    min-height: 0px !important;
    width: 90% !important;
    margin-top: 10px !important;
    height: auto;
    transition-duration: 592ms !important;
  }
}

.text-red {
  color: #ff0000;
}

.manage-booking .hotelName {
  width: 100%;
  min-width: 350px;
}
