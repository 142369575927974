.passengers-box {
  z-index: 2;
  width: 100%;
  position: absolute;
  background: #fff;
  border-radius: 0 0 7px 7px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}
.acitivity-box {
  z-index: 1;
  width: 100%;
  position: absolute;
  max-height: 300px !important;
  overflow-y: auto !important;
  background: #fff;
  border-radius: 0 0 7px 7px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  // transition-duration: 380ms !important;
}
.acitivity-box::-webkit-scrollbar {
  width: 10px;
}

.acitivity-box::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.acitivity-box::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
.css-1t4vnk2-MuiDialogContent-root::-webkit-scrollbar {
  width: 10px;
}

.css-1t4vnk2-MuiDialogContent-root::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.css-1t4vnk2-MuiDialogContent-root::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
.MuiDialogContent-root
  .MuiDialogContent-dividers
  .css-1r09u4m::-webkit-scrollbar {
  width: 10px;
}

.MuiDialogContent-root
  .MuiDialogContent-dividers
  .css-1r09u4m::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.MuiDialogContent-root
  .MuiDialogContent-dividers
  .css-1r09u4m::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
.meal-box {
  z-index: 1;
  width: 100%;
  position: absolute;
  max-height: 300px !important;
  overflow-y: auto !important;
  background: #fff;
  border-radius: 0 0 7px 7px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  // transition-duration: 380ms !important;
}
.meal-box::-webkit-scrollbar {
  width: 10px;
}

.meal-box::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.meal-box::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

.class-select-box {
  z-index: 1;
  width: 100%;
  position: absolute;
  max-height: 200px !important;
  overflow-y: auto !important;
  background: #fff;
  border-radius: 0 0 7px 7px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  // transition-duration: 380ms !important;
}
.class-select-box::-webkit-scrollbar {
  width: 10px;
}

.class-select-box::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.class-select-box::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

/* responsive */

@media screen and (max-width: 768px) {
  .acitivity-box {
    max-height: 200px !important;
  }
  .meal-box {
    max-height: 200px !important;
  }
  .class-select-box {
    max-height: 200px !important;
  }
}

// airport search
.all-airport {
  font-weight: 600;
  color: #0f294d;
  font-size: 14px;
}
.airport-code {
  margin-right: 4px;
  color: #8592a6;
  font-size: 12px;
}
.airport-secondary {
  color: #8592a6;
  font-size: 12px;
}
.airport__name {
  display: inline-block;
  margin-right: 8px;
  color: #0f294d;
  font-size: 14px;
}
// flight stepper
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  // color: var(--color-green) !important;
  color: var(--color-orange) !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: var(--color-blue) !important;
}
.css-1hv8oq8-MuiStepLabel-label {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.sortBy {
  color: var(--color-blue-black) !important;
  font-weight: 600 !important;
  font-size: var(--fs-18) !important;
  line-height: 1em !important;
  letter-spacing: 0em !important;
}

// hotel image galllary
.hotel-img-grid::-webkit-scrollbar {
  width: 10px;
}

.hotel-img-grid::-webkit-scrollbar-thumb {
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #31449b;

  &:hover {
    background-color: #fda400;
  }
}

.hotel-img-grid::-webkit-scrollbar-track {
  // background-color: #ffffff00;
  background-color: #f5f7fa;
}
.css-5xe99f-MuiLinearProgress-bar1 {
  background-color: #31449b !important;
}
// hotel compare
.css-9l3uo3 {
  color: $cl-white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.css-1rlbz42 {
  color: $cl-orange !important;
}
.css-ahj2mt-MuiTypography-root {
  color: $cl-white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.css-vubbuv {
  fill: $cl-orange !important;
}
// date pickup
.css-1bn53lx {
  flex-direction: row-reverse !important;
  border: none !important;
}
.css-slyssw {
  color: $cl-white !important;
}
.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: $cl-orange !important;
}
// my flight search
.css-tlelie-MuiListItemText-root {
  margin-top: 2px !important;
}
// steps

.css-4ff9q7.Mui-active {
  color: $cl-blue !important;
}

.MuiDateRangeCalendar-root div:first-child {
  color: transparent !important;
  z-index: 0 !important;
}

.MuiTypography-root {
  color: black !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.css-1x94ue7-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
.css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #31449b !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight
  .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
  color: white !important;
  font-weight: 500 !important;
}

.date-decoration {
  padding: 7px 8px !important;
}

.date-decoration fieldset {
  border: 0px !important;
}

.date-decoration .MuiTextField-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  cursor: pointer;
}

.date-value {
  padding: 10px 17px;
}

.css-12rk8f9-MuiDateRangePickerDay-root,
.css-1w1dd3b-MuiDateRangePickerDay-root {
  background-color: white !important;
}
.MuiDateRangePickerDay-rangeIntervalDayHighlight .Mui-selected {
  color: white !important;
  background-color: rgb(49 68 155) !important;
  font-weight: 500;
}
.MuiDateRangePickerDay-outsideCurrentMonth {
  background-color: white !important;
}
.MuiDateRangePickerDay-dayInsideRangeInterval {
  color: white !important;
}

.events-date-picker {
  .MuiInputBase-root.MuiOutlinedInput-root {
    width: 100%;
  }

  .MuiSvgIcon-root {
    fill: #6f6f71 !important;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 18.5px 16px;
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1294117647) !important;
}

.MuiButtonBase-root.Mui-selected {
  background-color: var(--color_blue) !important;
  color: white;
}
.MuiListItemButton-root.Mui-disabled {
  cursor: not-allowed !important;
}
.MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border-color: var(--color_blue) !important;
}
