.popular-attractions-btn {
  padding: 13px 21px;
  border-radius: 4px;
  border: 1px solid #0000000d;
  background-color: $cl-white;
}

.popular-attractions-btn.active {
  background-color: $cl-blue;
  color: $cl-white;
  font-weight: $fw-700;
}
.packing-list.active {
  background-color: $cl-blue;
  color: $cl-white;
  font-weight: $fw-700;
}
.packing-list {
  padding: 13px 21px;
  border-radius: 4px;
  border: 1px solid #0000000d;
  background-color: $cl-white;
}

.more-explore-btn {
  box-shadow: 2px 4px 16px 0px #0000000d;
  background-color: $cl-white;
  padding: 10px 28px;
}

.popular-attraction-card {
  border: 1px solid #0000000a;
  border-radius: 14px;
  overflow: hidden;
}

.card-padding {
  padding: 20px 12px 29px 12px;
}

.rating-bg {
  background-image: url('../../../../public/Assets/Img/svg/rating-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 7px 6px 7px 14px;
  right: 0;
  top: 20px;
}
