.car-rental-search-page {
  .hero-small-blue-bg {
    height: 320px;
  }
}

// .switch-icon {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 36px;
//     width: 36px;
//     border-radius: 50%;
//     background-color: $cl-blue;
//     position: absolute;
// }

.car-rental-search-form {
  // .switch-icon {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     height: 36px;
  //     width: 36px;
  //     border-radius: 50%;
  //     background-color: $cl-blue;
  //     position: absolute;
  //     top: 50%;
  //     transform: translate(-50%, -50%);
  // }
}

.w-xl-50 {
  width: 50%;
}

.car-rental-sidebar {
  .border-top-gray {
    border-top: 1px solid #0000001a;
  }
}

.car-models-slider {
  background-color: $cl-white;
  padding: 13px 50px;
  border: 1px solid #1010121a;
  border-radius: 8px;

  .car-card {
    text-align: center;
    border: 1px solid #1010121a;
    padding: 16px 27px 0 27px;
    border-radius: 8px;
    width: 167px !important;
    height: 100%;
  }
}

.car-slider {
  .car-models-right-arrow {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }
  .car-models-right-arrow path {
    fill: #101012 !important;
  }

  .car-models-left-arrow {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }
  .car-models-left-arrow path {
    fill: #101012 !important;
  }
}

.cars-detals {
  border: 1px solid #1010121a;
  padding: 25px 26px 55px 26px;
  border-radius: 8px;
  margin-top: 11px;

  .w-191 {
    width: 191px;
  }
}

@media (max-width: 1200px) {
  .w-xl-50 {
    width: 100%;
  }
  .w-lg-100 {
    width: 100%;
  }
}

@media (min-width: 992px) {
  // .switch-icon {
  //     top: 44px;
  //     left: 48.5%;
  // }
}

@media (max-width: 768px) {
  .car-rental-search-form {
    .hotel-search-btn {
      padding: 11px 40px;
    }
  }
}

@media (max-width: 576px) {
  .car-rental-search-form {
    // .switch-icon {
    //     transform: translate(-50%, -50%) rotate(90deg);
    // }
  }

  .car-rental-search-form {
    .hotel-search-btn {
      padding: 13px;
    }
  }

  .w-xsm-100 {
    width: 100%;
  }
}

// car search reasult page css 26/02/2024
header.car-search-result-header {
  min-height: 0vh !important;
}
p.intermediate-tag.fs-16.fw-600.color-orange {
  border: 1px solid #fda400;
  border-radius: 3px;
  padding: 8px 10px;
  color: #fda400;
  background-color: #fff3de;
  width: fit-content;
}
.switch-icon-car-search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #31449b;
  /* position: absolute; */
  top: 50px;
  right: -42px;
}
.car-rental-search-result {
  .css-qiexmm-MuiStack-root-MuiMultiInputDateRangeField-root {
    opacity: 0;
  }
  .date-value {
    white-space: nowrap;
    overflow-x: hidden;
    padding: 0px 0px;
  }
  .date-decoration {
    padding: unset;
  }
  .custom-width {
    width: 80%;
  }
  .cw-50 {
    width: 50%;
  }
}

.MuiStack-root.hotel-date-picker.MuiMultiInputDateRangeField-root.css-hw8s2-MuiStack-root-MuiMultiInputDateRangeField-root {
  display: none !important;
}
// .input-decoration.d-flex.align-items-center.w-100 {
//     width: 50% !important;
// }
@media (max-width: 576px) {
  .custom-width {
    width: 100% !important;
  }
  // .input-decoration.d-flex.align-items-center.w-100 {
  //     width: 100% !important;
  // }
  .switch-icon-car-search {
    transform: translateX(-50%) rotate(90deg);
  }
  .car-rental-search-result {
    .cw-50 {
      width: 100% !important;
    }
  }
}
.aria-expended-icon-div {
  // background-color: #ffffff !important;
  border: none;
  padding-top: -10px;
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 73%;
  border-radius: 50px;
  margin-top: -15px;
  margin-bottom: 16px;
}

.aria-expended-icon-div .see-car-dtl {
  z-index: 1;
  height: 31px;
  width: 31px;
  background-color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
}

.aria-expended-icon-div .hide-car-dtl {
  z-index: 1;
  height: 36px;
  width: 36px;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 8px;
}

.hero-small-blue-bg-car {
  background-color: #31449b;
  width: 100%;
  height: 320px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.d-flex.align-items-center.review-div {
  padding-left: 0px;
  padding-bottom: 16px;
}

.d-flex.align-items-center.justify-content-end.justify-content-xl-end {
  margin-top: 10px;
  margin-bottom: 25px;
}

.car-details-main-div {
  padding: 15px;
  border: 1px solid #e4dddd;
  border-radius: 8px;
  margin-top: 8px;
}

select.fs-16.fw-400.color-blue-black.ms-3.input-decoration.w-191 {
  background-color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.1294117647);
  padding: 12px 16px !important;
  border-radius: 5px;
  outline: none;
  height: 51px;
}

.pt-4.px-4.car-detail-second-section {
  border-top: 1px solid #f4efef;
}

.col-12.col-sm-6.col-md-4.py-3.colomn-1 {
  border-right: 1px solid #f4efef;
}
label.css-dqr9h-MuiRating-label {
  padding-right: 15px !important;
}
.pt-4.px-4.car-detail-second-section {
  border-top: 1px solid #f4efef;
}

.col-12.col-sm-6.col-md-4.py-3.colomn-2 {
  border-right: 1px solid #f4efef;
  padding-left: 30px;
}
label.css-dqr9h-MuiRating-label {
  padding-right: 15px !important;
}
.position-relative.car-slider-main-div {
  border: 1px solid #f4efef;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  text-align: center;
  height: 210px;
}

.car-card-slider-div {
  text-align: center;
  border: 1px solid #1010121a;
  padding: 16px 0px 0 0px;
  border-radius: 8px;
  width: 215px !important;
  height: 100%;
}
img.mt-3.car-slider-image {
  width: 130px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}
.selected-options {
  background-color: #31449b !important;
  color: #ffffff !important;
  opacity: 100% !important;
}

.d-flex.align-items-center.justify-content-between.mt-4.py-3 {
  cursor: pointer;
}
.d-flex.align-items-center.justify-content-between.py-3.border-top-gray {
  cursor: pointer;
}

// car icons CSS
.location-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.location-mui-icon-car path {
  fill: #101012 !important;
}
.location-mui-icon-car circle {
  fill: #101012 !important;
}

.confirm-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.full-of-full-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.full-of-full-mui-icon-car path {
  fill: #101012 !important;
}

.mileage-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.mileage-mui-icon-car path {
  fill: #101012 !important;
}
.cancel-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.cancel-mui-icon-car path {
  fill: #101012 !important;
}
.cancel-mui-icon-car circle {
  fill: #101012 !important;
}
.hours-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.hours-mui-icon-car path {
  fill: #101012 !important;
}
.hours-mui-icon-car circle {
  fill: #101012 !important;
}
.seat-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}
.bag-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}
.automatic-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}

.view-on-map-mui-icon-car path {
  fill: #fff !important;
}
.view-on-map-mui-icon-car circle {
  fill: #fff !important;
}
.door-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}

// car icons CSS
.location-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.location-mui-icon-car path {
  fill: #101012 !important;
}
.location-mui-icon-car circle {
  fill: #101012 !important;
}

.confirm-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.full-of-full-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.full-of-full-mui-icon-car path {
  fill: #101012 !important;
}

.mileage-mui-icon-car {
  height: 36px !important;
  width: 28px !important;
}
.mileage-mui-icon-car path {
  fill: #101012 !important;
}
.cancel-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.cancel-mui-icon-car path {
  fill: #101012 !important;
}
.cancel-mui-icon-car circle {
  fill: #101012 !important;
}
.hours-mui-icon-car {
  height: 40px !important;
  width: 33px !important;
}
.hours-mui-icon-car path {
  fill: #101012 !important;
}
.hours-mui-icon-car circle {
  fill: #101012 !important;
}
.seat-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}
.bag-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}
.automatic-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}

.view-on-map-mui-icon-car path {
  fill: #fff !important;
}
.view-on-map-mui-icon-car circle {
  fill: #fff !important;
}
.door-mui-icon-car path {
  fill: rgb(49, 68, 155) !important;
}

// CAR MAP CSS
.input-decoration-car-map {
  background-color: var(--color-white);
  border: 1px solid rgba(0, 0, 0, 0.1294117647);
  padding: 7px 15px !important;
  border-radius: 5px;
  outline: none;
}

.gm-style-iw-d {
  overflow: hidden !important;
  // max-height: auto !important;
}
.gm-style-iw.gm-style-iw-c {
  padding: 15px !important;
}

.row.hotel-view.position-relative.mt-0.car-map-main-section {
  align-items: flex-start !important;
}
.hotel-sider-filters.border-0.min-vh-100.overflow-hidden.car-map-filter-section.car-map {
  min-height: 100vh !important;
  overflow: auto !important;
}

div.view-link {
  margin-bottom: 5px !important;
}

.map-nav.car-map-nav-main-div {
  padding: 23px 8px 23px 37px;
}

.gm-ui-hover-effect {
  margin-right: 5px !important;
  margin-top: 5px !important;
}
.car-search-btn.fs-18.fw-600.color-white.w-xsm-100 {
  background-color: var(--color-blue);
  padding: 18px 40px 17px 40px;
  transition: 0.4s;
  border: 1px solid var(--color-blue);
  border-radius: 6px;
}

.d-flex.flex-column.cw-50.position-relative {
  width: 50%;
}
.date-value-car-map {
  padding-left: 5px;
}

.car-result-price-button-skelton {
  margin-left: 10px;
  display: none !important;
}

// car result filter css
.hotel-view .hotel-sider-filters.car-result-filter-bar.filter-open {
  position: fixed;
  z-index: 2;
  width: 290px;
  background-color: #ffffff;
  left: 2%;
  top: 1%;
  transition: 0.5s;
}

.close-btn.d-xl-none path {
  fill: #000;
}

.car-details-main-div .view-map-button {
  display: none;
  background: none;
  border: 1px solid #00000021;
  padding: 14px 20px;
  border-radius: 6px;
  margin-left: 10px;
}
.car-details-main-div .view-filter-button {
  display: none;
  background: none;
  border: 1px solid #00000021;
  padding: 14px 20px;
  border-radius: 6px;
  margin-left: 10px;
}

// car map filter css
.hotel-sider-filters.border-0.min-vh-100.overflow-hidden.car-map-filter-section.car-map.filer-open {
  position: fixed;
  z-index: 2;
  width: 290px;
  background-color: #ffffff;
  left: 0;
  top: 0;
  transition: 0.4s;
}
.car-map-main-section
  .d-flex.align-items-center.justify-content-between.mt-3.pt-1 {
  font-size: 35px !important;
}

@media (max-width: 1399px) {
  .car-card-slider-div {
    width: 180px !important;
  }
}

@media (max-width: 1200px) {
  .hotel-view .hotel-sider-filters.car-result-filter-bar {
    position: fixed;
    z-index: 2;
    width: 290px;
    background-color: #ffffff;
    left: -100%;
    top: 0;
    transition: 0.8s;
  }
  .car-details-main-div .view-map-button {
    display: block;
  }
  .car-details-main-div .view-filter-button {
    display: block;
  }
  .hotel-sider-filters.border-0.min-vh-100.overflow-hidden.car-map-filter-section.car-map {
    position: fixed;
    z-index: 2;
    width: 290px;
    background-color: #ffffff;
    left: -100%;
    top: 0;
    transition: 0.4s;
  }
  .close-btn.d-xl-none {
    position: absolute;
    right: 1%;
  }
  .view-map.car-result-map-view {
    display: none;
  }
  .rating-price-distence-amenities.mt-3.pb-0.car-result {
    margin-top: 0px !important;
    padding-top: 10px !important;
  }
  .car-result-price-button-skelton {
    display: block !important;
  }
}
@media (max-width: 1199px) {
  .car-card-slider-div {
    width: 210px !important;
  }
  // car rental
  .pick-up-date-picker-car-rental {
    width: 50% !important;
  }
  .pick-up-date-picker-car-rental
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 96% !important;
  }
  .pick-up-date-picker-car-rental
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    width: 98%;
  }

  .pick-up-time-picker-car-rental {
    width: 100%;
  }
  .drop-of-date-picker-car-rental {
    width: 48% !important;
  }
  .drop-of-time-picker-car-rental {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .car-card-slider-div {
    width: 200px !important;
  }
  .taxi-book-btn.fs-18.fw-600.color-white.mt-md-3.car-search-book-button {
    font-size: small;
  }
  .car-rental-search-result .custom-width {
    width: 100%;
  }
  .car-rental-search-result .car-result-book-btn {
    width: 66% !important;
    margin-left: 34px;
  }
  .position-relative.w-100.car-result-date-picker {
    width: 77% !important;
  }
  .car-search-btn.fs-18.fw-600.color-white.w-xsm-100 {
    background-color: var(--color-blue);
    padding: 12px 40px 16px 40px;
    width: 46%;
    margin-left: 38px;
  }

  .car-map-nav-main-div .car-map-date-picker {
    width: 46% !important;
  }
}

@media (max-width: 992px) {
  .car-map-filter-section span {
    font-size: 35px !important;
  }
  .car-result-map-star span {
    font-size: 35px !important;
  }
}
@media (max-width: 767px) {
  .car-card-slider-div {
    width: 220px !important;
  }
}
@media (max-width: 768px) {
  .car-rental-form-main-div
    .d-flex.flex-column.flex-md-row.align-items-center.justify-content-between.gap-4.gap-md-5.gap-lg-custom.position-relative {
    flex-direction: column !important;
  }
}

@media (max-width: 575px) {
  .hotel-view .hotel-sider-filters.car-result-filter-bar.filter-open {
    position: absolute;
    display: block;
    z-index: 2;
    width: 290px;
    background-color: #ffffff;
    left: 0;
    top: 3%;
    transition: 0.4s;
  }
}
@media (max-width: 527px) {
  .car-card-slider-div {
    width: 200px !important;
  }
}
@media (max-width: 485px) {
  .car-card-slider-div {
    width: 185px !important;
  }
}
@media (max-width: 460px) {
  .car-card-slider-div {
    width: 175px !important;
  }
}
@media (max-width: 441px) {
  .car-card-slider-div {
    width: 270px !important;
  }
}
