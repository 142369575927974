.background-overlay {
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.419);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9;
}

.overlay {
  width: 100%;
}

.flight-steps-and-details {
  .select-payment-method {
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
    background-color: $cl-white;
    padding: 35px 31px 29px 27px;

    .payment-options {
      margin-top: 34px;

      .payment-option {
        border: 1px solid #1010121a;
        width: 100%;
        border-radius: 8px;
        margin-left: 19px;
        padding: 7px 16px 10px 16px;
        cursor: pointer;
      }
    }

    .input-decoration {
      padding: 9px 13px 11px 13px;
      border: 1px solid #1010121a;
      border-radius: 8px;
      margin-top: 34px;
      width: 100%;

      ::placeholder {
        color: $cl-blue-black;
        opacity: 70%;
      }

      select {
        border: 0;
        outline: 0;
      }
    }

    .amount-none-max-btns {
      margin-left: 14px;

      p {
        margin: 0 36px;
      }

      .amount-none-btn {
        padding: 13px 30px;
        border-radius: 6px;
        background-color: $cl-orange;
        border: none;
        outline: none;
      }

      .amount-max-btn {
        padding: 13px 35px;
        border-radius: 6px;
        background-color: $cl-orange;
        border: none;
        outline: none;
      }
    }

    .form-submit-btn {
      margin-top: 57px;
      padding: 19px 10px;
    }
  }
}

.full-booking-info {
  width: 955px;
  padding: 35px 39px 38px 28px;
  background-color: $cl-white;
  box-shadow: 3px 4px 26px 0px #0000000d;
  border-radius: 8px;
  position: fixed;
  transform: translate(-50%, -50%) scale(0);
  left: 50%;
  top: 50%;
  z-index: 10;
  transition: 0.1s;

  .close-img {
    cursor: pointer;
  }
}

.booking-popup-show {
  transform: translate(-50%, -50%) scale(1);
}

.booking-info {
  box-shadow: 3px 4px 26px 0px #0000000d;
  background-color: $cl-white;
  border-radius: 8px;

  .flight-details-1 {
    padding: 22px 16px 17px 16px;
    border-bottom: 2px solid #0000000d;

    p {
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }

  .flight-depart-detail {
    padding: 24px 0 35px 0;
    margin: 0 16px;
    border-bottom: 1px solid #0000000d;

    img {
      width: 19px;
    }
  }

  .total-price {
    border-top: 2px dashed #0000000d;
    padding: 26px 16px;
  }
}

@media (max-width: 1200px) {
  .full-booking-info {
    width: 80%;
  }

  .booking-info {
    margin-bottom: 30px;

    .flight-details-1 {
      padding: 22px 16px 17px 16px;
      border-bottom: 0px solid #0000000d;
      border-radius: 8px;
    }

    .booking-details-drop-down {
      transform: scaleY(0);
      position: absolute;
      transform-origin: top;
      transition: 0.1s;
      width: 100%;
      background-color: $cl-white;
      box-shadow: 3px 19px 26px 0px #0000000d;
      border-radius: 0 0 8px 8px;
    }

    .booking-drop-down-show {
      transform: scaleY(1);
    }
  }
}

@media (max-width: 768px) {
  .flight-steps-and-details {
    .select-payment-method {
      padding: 35px 15px 29px 15px;

      .input-decoration {
        padding: 5px 13px 7px 13px;
      }
    }
  }
}

@media (max-width: 576px) {
  .full-booking-info {
    width: 95%;
    padding: 20px;
  }

  .flight-steps-and-details {
    .select-payment-method {
      .form-submit-btn {
        padding: 13px;
      }

      .payment-options {
        position: relative;

        .border-none {
          border: none !important;
        }

        .payment-options-dropdown {
          position: absolute;
          width: 100%;
          background-color: $cl-white;
          top: 42px;
          z-index: 1;
          transform: scaleY(0);
          transform-origin: top;
          box-shadow: 3px 19px 26px 0px #0000000d;
          transition: 0.1s;
        }

        .payment-optn-drop-down-show {
          transform: scaleY(1);
        }

        .payment-option {
          border: 1px solid #1010121a;
          width: 100%;
          border-radius: 8px;
          margin-left: 0;
          padding: 7px 16px 10px 16px;
          border-top: 1px solid #0000000d;

          p {
            margin-left: 10px;
          }
        }
      }

      .amount-none-max-btns {
        margin-left: 14px;

        p {
          font-size: 14px;
          margin: 0 36px;
        }

        .amount-none-btn {
          padding: 9px 26px;
        }

        .amount-max-btn {
          padding: 9px 30px;
        }
      }
    }
  }
}
