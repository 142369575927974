/* Define colors */
$black: #000000;
$white: #ffffff;
$blue: #31449b;
$light-blue: #4c69d3;
$light-blue-hover: #1449b3;
$light-grey: #f9f9f9;

/* Main container of the chatbot */
.chat-container {
  position: fixed;
  width: 440px;
  bottom: 4em;
  top: 1em;
  right: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 999;
}

/* Header of the chatbot */
.chat-header {
  background-color: $blue;
  color: $white;
  height: 70px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 28px;
}

/* Logo inside header */
.chat-header-intro {
  display: flex;
  align-items: center;
}

.explr-logo {
  width: 60px;
  height: 60px;
  padding-left: 20px;
}

.text-box {
  padding: 5px 20px;
  border: 1px solid rgba(70, 37, 37, 0.1);
  border-radius: 4px;
  margin-left: 10px;
}

/* Container for services */
.service-container,
.subservice-container {
  width: 320px;
  display: grid;
  grid-gap: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 20px 10px 10px;

  /* Service grid layout */
  .service-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  /* Service box inside grid */
  .service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.subservice-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  .subservice-button {
    color: white;
    background-color: #4f92e9;
    display: flex;
    height: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: small;
    min-width: 8rem;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}

/* Main area for displaying messages */
.chat-message {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;
  padding-left: 60px;
  &::-webkit-scrollbar {
    display: none;
  }
}

/* Style for messages */
.user-message,
.bot-message {
  border-radius: 10px;
  padding: 12px;
  margin: 12px;
  max-width: 80%;
}

.user-message {
  background-color: hsl(0, 0%, 88%);
  align-self: flex-end;
}

.bot-message {
  background-color: $blue;
  color: $white;
  align-self: flex-start;
}

/* Input area at the bottom */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 4px;

  .text-box {
    display: inline-block !important ;
    margin-right: 20px;
  }

  button {
    background-color: $light-blue;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    color: $white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $light-blue-hover;
    }

    svg {
      fill: currentColor !important;
    }
  }

  input[type='text'] {
    width: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
    color: $black;
  }

  .file-input-label {
    border: 1.24px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transform: rotate(45deg);
    color: $black;

    svg {
      fill: currentColor !important;
    }
  }
}
.message-input {
  flex: 1;
  padding-right: 10px;
}
/* Button to toggle the chat visibility */
.chat-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: $white;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  box-shadow: 2px 3px 14px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    animation: pulse 1s infinite;
  }
}

/* Responsiveness adjustments */
@media screen and (max-width: 768px) {
  .chat-container {
    width: 100%;
    display: flex;
    bottom: 4em;
    height: auto;
    left: 0;
  }

  .chat-header {
    border-radius: 0;
  }

  .chat-message {
    max-height: 80vh;
  }

  .service-box p {
    font-size: 12px;
  }

  .chat-icon {
    display: none;
  }
  .message-input-container {
    min-width: 90%;
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  span {
    width: 10px;
    height: 10px;
    background-color: $blue;
    border-radius: 50%;
    margin: 0 5px;
  }
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  padding-right: 2em;
}
