.back-btn {
  width: 35px;
  height: 35px;
  transform: rotate(180deg);
}

.flight-time-price {
  .tab-heading {
    background-color: $cl-white;
    z-index: 0;

    .tab-heading-bg {
      background-color: $cl-blue;
      border-radius: 28px 28px 0px 0px;
      padding: 27px 34px 24px 31px;

      .flight-all-details {
        padding: 8.5px 10px;
        background-color: $cl-white;
        border-radius: 5px;

        .depart-btn {
          background-color: $cl-blue;
          padding: 6px 10px;
          border-radius: 4px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .flight-time-price {
    .tab-heading {
      .tab-heading-bg {
        border-radius: 12px 12px 0px 0px;
        padding: 15px 27px;

        .flight-all-details {
          .depart-btn {
            background-color: $cl-white !important;
            padding: 0px !important;
            color: $cl-blue-black;
          }

          .flight-details-dropdown {
            position: absolute;
            background-color: $cl-white;
            padding: 14px 10px;
            width: 100%;
            border-radius: 5px;
            left: 0;
            top: 42px;
            z-index: 1;
            border: 1px solid rgba(0, 0, 0, 0.056);
            transition: 0.4s;
            display: none;
          }
        }
      }
    }
  }
}
