.flightChangePayment {
  width: 100%;
  .payment-options {
    margin-top: 34px;

    .payment-option {
      border: 1px solid #1010121a;
      width: 100%;
      border-radius: 8px;
      margin-left: 19px;
      padding: 7px 16px 10px 16px;
      cursor: pointer;
    }
  }
}
