/*============ font-sizes ============*/
$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-17: 17px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-23: 23px;
$fs-24: 24px;
$fs-26: 26px;
$fs-29: 29px;
$fs-34: 34px;
$fs-38: 38px;
$fs-44: 44px;
$fs-58: 58px;

/*============ font-weight ============*/
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;

/*============ font-family ============*/
// $ff-inter: 'Inter', sans-serif;
$ff-satisfy: 'Satisfy', cursive;

/*============== colors ==============*/
$cl-blue-black: #101012;
$cl-blue: #31449b;
$cl-white: #ffffff;
$cl-light-white: #f5f6f6;
$cl-black: #000000;
$cl-light-black: #14161b;
$cl-orange: #fda400;
$cl-light-orange: #edad352e;
$cl-gray: rgba(16, 16, 18, 0.27);
$bg-light-white: #fbfbfd;
$txt-gray: #9fa0a0;
