.map-nav {
  padding: 23px 30px 23px 37px;
}
.map-search-btn {
  background-color: $cl-blue;
  border-radius: 6px;
  border: none;
  outline: none;
  padding: 16px 50px;
}
.map-date-picker {
  display: inline-flex;
  .date-format {
    margin-left: 5px;
  }
}
.hotel-view {
  .map-filter-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1;
  }
  .filter-show-btn {
    background-color: $cl-white;
    padding: 5px 20px;
    border-radius: 5px;
    position: absolute;
    left: 20px;
    top: 50px;
  }
  .hotel-preview {
    display: flex;
    // position: absolute;
    flex-direction: column;
    left: 10%;
    top: 20%;
    z-index: 1;
  }
}
.hotel-map-card {
  background-color: $cl-white;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  .hotel-rating {
    padding: 2px 10px;
    background-color: $cl-blue;
    border-radius: 20px;
  }
  .sale-persent {
    background-color: #ec1414;
    padding: 3px 5px;
  }
}
.map-hotel-card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map-landmark-bg {
  width: 120px;
  background-color: #31449b;
  padding: 10.5px 10px;
  border-radius: 3px;
  position: relative;
  /* margin-left: 50px; */
  text-align: center;
  .bottom-arrow {
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }
}
