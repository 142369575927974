.empty-cart {
  min-height: calc(80vh - 143px);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 44px;
  }
}

@media (max-width: 768px) {
  .empty-cart {
    min-height: calc(80vh - 95px);

    img {
      width: 70px;
      margin-bottom: 25px;
    }
  }
}
