.main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
  position: relative;
  width: 290px;
  margin: 0 auto;
  padding: 16px 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 4px 12px 0 rgba(0, 0, 0, 0.08);
}

.explrar-bucks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  padding: 0 12px;
}

.logo {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  width: 209px;
  z-index: 1;
}

.logo-1 {
  flex-shrink: 0;
  position: relative;
  width: 33px;
  height: 27.25px;
  background-size: cover;
  z-index: 2;
}

.texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 164px;
  z-index: 3;
}

.explrar-bucks-2 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 21px;
  color: #000000;
  font-family: Poppins, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.3px;
  z-index: 4;
}

.text-and-icon {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 164px;
  z-index: 5;
}

.use-explrar-bucks {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 15px;
  color: #8a8a8a;
  font-family: Poppins, var(--default-font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.3px;
  z-index: 6;
}

.pencil-alt {
  flex-shrink: 0;
  position: relative;
  width: 10px;
  height: 10px;
  z-index: 7;
  overflow: hidden;
}

.icons {
  position: relative;
  width: 7.781px;
  height: 7.783px;
  margin: 0.9px 0 0 1.313px;
  background-size: 100% 100%;
  z-index: 8;
}

.tag-and-button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: end;
  align-items: center;
}

.tag-and-toggle {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 80px;
  z-index: 9;
}

.button-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 87px;
  height: 17px;
  padding: 1px 4px;
  cursor: pointer;
  background: #fda400;
  border: none;
  z-index: 10;
  border-radius: 3px;
}

.text-save {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 15px;
  color: #ffffff;
  font-family: Poppins, var(--default-font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  z-index: 11;
}

.switch-svgrepo {
  flex-shrink: 0;
  position: relative;
  width: 27px;
  height: 27px;
  z-index: 12;
  overflow: hidden;
}

.vector {
  position: relative;
  width: 27px;
  height: 16.2px;
  margin: 5px 0 0 0;
  // background: url(./assets/images/714f4f46-0261-478b-9ec2-2784b0ad71c9.png) no-repeat center;
  background-size: cover;
  z-index: 13;
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  width: 262px;
  padding: 0 0 0 21px;
  z-index: 14;
}

.primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 33px;
  height: 32px;
  padding: 10px 12px;
  background: #fda400;
  border: 1px solid #fda400;
  z-index: 15;
  border-radius: 6px;
}

.text-3 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 21px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 20.574px;
  text-align: left;
  white-space: nowrap;
  z-index: 16;
}

.primary-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 80px;
  height: 32px;
  padding: 10px 12px;
  cursor: text;
  background: transparent;
  border: 1px solid #8e93aa;
  z-index: 17;
  border-radius: 6px;
  font-size: 15px;
}

.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 3px;
  position: relative;
  width: 60px;
  height: 32px;
  padding: 10px 12px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #8e93aa;
  z-index: 17;
  border-radius: 5px;
  font-size: 12px;
}

.primary-6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 37px;
  height: 32px;
  padding: 10px 12px;
  background: #fda400;
  border: 1px solid #fda400;
  z-index: 19;
  border-radius: 6px;
}

.text-7 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 21px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 20.574px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}

.primary-8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 70px;
  height: 32px;
  padding: 10px 12px;
  cursor: pointer;
  background: #31449b;
  border: 1px solid #31449b;
  z-index: 21;
  border-radius: 6px;
}

.text-9 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 15px;
  color: #ffffff;
  font-family: Poppins, var(--default-font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.switch-svgrepo-com {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 21px;
  z-index: 8;
  overflow: hidden;

  .checkbox-wrapper-3 {
    input[type='checkbox'] {
      visibility: hidden;
      display: none;
    }

    .toggle {
      position: relative;
      display: block;
      width: 40px;
      height: 20px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);

      &:before {
        content: '';
        position: relative;
        top: 3px;
        left: 3px;
        width: 34px;
        height: 14px;
        display: block;
        background: #9a9999;
        border-radius: 8px;
        transition: background 0.2s ease;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        display: block;
        background: #31449b;
        border-radius: 10px;
        box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
        transition: all 0.2s ease;

        &:before {
          content: '';
          position: absolute;
          display: block;
          margin: -18px;
          width: 56px;
          height: 56px;
          background: rgba(79, 46, 220, 0.5);
          border-radius: 50%;
          transform: scale(0);
          opacity: 1;
          pointer-events: none;
        }
      }
    }

    #cbx-3:checked + .toggle {
      &:before {
        background: #31449b;
      }

      span {
        background: #fda400;
        transform: translateX(20px);
        transition:
          all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25),
          background 0.15s ease;
        box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);

        &:before {
          transform: scale(1);
          opacity: 0;
          transition: all 0.4s ease;
        }
      }
    }
  }
}
