.hotel-room-book-form {
  .width-310 {
    min-width: 310px;
  }
}
.css-1t4vnk2-MuiDialogContent-root {
  border-bottom: 0 !important;
}
.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  display: flex;
}
._3q7r8 {
  height: 100% !important;
}
.hotel-details {
  padding: 26px 26px 10px 17px;
  background-color: $cl-white;
  border-radius: 10px;
  box-shadow: 3px 4px 26px 0px #0000000d;
  position: relative;
  .hotel-detals-tabs {
    button {
      border: none;
      outline: none;
      background-color: transparent;
      border-bottom: 2px solid #10101217;
      padding: 10px 10px 18px 10px;
      width: 100%;
      &.active {
        border-bottom: 2px solid $cl-orange;
        svg {
          g {
            opacity: 100;
          }
          path {
            fill: $cl-orange;
          }
          .stroke {
            fill: none;
            stroke: $cl-orange;
          }
        }
        p {
          opacity: 100 !important;
        }
      }
    }
  }
  .hotel-details-tab-content {
    .border-rds-8 {
      border-radius: 8px;
    }
    .hotel-all-img-btn {
      background-color: #0000006b;
      padding: 10px 27px;
      border-radius: 8px;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
    .map-full-view-btn {
      position: absolute;
      right: 13px;
      bottom: 9px;
      background-color: $cl-white;
      padding: 9px;
      border-radius: 5px;
    }
    .select-room-btn {
      padding: 16px 20px;
      border-radius: 6px;
      background-color: $cl-blue;
      cursor: pointer;
      &:hover {
        background-color: $cl-orange !important;
        color: $cl-white !important;
      }
    }
    .free-tools-btn {
      padding: 9px 16px;
      background-color: #fda4001f;
      border-radius: 8px;
    }
    .view-rating-amenities {
      padding: 19px 19px 12px 19px;
      background-color: #fbfbfd;
      border-radius: 8px;
      .facilitys {
        margin-top: 22px;
        .cleanliness {
          width: 65%;
          border-radius: 14px;
          height: 7px;
          background-color: #1010121c;
          display: inline-block;
          margin-top: 3px;
        }
        .amenities {
          width: 65%;
          border-radius: 14px;
          height: 7px;
          background-color: #1010121c;
          display: inline-block;
          margin-top: 3px;
        }
        .location {
          width: 65%;
          border-radius: 14px;
          height: 7px;
          background-color: #1010121c;
          display: inline-block;
          margin-top: 3px;
        }
        .service {
          width: 65%;
          border-radius: 14px;
          height: 7px;
          background-color: #1010121c;
          display: inline-block;
          margin-top: 3px;
        }
      }
      .amenities-facilitys {
        .amenities-facility {
          padding: 8px 0 8px 3px;
        }
      }
    }
  }
}
.close-btn {
  cursor: pointer;
}
.select-room-btn-amenties {
  padding: 16px 10px;
  background-color: $cl-blue !important;
  border-radius: 8px;
  width: 100%;
  margin-top: 35px;
  cursor: pointer;
  &:hover {
    background-color: $cl-orange !important;
    color: $cl-white !important;
  }
}
.album-back-btn {
  color: #31449b;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.ablum-title {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 26px;
  padding-bottom: 8px;
  user-select: none;
}
.album-select-room-btn {
  background-color: #31449b;
  padding: 16px 20px;
  border-radius: 6px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.ablum-photos-img {
  width: 400px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(240, 242, 245);
}
.photos-category-tag {
  background: #f5f7fa;
  user-select: none;
  border-radius: 4px 4px 4px 4px;
  color: #0f294d;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
  margin-left: 13px;
  margin-bottom: 16px;
  padding: 8px;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  width: fit-content;
  max-width: 320px;
  align-items: center;
}
.tag-active {
  background: #31449b !important;
  color: #ffffff !important;
}
.view-all-rating {
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 50%;
  background-color: $cl-white;
  width: 1300px;
  padding: 0 0 20px 20px;
  border-radius: 8px;
  height: 733px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 3px 4px 26px 0px #0000000d;
  display: none;

  .guest-ratings {
    height: 734px;
    background-color: $cl-white;
  }
}
.room {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $cl-blue;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #10101229;
  }
  .rooms {
    background-color: #fbfbfd;
    .hotel-Img {
      width: 277px;
    }
    .hotel-mini-img {
      height: 90px;
      padding: 0 3px 0 0;
    }
    .hotel-mini-img-2 {
      padding: 0 0 0 3px;
    }
    .bdr-rds-8 {
      border-radius: 8px !important;
    }
    .hotel-image {
      padding-bottom: 6px;
    }
    .hotel-details-heading {
      padding: 14px 26px;
      border-radius: 8px 8px 0 0;
      background-color: $cl-blue;
    }
    .choice-sleep-price {
      padding: 16px 27px 5px 16px;
      background-color: $cl-white;
      border-radius: 0 0 8px 8px;
      .reserve-btn {
        padding: 16px 20px;
        background-color: $cl-blue;
        border-radius: 6px;
        margin-left: 21px;
        cursor: pointer;
        &:hover {
          background-color: $cl-orange !important;
          color: $cl-white !important;
        }
      }
      .choices {
        border-right: 1px solid #10101214;
      }
      .price {
        border-left: 1px solid #10101214;
      }
    }
    .border-bottom-ligth-gray {
      border-bottom: 0.48px solid #10101212;
      padding-bottom: 37px;
    }
    .border-left-gray {
      border-left: 1px solid #4b4a4a;
    }
    .room-amenities {
      position: fixed;
      z-index: 2;
      left: 50%;
      top: 50%;
      padding: 36px 27px 44px 24px;
      background-color: rgb(255, 255, 255);
      width: 1300px;
      border-radius: 8px;
      height: 733px;
      overflow: hidden;
      transform: translate(-50%, -50%);
      box-shadow: 3px 4px 26px 0px #0000000d;
      display: none;
      .room-amenities-content {
        height: 634px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          background-color: transparent;
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $cl-blue;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #10101229;
        }
        .slider-arrow {
          border: 1px solid $cl-orange;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-color: $cl-white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color: $cl-orange;
            svg {
              path {
                stroke: $cl-white;
              }
            }
          }
          &.hotel-slider-left-arrow {
            position: absolute;
            top: 50%;
            z-index: 1;
            left: -22px;
          }
          &.hotel-slider-right-arrow {
            position: absolute;
            top: 50%;
            z-index: 1;
            right: -22px;
          }
        }
        .hotel-mini-slider {
          overflow-x: scroll;
          &::-webkit-scrollbar {
            background-color: transparent;
            width: 4px;
            height: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $cl-blue;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            background-color: #10101229;
          }
        }
      }
    }
  }
  svg {
    width: 14px;
  }
}

.guest-ratings {
  max-height: 1000px;
  overflow-y: scroll;
  padding-right: 20px;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $cl-blue;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #10101229;
  }
  .cleanliness {
    width: 100%;
    background-color: #1010121c;
    border-radius: 14px;
    height: 7px;
    margin-left: 22px;
  }
  .amenities {
    width: 100%;
    background-color: #1010121c;
    border-radius: 14px;
    height: 7px;
    margin-left: 34px;
  }
  .location {
    width: 100%;
    background-color: #1010121c;
    border-radius: 14px;
    height: 7px;
    margin-left: 22px;
  }
  .service {
    width: 100%;
    background-color: #1010121c;
    border-radius: 14px;
    height: 7px;
    margin-left: 30px;
  }
  .input-filters {
    width: 218px;
  }
  .input-decoration {
    padding: 13px 16px;
  }
  .user-rating {
    padding: 27px 36px 23px 24px;
    background-color: #fbfbfd;
    border-radius: 8px;
    margin-top: 38px;
    .profile-dtl {
      border-right: 0.48px solid #10101212;
    }
    .review {
      padding-left: 53px;
    }
  }
}

.policies {
  padding: 20px 30px 20px 30px;
  background-color: #fbfbfd;
  margin-top: 31px;
  border-radius: 8px;
  max-height: 1000px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $cl-blue;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #10101229;
  }
  .check-in-out-dtls {
    padding: 26px 21px;
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
  }
  .age-fee-dtls {
    background-color: #fbfbfd;
    border-radius: 8px;
    overflow: hidden;
    width: 85%;
    margin-top: 30px;
    .age-fee {
      background-color: $cl-blue;
      padding: 13px 14px;
    }
    .child-2-year-row {
      border-bottom: 1px solid #10101214;
      .col-5 {
        padding: 27px 14px;
        border-right: 1px solid #10101214;
      }
      .col-7 {
        padding: 27px 14px;
      }
    }
    .child-2-year-above-row {
      .col-5 {
        padding: 27px 14px;
        border-right: 1px solid #10101214;
      }
      .col-7 {
        padding: 27px 14px;
      }
    }
  }
  .breakfast {
    padding-bottom: 24px;
    border-bottom: 1px solid #10101214;
  }
  .fee {
    padding-top: 24px;
  }
}

.hotel-card-slider {
  border: 1px solid #0000000a;
  border-radius: 14px;
  overflow: hidden;
  background-color: $cl-white;
  position: relative;
  margin-left: 11px;
  margin-right: 11px;
  .place-rating {
    background-image: url('../../../../public/Assets/Img/svg/rating-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 7px 6px 7px 14px;
    position: absolute;
    right: -1px;
    top: 20px;
  }
  .place-name {
    padding: 20px 12px 0 12px;
  }
  .today-offer {
    padding: 0 12px 16px 12px;
    border-bottom: 1px solid #00000014;
    .sale-percent-off {
      padding: 3px 8px;
      border-radius: 2px;
      background-color: $cl-blue;
    }
  }
  .guest-rating {
    padding: 14px 12px;
    border-bottom: 1px solid #00000014;
  }
  .distance {
    padding: 14px 12px;
    border-bottom: 1px solid #00000014;
  }
  .location {
    padding: 14px 12px;
    border-bottom: 1px solid #00000014;
  }

  .view-property-btn {
    padding: 14px 10px;
    background-color: $cl-blue;
    &:hover {
      color: $cl-white !important;
      background-color: $cl-orange;
    }
  }
}

@media (max-width: 992px) {
  .hotel-details {
    .h-150 {
      height: 150px;
    }
    .hotel-details-tab-content {
      .hotel-all-img-btn {
        padding: 4px 6px;
        img {
          width: 30px;
        }
        p {
          font-size: 13px !important;
        }
      }
    }
    .hotel-detals-tabs {
      button {
        padding: 10px 0px 18px 0px;
        svg {
          width: 35px;
        }
      }
    }
  }
  .slider-heading {
    h2 {
      font-size: 28px;
    }
  }
}

@media (max-width: 768px) {
  .hotel-details {
    .hotel-details-tab-content {
      .hotel-all-img-btn {
        right: 1px;
      }
      .free-tools-btn {
        padding: 6px 10px;
      }
      .select-room-btn {
        padding: 9px;
      }
    }
    .hotel-card-slider {
      .today-offer {
        .sale-percent-off {
          padding: 3px 3px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .hotel-details {
    .hotel-details-tab-content {
      .hotel-all-img-btn {
        padding: 4px 6px;
        img {
          width: 30px;
        }
      }
    }
  }
  .slider-heading {
    h2 {
      font-size: 22px;
    }
  }
  .hotel-card-slider {
    .view-property-btn {
      padding: 9px 10px;
      background-color: $cl-blue;
    }
  }
}

.slick-track {
  margin-left: 0px;
}
