.currency-popup-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  min-width: 1100px;

  p {
    color: #101012;
    font-weight: 600;
    font-size: 32px;
  }
}

.currency-title-container {
  background: #fda400;
  color: #101012;
  padding-left: 20px;
  margin-top: 100px;
  p {
    font-family: poppins;
    font-weight: 500;
    font-size: 16px;
  }
}

.popular-currency-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  place-content: center;
  margin: 20px 0;
  place-content: center;
}

.all-currency-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 80px;
  place-content: center;
  margin-top: 20px;
  place-content: center;
}

.each-currency {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 10px;

  &:hover {
    background: #31449b4d;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    font-family: poppins;
  }
}

.active-currency-code {
  background: #31449b4d;
}
