.change-flights-confirm {
  margin-top: -67px;
  .flight-booking-conf {
    border: 2px solid #0000001a;
    padding: 26px 22px;
    border-radius: 8px;
    margin-top: 38px;
  }

  .div-11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
  }

  .text-wrapper-13 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: 'Inter', Helvetica;
    font-weight: 700;
    color: var(--blue-black);
    font-size: 18px;
    letter-spacing: 0;
    line-height: normal;
  }

  .vector-3 {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 2px;
    margin-top: -1px;
  }

  .div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div-12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div-13 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex: 0 0 auto;
  }

  .text-wrapper-14 {
    position: relative;
    width: fit-content;
    margin-top: -1.33px;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 17.3px;
    letter-spacing: 0;
    line-height: normal;
  }

  .text-wrapper-15 {
    position: relative;
    width: fit-content;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
  }
}
