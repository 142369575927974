:root {
  --terms-main-color: #101012;
}

.terms-services-main {
  width: 40%;
  margin: 96px auto;
}

.terms-update-container {
  text-align: center;
  color: #fda400;
  font-weight: 600;
}

.terms-of-services {
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.96px;
  margin-top: 12px;
  margin-bottom: 96px;
}

.terms-services-content-container {
  padding: 0 24px;
}
.similar-margin-div2 {
  margin-top: 32px;
}

.similar-design-h3 {
  color: var(--terms-main-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 133.33%;
  margin-bottom: 16px;
}
