@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* -------Ar bg section start */

.ar-hero-section {
  background-image: url('../../../../public/Assets/Img/ar-hero-background-image.png');
  padding: 140px 0 100px 0;
}

.ar-hero-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.coming-soon {
  display: 'flex';
  align-items: 'center';
  width: 16rem;
  border-radius: 20px;
  gap: 10px;
  padding: 10px 20px;
  background-color: #31449b0d;

  span {
    font-weight: 600;
  }
}

.blue-star {
  width: 15px;
}

@media (max-width: 1280px) {
  .ar-hero-container {
    width: 80%;
    flex-direction: column;
  }

  .ar-hero-data-info {
    text-align: center;
  }

  .augment-text {
    margin: auto;
    max-width: 300px;
  }

  .app-store-google-play {
    margin: auto;
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .coming-soon {
    margin: auto;
    position: relative;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .coming-soon {
    position: absolute;
    top: -50px;
    display: flex;
  }
}

.ar-hero-container .ar-hero-data-info .ar-hero-title {
  font-size: 58px;
  line-height: 127.023%;
}
.text-color {
  color: #fda400;
}
.ar-hero-container .ar-hero-data-info .augment-text {
  width: 600px;
  padding: 20px 0 20px 10px;
  display: flex;
  border-left: 5px solid #fda400;
  background-color: rgba(253, 164, 0, 0.1);
  color: #101012;
  margin-top: 58px;
  margin-bottom: 80px;
}
.ar-hero-container .ar-hero-data-info .app-store-google-play {
  display: flex;
  gap: 10px;
  width: 180px;
  height: 60px;
}
.ar-hero-img {
  width: 100%;
  height: auto;
}

.ar-ecplrar-main {
  width: 70%;
  margin: 0 auto;
}
/* -------Ar bg section End ----------*/

/* ------------ whate is ar explrar  section start ----------  */
.ar-explrar-container {
  margin-top: 222px;
  display: flex;
  gap: 38px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .ar-explrar-container {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .ar-explrar-container-info {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .ar-explrar-container-info {
    width: 50%;
  }
}

.ar-explrar-container .frame-img {
  width: 100%;
  height: auto;
}
.ar-explrar-container .ar-explrar-data-info {
  color: #101012;
  font-size: 44px;
  font-weight: 700;
}

.ar-explror-data-sub-info {
  font-size: 18px;
  line-height: 31.774px;
}
/* ------------ whate is ar explrar  section End ----------  */

/* ------------ Why Use AR Explrar? section Start ----------  */

.satisfy-regular {
  font-family: 'Satisfy', cursive;
  font-weight: 400;
  font-style: normal;
}

.why-uses-all-data-container {
  position: relative;
  background-color: #fbfbfd;
  margin-top: 155px;
  padding: 45px 0 68px 0;
}

.why-uses-data-container {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}
.why-uses-all-data-container .why-uses-title-info {
  font-size: 26px;
}
.why-uses-all-data-container .why-uses-header-title {
  font-size: 44px;
  font-weight: 700;
  color: #101012;
  margin: 16px 0 70px 0;
}

.hear-what-header-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 95px 0 64px 0;
}
.hear-what-header-data .hear-what-info-data {
  font-size: 26px;
}
.hear-what-header-data .hear-what-header-data-title {
  font-size: 44px;
  font-weight: 700;
  color: #101012;
}

.why-uses-container {
  display: grid;
  gap: 88px;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 640px) {
  .why-uses-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 640px) and (max-width: 1280px) {
  .why-uses-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.why-uses-container .why-uses-title {
  color: #101012;
  font-size: 20px;
  font-weight: 700;
  margin: 18px;
}
.why-uses-container .why-uses-title-info {
  font-size: 16px;
  line-height: 27.844px;
  opacity: 0.7;
  margin-top: 13px;
}

.why-uses-title-info {
  font-size: 26px;
}

.why-uses-ellipse-icon {
  position: absolute;
  top: 25px;
  right: 30%;
}
/* ------------ Why Use AR Explrar? section End ----------  */
.ar-exploar-work-header {
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  margin: 229px 0 222px 0;
}

@media screen and (max-width: 768px) {
  .ar-exploar-work-header {
    margin: 100px 0 50px 0;
  }

  .ar-exploar-work-header {
    font-size: 30px;
  }
}

.ar-exploar-work-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 153px;
}
.ar-exploar-work-sub-container {
  display: flex;
  gap: 168px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1280px) {
  .ar-exploar-work-container {
    gap: 50px;
  }

  .ar-exploar-work-sub-container {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

.ar-exploar-work-title-container {
  display: flex;
  flex-direction: column;
  gap: 19px;
}
.ar-exploar-work-sub-container .ar-exploar-work-header-title {
  font-size: 30px;
  font-weight: 700;
  color: #101012;
}
.ar-exploar-work-sub-container .ar-exploar-work-sub-title {
  font-size: 18px;
  line-height: 31.774px;
  opacity: 0.7;
  color: #94948f;
}

/* Ellose icons  */
.ar-exploar-work-ellose-big-icon1 {
  position: absolute;
  top: 57%;
  left: 60%;
  z-index: 0;
}
.ar-exploar-work-ellose-big-icon2 {
  position: absolute;
  top: 80%;
  left: -8%;
}

.ar-exploar-work-ellose-small-icon4 {
  position: absolute;
  top: 97%;
  left: -2%;
}
.ar-exploar-work-ellose-small-icon3 {
  position: absolute;
  top: 77%;
  right: 32%;
}
.ar-exploar-work-ellose-small-icon2 {
  position: absolute;
  top: 44%;
  left: -8%;
}
.ar-exploar-work-ellose-small-icon1 {
  position: absolute;
  top: 20%;
  left: 30%;
}

/* ------------ Hear What Others Say section start */
.hear-what-all-container {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 87px;
}
.hear-what-data-container {
  text-align: center;
}
.hear-what-data-container .hear-what-title {
  font-size: 18px;
  line-height: 27.544px;
  margin-top: 23px;
}
.hear-what-data-container .hear-what-sec1 {
  margin-bottom: 78px;
}
.hear-what-data-container .hear-what-name {
  font-size: 20px;
  color: #101012;
  font-weight: 700;
}
.hear-what-data-container .hear-what-travel-title {
  margin-top: 6px;
  opacity: 0.7;
}
.hear-what-data-container .hear-what-profile_image {
  width: 75.382px;
  height: 75.382px;
  margin-top: 17px;
}
/* ------------ Hear What Others Say section End */

/* Download AR Explrar Now section start */

.future-of-travel-container {
  position: relative;
  display: flex;
  width: 70%;
  margin: 200px auto;
  gap: 20%;
  height: 336px;
  align-items: center;
  justify-content: left;
  box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.09);
}

@media (max-width: 1280px) {
  .future-of-travel-container {
    display: none;
  }
}

.future-of-travel-title-container {
  margin: 63px 0 63px 39px;
}

.future-of-travel-app-google-img {
  display: flex;
  gap: 10px;
  margin-top: 37px;
}

.future-of-travel-container .future-of-travel-title {
  font-size: 38.575px;
  font-weight: 700;
  line-height: 55.943px;
}

.future-of-travel-img {
  z-index: 100;
}
.future-of-travel-ellipse_icon1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.future-of-travel-ellipse_icon2 {
  position: absolute;
  bottom: 0;
  right: 8%;
  z-index: 10;
}

/* Download AR Explrar Now section End */
/* FAQ CSS */
.faq-container {
  width: 80%;
  margin: 50px auto;
  max-width: 35rem;
}

@media screen and (max-width: 1280px) {
  .faq-main {
    margin-top: 100px;
  }
}

/* FAQ CSS END */

/* Ar Explrar work */
.ar-explrar-work-container {
  display: flex;
  gap: 163px;
}

@media (max-width: 1280px) {
  .ar-explrar-work-container {
    gap: 0px;
  }
}

.ar-working-subcontainer {
  display: flex;
}

@media screen and (max-width: 768px) {
  .scroll-bar-ar-explrar-work {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .scroll-bar-ar-explrar-work {
    display: relative;
  }
}

.ar-explrar-work-container {
  margin: 0 auto;
  width: 75%;
  display: flex;
  /* gap: 163px; */
  justify-content: space-around;
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}
