* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-page-details-container {
  width: 60%;
  margin: 0 auto;
}
.blog-page-details-header-data-container {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-page-details-name-and-data-text {
  color: #fda400;
  font-weight: 800;
  line-height: 24px;
}

.blog-page-details-title-text {
  color: #101012;
  font-size: 48px;
  font-weight: 600;
  margin-top: 12px;
}
.blog-page-details-subtitle-text {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  line-height: 30px;
  color: #101012;
  font-size: 20px;
  margin-top: 24px;
}

.blog-page-details-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 64px;
}
.blog-page-details-btn {
  color: #31449b;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 16px;
  border: 1px solid #d5d9eb;
  background: #fafafc;
}
/* _------------------------ */
.blog-page-details-data-container {
  display: flex;
  width: 33.5%;
  margin: 0 auto;
  margin-top: 96px;
}

.blog-page-details-data-title1-text {
  font-size: 30px;
  color: #101012;
  font-weight: 600;
  margin-bottom: 20px;
}

.blog-page-details-data-title2-text {
  font-size: 24px;
  color: #101012;
  font-weight: 600;
  margin: 32px 0 16px 0;
}
.blog-page-details-subTitle-data {
  font-size: 18px;
  color: #101012;
  line-height: 28px;
}
/* _------------------------ */

.blog-page-details-card-all-data-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 60%;
  margin: 0 auto;
  margin-top: 96px;
}

.blog-page-details-card-data-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.blog-page-details-card-name-and-data-text {
  color: #fda400;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}

.blog-page-details-card-title-text {
  color: #101012;
  font-size: 24px;
  font-weight: 600;
  margin: 8px 0;
}

.blog-page-details-card-subTitle-text {
  color: #101012;
  line-height: 24px;
}

.blog-page-details-card-view-all-post {
  background-color: #31449b;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 20px;
  border-radius: 6px;
  border: 1px solid #31449b;
}
