.frame {
  padding: 1rem;
  width: 1261px;
  margin: auto;
  display: flex;

  .change-flight-left {
    flex: 1;
  }

  .change-flight-right {
    .total-summary {
      border-radius: 0.25rem;
      padding: 1.5rem;
      background-color: #ffffff;
      min-width: 300px;
      border-radius: 9px;
      box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
      .section-title {
        font-size: 1.125rem;
        font-weight: 500;
        color: $blue-black;
        margin-bottom: 1rem;
      }
      .rp-blue {
        color: #31449b;
        font-size: 14px;
        font-weight: 600;
      }
      .dashed-line {
        border: none;
        border-top: 1px dotted black;
        color: #fff;
        background-color: #fff;
        height: 1px;
        width: 100%;
      }

      .total-amount {
        display: flex;
        justify-content: space-between;
        font-size: 1.125rem;
        font-weight: 500;
        color: $blue-black;
      }
    }
  }

  .text-wrapper {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: 'Inter', Helvetica;
    font-weight: 700;
    color: var(--blue-black);
    font-size: 24.1px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div-2 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    position: relative;
    flex: 0 0 auto;
    box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .div-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 20px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
  }

  .text-wrapper-2 {
    position: relative;
    width: 890px;
    margin-top: -1px;
    font-family: 'Inter', Helvetica;
    font-weight: 700;
    color: var(--blue-black);
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
  }

  .vector {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 2px;
  }

  .div-4 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    flex: 0 0 auto;
  }

  .div-5 {
    display: flex;
    flex-direction: column;
    width: 306.47px;
    align-items: flex-start;
    gap: 13px;
    position: relative;
    flex: 0 0 auto;
  }

  .text-wrapper-3 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div-6 {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div-wrapper {
    display: flex;
    width: 63.62px;
    height: 29.54px;
    align-items: flex-start;
    gap: 10px;
    padding: 6px 10px;
    position: relative;
    background-color: var(--blue);
    border-radius: 4px;
  }

  .text-wrapper-4 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: 'Poppins', Helvetica;
    font-weight: 600;
    color: var(--white);
    font-size: 12px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div-7 {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    position: relative;
    flex: 0 0 auto;
  }

  .group {
    position: relative;
    width: 23.86px;
    height: 23.34px;
    margin-bottom: -0.5px;
  }

  .text-wrapper-5 {
    position: relative;
    width: fit-content;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 13px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div-8 {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .image {
    position: relative;
    width: 108px;
    height: 40px;
    object-fit: cover;
  }

  .div-9 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    flex: 0 0 auto;
  }

  .text-wrapper-6 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: 'Poppins', Helvetica;
    font-weight: 700;
    color: var(--blue);
    font-size: 24px;
    letter-spacing: 0;
    line-height: normal;
  }

  .text-wrapper-7 {
    font-weight: 300;
    font-size: 10px;
    position: relative;
    width: fit-content;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    color: var(--blue-black);
    letter-spacing: 0;
    line-height: normal;
  }

  .group-2 {
    position: relative;
    width: 260.31px;
    height: 86.34px;
  }

  .overlap-group {
    position: absolute;
    width: 256px;
    height: 13px;
    top: 45px;
    left: 0;

    .img {
      position: absolute;
      width: 244px;
      height: 2px;
      top: 6px;
      left: 5px;
    }

    .ellipse {
      left: 0;
      background-color: #ffffff;
      border-color: var(--blue);
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      border-radius: 6.02px;
      border: 1px solid;
    }

    .ellipse-2 {
      left: 244px;
      background-color: var(--blue);
      position: absolute;
      width: 12px;
      height: 12px;
      top: 0;
      border-radius: 6.02px;
      border: 1px solid;
      border-color: var(--blue);
    }

    .ellipse-3 {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 1px;
      left: 122px;
      background-color: var(--orange);
      border-radius: 6.02px;
      border: 1px solid;
      border-color: var(--orange);
    }

    .p {
      position: absolute;
      width: 141px;
      top: 0;
      left: 58px;
      opacity: 0.7;
      font-family: 'Poppins', Helvetica;
      font-weight: 500;
      color: var(--blue-black);
      font-size: 14px;
      letter-spacing: 0;
      line-height: normal;
    }

    .text-wrapper-8 {
      position: absolute;
      width: 26px;
      top: 62px;
      left: 115px;
      opacity: 0.7;
      font-family: 'Poppins', Helvetica;
      font-weight: 500;
      color: var(--blue-black);
      font-size: 14px;
      letter-spacing: 0;
      line-height: normal;
    }

    .vector-2 {
      position: absolute;
      width: 22px;
      height: 15px;
      top: 27px;
      left: 116px;
    }
  }

  .text-wrapper-9 {
    position: relative;
    width: 87px;
    height: 17px;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 300;
    color: var(--blue-black);
    font-size: 10px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div-10 {
    display: flex;
    flex-direction: column;
    width: 166px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: relative;
  }

  .text-wrapper-10 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 12px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }

  .text-wrapper-11 {
    position: relative;
    align-self: stretch;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 12px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }

  .text-wrapper-12 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
  }

  .div-11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
  }

  .text-wrapper-13 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: 'Inter', Helvetica;
    font-weight: 700;
    color: var(--blue-black);
    font-size: 18px;
    letter-spacing: 0;
    line-height: normal;
  }

  .vector-3 {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 2px;
    margin-top: -1px;
  }

  .div-12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }

  .div-13 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex: 0 0 auto;
  }

  .text-wrapper-14 {
    position: relative;
    width: fit-content;
    margin-top: -1.33px;
    opacity: 0.7;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 17.3px;
    letter-spacing: 0;
    line-height: normal;
  }

  .text-wrapper-15 {
    position: relative;
    width: fit-content;
    font-family: 'Poppins', Helvetica;
    font-weight: 500;
    color: var(--blue-black);
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
  }

  .chevron-right {
    position: relative;
    width: 18px;
    height: 18px;
  }

  .div-14 {
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
    align-self: stretch;
    width: 100%;
    margin-top: 35px;
    flex: 0 0 auto;
  }

  .primary {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    position: relative;
    flex: 1;
    flex-grow: 1;
    background-color: #31449b;
    border-radius: 8px;
    border: 0px none;
    border-color: var(--blue);
    cursor: pointer;
  }

  .create-account {
    position: relative;
    width: fit-content;
    font-family: 'Inter', Helvetica;
    font-weight: 600;
    color: var(--white);
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }

  .create-account-wrapper {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    position: relative;
    flex: 1;
    flex-grow: 1;
    border-radius: 8px;
    border: 1px solid;
    border-color: var(--blue);
    cursor: pointer;
  }

  .create-account-2 {
    position: relative;
    width: fit-content;
    font-family: 'Inter', Helvetica;
    font-weight: 600;
    color: var(--blue);
    font-size: 20px;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
}
