::placeholder {
  opacity: 70%;
}

.car-rental {
  min-height: calc(100vh - 174px);
  position: relative;

  .car-rental-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
  }

  .car-booking-form {
    padding: 38px 21px 40px 21px;
    background-color: $cl-white;
    border-radius: 9px;
    box-shadow: 6px 4px 20px 0px #0000000f;
    margin-top: 63px;

    .drop-different {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .recent-search {
      background-color: #fda40017;
      padding: 8px 18px;
      border-radius: 37px;
    }
  }
}

@media (max-width: 992px) {
  .car-rental {
    .car-booking-form {
      // .switch-icon {
      //   top: 44px;
      //   transform: translateX(865%);
      // }
    }
  }
}

@media (max-width: 768px) {
  .car-rental {
    .car-booking-form {
      .drop-different {
        top: -27px;
      }

      // .switch-icon {
      //   top: 40px;
      //   transform: translateX(-50%);
      // }
    }
  }
}

@media (max-width: 576px) {
  .car-rental {
    .car-booking-form {
      padding: 20px 15px 25px 15px;
      margin-top: 24px;

      .drop-different {
        top: 0px;
      }

      // .switch-icon {
      //   top: 88px;
      //   // transform: translateX(-50%) rotate(90deg);
      // }
    }
  }
}
