.hero-small-blue-bg {
  background-color: $cl-blue;
  width: 100%;
  height: 186px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.hero-links {
  cursor: pointer;

  &:hover {
    a {
      color: $cl-orange;
    }

    svg {
      path {
        stroke: $cl-orange;
      }
    }
  }
}

.dashed-line {
  border-bottom: 2px dashed rgba(0, 0, 0, 0.0509803922);
  padding-bottom: 5px;
}
.flight-steps-and-details {
  .three-steps-bg {
    padding: 50px 44px;
    background-color: $cl-white;
    border-radius: 8px;
    margin-bottom: 40px;
    box-shadow: 3px 4px 26px 0px #0000000d;

    .three-steps {
      position: relative;

      .pe-30 {
        padding-right: 30px;
      }

      // &::before {
      //     content: "";
      //     width: 100%;
      //     height: 3px;
      //     position: absolute;
      //     top: 16px;
      //     background-color: #ece7e78c;
      // }

      .step-1 {
        width: 36px;
        height: 36px;
        background-color: #efeaea;
        border-radius: 50%;
      }

      .step-2 {
        width: 36px;
        height: 36px;
        background-color: #efeaea;
        border-radius: 50%;
      }

      .step-3 {
        width: 36px;
        height: 36px;
        background-color: #efeaea;
        border-radius: 50%;
      }
    }
  }

  .depart-btn {
    padding: 6px 10px;
    background-color: $cl-blue;
    border-radius: 4px;
  }

  .flight-depart-details {
    border: 1px solid #0000001a;
    border-radius: 8px;

    .flight-shadules {
      .flight-price-time {
        border-radius: 0px;
        border: 0;
        border-top: 1px solid #0000001a;
        padding: 39px 10px;

        .flight-times {
          // width: 70%;
        }
      }
    }
  }
}

.price-booking-details {
  .price-details {
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
    padding: 22px 0;

    h6 {
      border-bottom: 2px solid #0000000d;
    }

    .total {
      border-top: 2px dashed #0000000d;
    }
  }

  .booking-details {
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;
    border-radius: 8px;
    padding: 22px 0;
    margin-top: 27px;

    h6 {
      border-bottom: 2px solid #0000000d;
    }

    .customer-profile-pic {
      border-bottom: 2px solid #0000000d;
    }
  }
}

.personal-information-form {
  ::placeholder {
    opacity: 70%;
  }
  .persnal-information {
    padding: 33px 36px 64px 36px;
    background-color: $cl-white;
    border-radius: 8px;
    box-shadow: 3px 4px 26px 0px #0000000d;
  }

  .contact-info {
    padding: 55px 36px 43px 36px;
    background-color: $cl-white;
    border-radius: 8px;
    box-shadow: 3px 4px 26px 0px #0000000d;
  }

  .remove-passenger-btn {
    background-color: $cl-blue;
    padding: 5px 11px;
    border-radius: 6px;
  }
  .name-error {
    color: #d23939;
  }

  select {
    outline: 0;
  }
  .add-passenger-btn {
    padding: 6px 10px;
    background-color: #d4dcff;
    border-radius: 6px;
  }
}

.InfoPopover__content {
  position: absolute;
  z-index: 10;
  display: block;
  left: 50%;
  transform: translateX(-50%) scaleY(0); /* Initially scaled down */
  transform-origin: top; /* Ensures scaling happens from the top */
  width: 110%;
  min-width: 18rem;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin-top: 0.5rem;
  overflow: hidden;
  max-height: 0; /* Start with 0 height */
  opacity: 0; /* Start fully transparent */
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease,
    transform 0.5s ease; /* Transition for folding effect */
}

.InfoPopover__content--visible {
  max-height: 500px; /* Enough max-height to contain the content */
  opacity: 1; /* Fully visible */
  transform: translateX(-50%) scaleY(1); /* Scale back to normal */
}

@media (max-width: 1200px) {
  .flight-steps-and-details {
    .price-booking-details {
      box-shadow: 3px 4px 26px 0px #0000000d;
      background-color: $cl-white;
      border-radius: 8px;

      .price-details-heading {
        background-color: $cl-white;
        padding: 22px 16px 024px 16px;
        border-radius: 8px;
      }

      .price-details-position {
        position: absolute;
        width: 100%;
        top: 63px;
        left: 0;
        background-color: $cl-white;
        z-index: 1;
        border-radius: 0 0 10px 10px;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.1s;

        &.price-dropdown-show {
          transform: scaleY(1);
        }

        .price-details {
          border-radius: 0 0 8px 8px;
          padding: 0 0 22px 0;
          border-top: 2px solid #0000000d;
        }
      }

      .booking-details-heading {
        background-color: $cl-white;
        padding: 22px 16px 024px 16px;
        border-radius: 8px;
      }

      .booking-details-position {
        position: absolute;
        width: 100%;
        top: 63px;
        left: 0;
        background-color: $cl-white;
        z-index: 1;
        border-radius: 0 0 10px 10px;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.1s;

        &.booking-dropdown-show {
          transform: scaleY(1);
        }

        .booking-details {
          margin-top: 0;
          border-top: 2px solid #0000000d;
          padding: 0 0 22px 0;

          img {
            width: 60px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
  }
  // .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  //     padding: 10px !important;
  // }
  .flight-steps-and-details {
    .flight-depart-details {
      .flight-shadules {
        .flight-price-time {
          padding: 20px 10px;

          .flight-times {
            // width: 74%;
          }
        }
      }
    }
  }

  .personal-information-form {
    .input-decoration {
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .hero-links {
    a {
      font-size: 12px;
    }
  }

  .flight-steps-and-details {
    .three-steps-bg {
      padding: 20px 15px;
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 20px;
      box-shadow: 3px 4px 26px 0px rgba(0, 0, 0, 0.0509803922);

      p {
        font-size: 14px;
      }
    }

    .price-booking-details {
      .h-260 {
        height: 260px;
        transition: 0.1s;
      }

      .booking-details {
        border-radius: 0 0 10px 10px;
      }

      .booking-details-position {
        position: absolute;
        width: 100%;
        top: 135px;
        left: 0;
        background-color: $cl-white;
        z-index: 1;
        border-radius: 0 0 10px 10px;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.1s;
      }
    }

    .flight-depart-details {
      .flight-info {
        p {
          font-size: 12px;
        }
      }

      .flight-shadules {
        .flight-price-time {
          padding: 20px;

          .flight-times {
            // width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .flight-steps-and-details {
    .three-steps-bg {
      padding: 20px 10px;
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 20px;
      box-shadow: 3px 4px 26px 0px rgba(0, 0, 0, 0.0509803922);

      p {
        font-size: 12px;
      }
    }
  }

  .personal-information-form {
    .form-submit-btn {
      padding: 10px 0;
    }
  }
}
