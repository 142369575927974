.form-submit-btn {
  background-color: $cl-blue;
  padding: 17px 0;
  transition: 0.4s;
  border: 1px solid $cl-blue;
  border-radius: 6px;
  text-align: center;
}

.form-submit-btn:hover {
  background-color: $cl-orange;
  border: 1px solid $cl-orange;
  color: $cl-white !important;
}

.change-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button-with-icon {
  display: flex;
  padding: 8px 13px;
  align-items: center;
  gap: 7px;
  border-radius: 7px;
  border: 1px solid $cl-orange;
  background: #fff;
}

.cta-btn {
  padding: 14px 80px;
  background-color: $cl-blue;
  border-radius: 6px;
  color: $cl-white;
  &:hover {
    background-color: $cl-orange;
    color: $cl-white !important;
    transition: 0.2;
  }
}
.back-btn-top {
  margin-top: -35px;
}
.counter {
  font-weight: bold;
  margin: 0 15px;
}
