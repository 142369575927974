.flight-steps-and-details {
  .details-2 {
    .flight-shadules {
      .flight-price-time {
        .flight-times {
          width: 65%;

          .flight-start-stop-line {
            width: 255px;
          }
        }
      }
    }
  }
}

.flight-time-price {
  .flight-shadules {
    .select-seat-btn {
      background-color: $cl-orange;
      padding: 10px 41px;
      border-radius: 6px;

      &:hover {
        color: $cl-white;
      }
    }
  }
}

.vip-lounges {
  margin-top: 57px;
  background-color: $cl-white;
  box-shadow: 3px 4px 26px 0px #0000000d;
  padding: 31px 26px 33px 19px;
  border-radius: 8px;

  .vip-lounges-btn {
    border: 0;
    outline: 0;
    border-bottom: 1px solid #0000001a;
    padding: 16px 0 13px 0;
    background-color: transparent;

    &.active {
      border-bottom: 2px solid $cl-orange;
    }
  }

  .vip-lounges-cards {
    margin-top: 37px;

    .vip-lounges-card {
      border-radius: 14px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.04);
      background-color: $cl-white;
      height: 100%;
      position: relative;

      .depart-btn {
        background-color: $cl-blue;
        padding: 6px 10px;
        border-radius: 4px;
        position: absolute;
        top: 10px;
        right: 7px;
      }

      .return-btn {
        background-color: $cl-blue;
        padding: 6px 10px;
        border-radius: 4px;
        position: absolute;
        top: 10px;
        right: 7px;
      }

      .card-inner-content {
        padding: 11px 9px 24px 12px;

        .person {
          background-color: #fda40024;
          padding: 6px 10px;
          border-radius: 6px;
        }
      }

      .add-lounges-btn {
        background-color: $cl-blue;
        padding: 19px 0;

        &:hover {
          background-color: $cl-orange;
          color: $cl-white !important;
        }
      }
    }
  }
}

.extra-baggage-allowance {
  margin-top: 57px;
  padding: 31px 20px 38px 19px;
  background-color: $cl-white;
  box-shadow: 3px 4px 26px 0px #0000000d;
  border-radius: 8px;

  .baggage-add-box {
    // border: 1px solid #0000000a;
    border-radius: 8px;
    background-color: rgba(49, 68, 155, 0.02);
    // padding: 15px 23px 36px 15px;
    margin-top: 39px;

    .baggage-add-btn {
      background-color: $cl-blue;
      padding: 10px 72px;
      border-radius: 6px;
      width: 200px;

      // &:hover {
      //   color: $cl-white !important;
      //   background-color: $cl-orange;
      // }
      &.disabled {
        background-color: #9dadf4;
        color: $cl-blue;
        cursor: not-allowed;
      }
    }
    .journey {
      background-color: $cl-blue;
      padding: 15px 23px;
      color: $cl-white;
      border-radius: 10px;
      .journey-badge {
        background-color: $cl-orange;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
    .baggage-addon {
      padding: 15px 23px 20px 23px;
    }
    .journey-border-bottom {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 90%;
        height: 1px;
        background-color: #0000001a;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}

.airport-transfer {
  padding: 31px 26px 36px 19px;
  background-color: $cl-white;
  margin-top: 57px;
  box-shadow: 3px 4px 26px 0px #0000000d;
  border-radius: 8px;

  .cab-btn {
    border: 0;
    outline: 0;
    border-bottom: 1px solid #0000001a;
    padding: 16px 0 13px 0;
    background-color: transparent;

    &.active {
      border-bottom: 2px solid $cl-orange;
    }
  }

  .airport-transfer-cards {
    margin-top: 37px;

    .airport-transfer-card {
      border: 1px solid #0000000a;
      background-color: $cl-white;
      border-radius: 14px;
      height: 100%;
      overflow: hidden;

      .card-inner-content {
        padding: 11px 12px 24px 12px;

        .per-person-price {
          padding: 6px 5px;
          background-color: #fda40024;
          border-radius: 6px;
        }
      }

      .book-now-btn {
        background-color: $cl-blue;
        padding: 19px 0;

        &:hover {
          background-color: $cl-orange;
          color: $cl-white !important;
        }
      }

      .depart-btn {
        position: absolute;
        top: 10px;
        right: 7px;
        background-color: $cl-blue;
        padding: 6px 10px;
        border-radius: 6px;
      }
    }
  }
}

.plan-pay-btn {
  padding: 19px 0;
  background-color: $cl-blue;
  border-radius: 6px;
  margin-top: 76px;
  cursor: pointer;
  &:hover {
    color: $cl-white !important;
    background-color: $cl-orange !important;
  }
}

@media (max-width: 1400px) {
  .flight-depart-details {
    .flight-time-price {
      .flight-shadules {
        .select-seat-btn {
          background-color: $cl-orange;
          padding: 10px 25px;
          border-radius: 6px;

          &:hover {
            color: $cl-white;
          }
        }
      }
    }
  }

  .flight-steps-and-details {
    .details-2 {
      .flight-shadules {
        .flight-price-time {
          .flight-times {
            width: 68%;

            .flight-start-stop-line {
              width: 190px;
            }
          }
        }
      }
    }
  }

  .select-travel-insurence {
    .insurence-cards {
      p {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .flight-steps-and-details {
    .details-2 {
      .flight-shadules {
        .flight-price-time {
          .flight-times {
            width: 70%;

            .flight-start-stop-line {
              width: 255px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .flight-steps-and-details {
    .details-2 {
      .flight-shadules {
        .flight-price-time {
          .flight-times {
            width: 70%;

            .flight-start-stop-line {
              width: 125px;
            }
          }
        }
      }
    }
  }

  .tab-none {
    display: none;
  }

  .select-travel-insurence {
    margin-top: 57px;
    padding: 31px 23px 40px 19px;
    background-color: $cl-white;
    border-radius: 8px;
    box-shadow: 3px 4px 26px 0px #0000000d;

    .insurence-tabs-details {
      border: 1px solid #0000001a;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fbfbfd;
      margin-top: 30px;

      .tab-btns {
        button {
          border: 0;
          outline: 0;
          background-color: transparent;
          padding: 16px 0 13px 0;
          width: 100%;
          border-bottom: 1px solid #0000001a;

          &.active {
            border-bottom: 1px solid $cl-orange;
          }
        }
      }

      .insurence-cards {
        margin-top: 27px;

        .plan-card-border-top {
          border-top: 1px solid #0000001a;
        }

        .qulitys {
          .cancellation {
            padding: 21px 0;
          }

          .lost-luggage {
            padding-top: 21px 0;
          }

          .medical-emergencies {
            padding-top: 21px 0;
          }

          .price {
            padding-top: 21px 0;
          }
        }

        .insurence-tab-content {
          width: 100%;
        }

        .standard-plan {
          background-color: transparent;
          width: 100%;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            height: 70%;
            width: 1px;
            background-color: #0000001a;
            left: 38%;
            top: 0;
          }

          p {
            padding: 21px 0;
          }

          .cancellation-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .lost-luggage-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .medical-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .price-width {
            width: 100%;
            border-top: 1px solid #0000001a;
            border-bottom: 1px solid #0000001a;
          }

          .standard {
            padding: 13px 0px 15px 0px;
            width: 100%;
          }

          .get {
            padding: 21px 0;
            margin: 0;
            width: 400px;
            text-align: center;
          }

          .check-img-1-width {
            padding: 21px 0;
            margin: 0;
            width: 400px;
            text-align: center;

            .check-img-1 {
              padding-top: 0;
            }
          }

          .check-img-2-width {
            padding: 21px 0;
            margin: 0;
            width: 400px;
            text-align: center;

            .check-img-2 {
              padding-top: 0;
            }
          }

          .per-person-price {
            padding: 21px 0;
            margin: 0;
            width: 400px;
            text-align: center;
          }

          .insurence-add-btn {
            margin: 23px 0 20px 0;
            padding: 10px 0px;
            border-radius: 6px;
            transition: 0.4s;
            width: 95%;
            background-color: $cl-orange;

            &:hover {
              color: $cl-white !important;
            }
          }
        }

        .pro-plan {
          border: 1px solid #0000000a;
          border-radius: 8px;
          background-color: white;
          transition: 0.4s;

          .pro {
            padding: 13px 0px 15px 0px;
            border-bottom: 1px solid #0000000a;
            width: 100%;
            transition: 0.4s;
          }

          .get {
            padding-top: 57px;
            margin: 0px 20px;
          }

          .check-img-1 {
            padding-top: 64px;
          }

          .check-img-2 {
            padding-top: 57px;
          }

          .per-person-price {
            padding-top: 57px;
          }

          .insurence-add-btn {
            margin: 23px 0 20px 0;
            padding: 10px 72px;
            border-radius: 6px;
            transition: 0.4s;

            &:hover {
              color: $cl-white !important;
            }
          }

          &:hover {
            border-color: $cl-orange;

            .pro {
              border-bottom: 1px solid $cl-orange;
            }

            .insurence-add-btn {
              background-color: $cl-orange;
            }
          }
        }

        .premium-plan {
          border: 1px solid #0000000a;
          border-radius: 8px;
          background-color: white;
          transition: 0.4s;

          .premium {
            padding: 13px 0px 15px 0px;
            border-bottom: 1px solid #0000000a;
            width: 100%;
            transition: 0.4s;
          }

          .get {
            padding-top: 57px;
            margin: 0px 20px;
          }

          .check-img-1 {
            padding-top: 64px;
          }

          .check-img-2 {
            padding-top: 57px;
          }

          .per-person-price {
            padding-top: 57px;
          }

          .insurence-add-btn {
            margin: 23px 0 20px 0;
            padding: 10px 72px;
            border-radius: 6px;
            transition: 0.4s;

            &:hover {
              color: $cl-white !important;
            }
          }

          &:hover {
            border-color: $cl-orange;

            .pro {
              border-bottom: 1px solid $cl-orange;
            }

            .insurence-add-btn {
              background-color: $cl-orange;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .flight-steps-and-details {
    .details-2 {
      .flight-shadules {
        .flight-price-time {
          .flight-times {
            width: 100%;

            .flight-start-stop-line {
              width: 230px;
            }
          }
        }
      }
    }
  }

  .select-travel-insurence {
    .insurence-tabs-details {
      .insurence-cards {
        .standard-plan {
          background-color: $cl-white;
          width: 100%;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            height: 70.7%;
            width: 1px;
            background-color: #0000001a;
            left: 35%;
            top: 0;
          }

          .get {
            width: 300px;
          }

          .check-img-1-width {
            width: 300px;
          }

          .check-img-2-width {
            width: 300px;
          }

          .per-person-price {
            width: 300px;
          }
        }
      }
    }
  }

  .plan-pay-btn {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .vip-lounges {
    padding: 20px 13px;
  }

  .extra-baggage-allowance {
    padding: 25px 13px;

    .baggage-add-box {
      border: 1px solid #0000000a;
      border-radius: 8px;
      padding: 15px 23px 20px 15px;
      margin-top: 39px;

      .baggage-add-btn {
        padding: 10px 72px;
      }
    }
  }

  .select-travel-insurence {
    padding: 31px 10px 40px 10px;

    .insurence-tabs-details {
      .insurence-cards {
        .standard-plan {
          background-color: $cl-white;
          width: 100%;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            height: 71%;
            width: 1px;
            background-color: #0000001a;
            left: 42%;
            top: 0;
          }

          .cancellation {
            font-size: 13px;
          }

          .price {
            font-size: 13px;
          }

          .medical-emergencies {
            font-size: 13px;
          }

          .lost-luggage {
            font-size: 13px;
          }

          p {
            padding: 21px 0;
          }

          .cancellation-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .lost-luggage-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .medical-width {
            width: 100%;
            border-top: 1px solid #0000001a;
          }

          .price-width {
            width: 100%;
            border-top: 1px solid #0000001a;
            border-bottom: 1px solid #0000001a;
          }

          .standard {
            padding: 13px 0px 15px 0px;
            width: 100%;
          }

          .get {
            padding: 21px 0;
            margin: 0;
            width: 50%;
            text-align: center;
            font-size: 12px;
          }

          .check-img-1-width {
            padding: 21px 0;
            margin: 0;
            width: 50%;
            text-align: center;

            .check-img-1 {
              padding-top: 0;
            }
          }

          .check-img-2-width {
            padding: 21px 0;
            margin: 0;
            width: 50%;
            text-align: center;

            .check-img-2 {
              padding-top: 0;
            }
          }

          .per-person-price {
            padding: 21px 0;
            margin: 0;
            width: 50%;
            text-align: center;
            font-size: 12px;
          }

          .insurence-add-btn {
            margin: 23px 0 20px 0;
            padding: 10px 0px;
            border-radius: 6px;
            transition: 0.4s;
            width: 95%;
            background-color: $cl-orange;

            &:hover {
              color: $cl-white !important;
            }
          }
        }
      }
    }
  }

  .plan-pay-btn {
    padding: 13px 0;
  }
}

@media (min-width: 992px) {
  .select-travel-insurence {
    margin-top: 57px;
    padding: 31px 23px 40px 19px;
    background-color: $cl-white;
    border-radius: 8px;
    box-shadow: 3px 4px 26px 0px #0000000d;

    .insurence-cards {
      margin-top: 57px;

      .qulitys {
        .cancellation {
          padding-top: 112px;
        }

        .lost-luggage {
          padding-top: 57px;
        }

        .medical-emergencies {
          padding-top: 48px;
        }

        .price {
          padding-top: 48px;
        }
      }

      .standard-plan {
        border: 1px solid #0000000a;
        border-radius: 8px;
        background-color: #fbfbfd;
        transition: 0.4s;

        .standard {
          padding: 13px 0px 15px 0px;
          border-bottom: 1px solid #0000000a;
          width: 100%;
          transition: 0.4s;
        }

        .get {
          padding-top: 57px;
          margin: 0px 20px;
        }

        .check-img-1 {
          padding-top: 64px;
        }

        .check-img-2 {
          padding-top: 57px;
        }

        .per-person-price {
          padding-top: 57px;
        }

        .insurence-add-btn {
          margin: 23px 0 20px 0;
          padding: 10px 72px;
          border-radius: 6px;
          transition: 0.4s;

          &:hover {
            color: $cl-white !important;
          }
        }

        &:hover {
          border-color: $cl-orange;

          .standard {
            border-bottom: 1px solid $cl-orange;
          }

          .insurence-add-btn {
            background-color: $cl-orange;
          }
        }
      }

      .pro-plan {
        border: 1px solid #0000000a;
        border-radius: 8px;
        background-color: #fbfbfd;
        transition: 0.4s;

        .pro {
          padding: 13px 0px 15px 0px;
          border-bottom: 1px solid #0000000a;
          width: 100%;
          transition: 0.4s;
        }

        .get {
          padding-top: 57px;
          margin: 0px 20px;
        }

        .check-img-1 {
          padding-top: 64px;
        }

        .check-img-2 {
          padding-top: 57px;
        }

        .per-person-price {
          padding-top: 57px;
        }

        .insurence-add-btn {
          margin: 23px 0 20px 0;
          padding: 10px 72px;
          border-radius: 6px;
          transition: 0.4s;

          &:hover {
            color: $cl-white !important;
          }
        }

        &:hover {
          border-color: $cl-orange;

          .pro {
            border-bottom: 1px solid $cl-orange;
          }

          .insurence-add-btn {
            background-color: $cl-orange;
          }
        }
      }

      .premium-plan {
        border: 1px solid #0000000a;
        border-radius: 8px;
        background-color: #fbfbfd;
        transition: 0.4s;

        .premium {
          padding: 13px 0px 15px 0px;
          border-bottom: 1px solid #0000000a;
          width: 100%;
          transition: 0.4s;
        }

        .get {
          padding-top: 57px;
          margin: 0px 20px;
        }

        .check-img-1 {
          padding-top: 64px;
        }

        .check-img-2 {
          padding-top: 57px;
        }

        .per-person-price {
          padding-top: 57px;
        }

        .insurence-add-btn {
          margin: 23px 0 20px 0;
          padding: 10px 72px;
          border-radius: 6px;
          transition: 0.4s;

          &:hover {
            color: $cl-white !important;
          }
        }

        &:hover {
          border-color: $cl-orange;

          .pro {
            border-bottom: 1px solid $cl-orange;
          }

          .insurence-add-btn {
            background-color: $cl-orange;
          }
        }
      }
    }
  }
}
