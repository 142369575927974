.glass-input-search {
  .glass-bg {
    background-color: #ffffff29;
    padding: 8px 29px;
    border-radius: 33px;
    width: 56%;
    margin: 0 auto;

    .css-h4y409-MuiList-root {
      padding: 0;
      color: white;
      font-weight: var(--fw-600);
    }
  }

  ::placeholder {
    color: $cl-white !important;
    opacity: 70%;
  }

  .city-dropdown {
    padding: 8px 0;

    select {
      width: 110px;
      padding-right: 20px;
      border: 0;
      outline: 0;
      background-color: transparent;
      background-image: url('../../../../public/Assets/icons/dropdown-white-btn.svg');
      background-position: right;
    }

    .city-dropdown-arrow {
      padding-left: 20px;

      .css-iksidf-MuiSvgIcon-root,
      .css-i4bv87-MuiSvgIcon-root {
        fill: white !important;
      }
    }
  }

  .search-input-events {
    border-left: 1px solid #ffffff52;
    padding: 8px 10px 8px 50px;
    margin-left: 39px;
  }

  .recent-search {
    .glass-input {
      background-color: #ffffff29;
      padding: 8px 18px;
      border-radius: 37px;
    }
  }
}

.slider-rating {
  position: absolute;
  top: 20px;
  right: -1px;
  padding: 7px 6px 7px 14px;
  // background-image: url("/assets/image/svg/rating-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-save-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px 19px;
  border-radius: 7.5px 0 7.5px 0;
  background-color: #000000a1;
  cursor: pointer;
}

.tours-hero-bg {
  // background-image: url("/assets/image/png/tours-hero-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 0;
}

.filter-and-sortby {
  .input-decoration {
    width: 197px;
    margin-left: 14px;
    padding-right: 33px !important;
  }
}

.tours-city {
  .tour-card-slider {
    .tour-card {
      padding: 11px 11px 14px 11px;
      border: 1px solid #0000000a;
      margin: 35px 10px 0 10px;
      border-radius: 14px;
    }
  }
}

@media (max-width: 1200px) {
  .glass-input-search {
    .glass-bg {
      width: 70%;
    }
  }
}

@media (max-width: 992px) {
  .glass-input-search {
    .glass-bg {
      width: 85%;
    }

    .search-input-events {
      padding: 8px 10px 8px 30px;
      margin-left: 30px;
    }
  }

  .filter-and-sortby {
    .input-decoration {
      padding: 13px 16px;
    }
  }
}

@media (max-width: 768px) {
  .glass-input-search {
    .glass-bg {
      width: 100%;
    }

    .city-dropdown {
      padding: 6px 0;
    }

    .search-input-events {
      padding: 6px 10px 6px 20px;
      margin-left: 20px;
    }
  }

  .slider-save-btn {
    padding: 5px 13px;

    svg {
      width: 17px;
    }
  }

  .filter-and-sortby {
    .w-md-100 {
      width: 100%;
    }

    .w-md-50 {
      width: 50%;
    }

    .input-decoration {
      width: 100%;
    }
  }

  .tours-city {
    .tour-card-slider {
      .tour-card {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 576px) {
  .tour-hero-bg {
    padding: 65px 0;
  }

  .glass-input-search {
    .glass-bg {
      all: unset;
    }

    .city-dropdown {
      padding: 13px 61px;
      // background-image: url("/assets/image/svg/city-dropdown-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: -1px;
    }

    .search-input-events {
      padding: 13px 10px 13px 20px !important;
      margin-left: 0px;
    }

    .glass-bg-2 {
      width: 100%;
      border: 0;
      background-color: #ffffff29;
      padding: 8px 29px;
      border-radius: 33px;
    }
  }

  .filter-and-sortby {
    .input-decoration {
      padding: 9px;
    }
  }
}
