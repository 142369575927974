// <!--================ TRIP-ITNERARY ================-->
.nextDay {
  font-size: 13px;
  position: absolute;
  // margin-left: 4px;
}
.packingList-div {
  overflow: hidden auto;
  height: 700px;
}
.trip-itnerary-header {
  //   height: 100vh;
  padding-bottom: 60px;

  .trip-itnerary {
    .container {
      height: 100%;
    }

    .itnerary-area {
      height: 100%;
      overflow: hidden;
      padding: 40px 48px;
      border-radius: 22px;
      background: var(--white, #fff);
      box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);

      .itnerary-area-background-1 {
        display: flex;
        border-radius: 50%;
        height: 700px;
        width: 700px;
        background-color: $cl-orange;
        position: absolute;
        right: -465px;
        top: -70%;
      }

      .itnerary-area-background-2 {
        display: flex;
        border-radius: 50%;
        height: 700px;
        width: 700px;
        background-color: $cl-orange;
        opacity: 20%;
        position: absolute;
        right: -430px;
        top: -65%;
      }

      .itnerary-heading {
        margin-bottom: 20px;

        .label {
          text-align: center;
          padding: 7px 40px;
          border-radius: 28px;
          background: rgba(253, 164, 0, 0.1);
          margin-bottom: 20px;
          width: fit-content;
        }
      }

      .block-1 {
        .row {
          padding-top: 25px;
          margin-bottom: 25px;

          .time {
            padding: 27px 0px 27px;
            border-radius: 22px;
            border: 3px solid $cl-white;
            background: $cl-white;
            box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
          }

          .cost {
            // width: 100%;
            padding: 27px 0px 27px;
            border-radius: 22px;
            border: 3px solid $cl-white;
            background: $cl-white;
            box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
            height: 164px;
          }
        }

        .flight-block {
          // height: 450px;
          // width: 95%;
          padding: 30px 24px;
          border-radius: 22px;
          border: 3px solid #ffffff;
          background: #ffffff;
          box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
          // overflow-y: scroll;
        }

        .flight-lists {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 35px;

          .flight-list {
            position: relative;

            .stops-position {
              .stops {
                position: relative;

                &::after {
                  content: '';
                  height: 1px;
                  width: 70%;
                  border-top: 2px dotted $cl-blue;
                  position: absolute;
                  top: -13.8px;
                }

                .paln-takeoff {
                  position: absolute;
                  left: -2px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  border: 1px solid $cl-blue;
                  top: -20px;
                  z-index: 1;
                  background-color: $cl-white;
                }

                .paln-land {
                  position: absolute;
                  right: -2px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  border: 1px solid $cl-blue;
                  top: -20px;
                  z-index: 1;
                  background-color: $cl-blue;
                }

                .first-stop {
                  height: 13px;
                  width: 13px;
                  border-radius: 50%;
                  background-color: $cl-orange;
                  display: inline-block;
                  position: absolute;
                  top: -20px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 1;
                }

                .second-stop {
                  height: 13px;
                  width: 13px;
                  border-radius: 50%;
                  background-color: $cl-orange;
                  display: inline-block;
                  position: absolute;
                  top: -20px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 1;
                }
              }
            }

            .no-stops-position {
              .stops {
                position: relative;

                &::after {
                  content: '';
                  height: 1px;
                  width: 70%;
                  border-top: 2px dotted $cl-blue;
                  position: absolute;
                  top: -13.8px;
                }

                .paln-takeoff {
                  position: absolute;
                  left: -2px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  border: 1px solid $cl-blue;
                  top: -20px;
                  z-index: 1;
                  background-color: $cl-white;
                }

                .paln-land {
                  position: absolute;
                  right: -2px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  border: 1px solid $cl-blue;
                  top: -20px;
                  z-index: 1;
                  background-color: $cl-blue;
                }
              }
            }
          }
        }
      }
    }

    .hotel-block {
      padding: 20px 25px;
      border-radius: 22px;
      background: $cl-white;
      box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);

      .row {
        padding-top: 0;
      }
    }
  }
}

.block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  .label {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.hotel-cards {
  overflow: auto;
  height: 429px;
}

.hotel-card {
  margin-bottom: 30px;
  position: relative;

  img {
    border-radius: 14px 14px 0px 0px;
    height: 206px;
    width: 100%;
  }

  .rating-tab {
    background-image: url('../../../../public/Assets/icons/rating-tab-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -1px;
    left: 200px;
    height: 47px;
    width: 24px;
    padding: 5px 5px 12px 5px;
    display: flex;
    flex-direction: column;
    text-align: center;

    img {
      width: 100%;
    }

    p {
      padding-top: 2px;
    }
  }

  .card-info {
    margin-top: 17px;

    :first-child {
      padding-bottom: 4px;
    }
  }
}

body::-webkit-scrollbar {
  width: 6px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: $cl-orange #f4f4f4;
}

body::-webkit-scrollbar-track {
  background: #f4f4f4;
}

body::-webkit-scrollbar-thumb {
  background-color: $cl-orange;
  border-radius: 6px;
  border: 3px solid $cl-orange;
}

.hotel-cards::-webkit-scrollbar {
  width: 6px;
}
.flight-scroll::-webkit-scrollbar {
  width: 6px;
}

.hotel-cards::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 6px;
}
.flight-scroll::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 6px;
}

.hotel-cards::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 3px solid $cl-orange;
}
.flight-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 3px solid $cl-orange;
}

// <!--================ Timeline ================-->

.timeline-area {
  .breakdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    .action-buttons {
      display: flex;
      align-items: center;
      gap: 35px;
    }
  }

  .breakdown {
    margin: 35px 0;

    .timeline {
      ol {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;

        .progress {
          width: 98%;
          height: 8px;
          background-color: #f6f6f6;
          border-radius: 6px;
          top: 21px;
          position: absolute;

          .progress-bar {
            display: flex;
            height: 8px;
            border-radius: 4px;
            background-color: $cl-orange;
            width: 10%;
          }
        }

        #stage-1:active ~ .progress > .progress-bar {
          width: 20%;
        }

        .timeline-stage {
          .timeline-stage-indication {
            display: flex;
            border-radius: 25px;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: $cl-white;
            filter: drop-shadow(6px 4px 20px rgba(0, 0, 0, 0.1));

            &:hover svg path {
              fill: $cl-orange;
            }
          }

          .green-check svg path {
            fill: #58da37;
          }

          .orange-check svg path {
            fill: $cl-orange;
          }

          .gray-check svg path {
            fill: #d1d1d1;
          }
        }
      }
    }
  }
}

.break-down-bg {
  background-color: #fdfcf9;
  padding: 70px 0 0 0;

  .breakdown-nav {
    margin-top: 45px;
    flex-wrap: nowrap;
    justify-content: space-between;
    display: flex;

    button {
      width: 100%;
      text-align: center;
      border: none;
      border-bottom: 1.5px solid #e9e8e5;
      padding: 10px 20px;
      font-weight: 500;
      color: $cl-blue-black;
      opacity: 60%;
      background-color: transparent;
    }

    .active {
      color: $cl-orange;
      font-weight: 600;
      opacity: 100%;
      border-bottom: 2px solid $cl-orange;
    }
  }

  .tab-pane {
    display: none;

    .change-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .slider-left-arrow {
      position: absolute;
      top: 47%;
      left: -70px;
      transform: translateY(-50%);
      z-index: 1;
    }

    .slider-right-arrow {
      position: absolute;
      top: 47%;
      right: -70px;
      transform: translateY(-50%);
      z-index: 1;
    }

    .timing-card {
      border-radius: 22px;
      background: $cl-white;
      box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 30px 23px;
      position: relative;

      &.img {
        padding: 16px 14px 30px;
      }

      .card-image {
        height: 157px;
        width: 100%;
      }

      .clock-with-time {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 50px;
          height: 50px;
        }
      }

      a {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 121px;
          height: 2px;
          background-color: $cl-orange;
        }
      }

      .clock-with-time-small {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 22px;
          height: 22px;
        }
      }

      a {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 82px;
          height: 2px;
          background-color: $cl-orange;
        }
      }

      .rating-tab {
        top: 15px;
        right: 11px;
        height: 24px;
        width: 44px;
        padding: 4px 5px;
        display: flex;
        gap: 3px;
        align-items: center;

        .rating-bg {
          background-image: url('../../../../public/Assets/icons/rating-tab-bg.svg');
          background-size: contain;
          transform: rotate(90deg);
          background-repeat: no-repeat;
          position: absolute;
          top: -16px;
          right: 12px;
          height: 58px;
          width: 30px;
          // width: 100%;
        }

        img {
          width: 100%;
          z-index: 1;
        }

        p {
          padding-top: 2px;
          z-index: 1;
        }
      }
    }

    .packing-card {
      border-radius: 22px;
      background: $cl-white;
      box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 30px 23px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        background-repeat: no-repeat;
        object-fit: contain;
      }

      .headphone-img {
        width: 100%;
        height: 143px;
      }

      .watch-img {
        width: 100%;
        height: 143px;
      }

      .dryer-img {
        width: 100%;
        height: 143px;
      }
    }

    .slick-track {
      //   margin-bottom: 30px;
      padding: 20px 0;
    }
  }
}

.cta {
  border-radius: 22px;
  background: $cl-white;
  box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 45px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .cta-content {
    width: 60%;
  }

  .orange-circle {
    position: absolute;
    top: -422px;
    left: -357px;
    width: 540px;
    height: 540px;
    border-radius: 541.109px;
    background: $cl-orange;
    filter: blur(2px);
  }

  .orange-circle1 {
    position: absolute;
    bottom: -422px;
    right: -357px;
    width: 540px;
    height: 540px;
    border-radius: 541.109px;
    background: $cl-orange;
    filter: blur(2px);
  }

  .blue-circle {
    position: absolute;
    top: -510px;
    right: -7px;
    width: 540px;
    height: 540px;
    border-radius: 541.109px;
    background: $cl-blue;
    filter: blur(2px);
  }

  .blue-circle1 {
    position: absolute;
    bottom: -510px;
    left: -22px;
    width: 540px;
    height: 540px;
    border-radius: 541.109px;
    background: $cl-blue;
    filter: blur(2px);
  }
}

.width-590 {
  max-width: 590px;
}

.review-section {
  .container {
    width: 770px;

    margin: 0 auto;

    .review-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .review-box {
        text-align: left;
        padding: 34px 56px;
        border: 1px solid #e3e3e3;
        width: 100%;
        border-radius: 20px;

        sapn {
          font-size: 25px !important;
          svg {
            width: 25px !important;
            height: 25px !important;
          }
        }

        textarea {
          min-height: 230px;
          border: 1px solid #e3e3e3 !important;
          width: 100%;
          padding: 17px;
          border-radius: 5px;
          resize: none;

          &::placeholder {
            color: $cl-blue-black;
            opacity: 70%;
            font-size: $fs-16;
            font-weight: 400;
            position: absolute;
            top: 17px;
            left: 17px;
          }
          &:focus-visible {
            outline: none !important;
          }
        }
      }
    }
  }
}
.time-link {
  flex-direction: column;
  gap: 8px;
  align-items: start !important;
}
@media (max-width: 1200px) {
  .trip-itnerary-header {
    padding-bottom: 60px;

    .itnerary-area {
      padding: 40px 48px;

      .itnerary-area-background-1 {
        height: 700px;
        width: 700px;
        right: -465px;
        top: -70%;
      }

      .itnerary-area-background-2 {
        height: 700px;
        width: 700px;
        right: -430px;
        top: -65%;
      }

      .itnerary-heading {
        margin-bottom: 20px;

        .label {
          padding: 7px 40px;
          margin-bottom: 20px;
        }
      }

      .row {
        padding-top: 25px;

        .block-1 {
          // height: 578px;

          .time-cost-block {
            display: flex;
            align-items: center;
            // gap: 68px;
            margin-bottom: 25px;
            // width: 95%;

            .time {
              // width: 100%;
              padding: 27px 0px 27px;
              border-radius: 22px;
              border: 3px solid $cl-white;
              background: $cl-white;
              box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
            }

            .cost {
              width: 100%;
              padding: 27px 0px 27px;
              border-radius: 22px;
              border: 3px solid $cl-white;
              background: $cl-white;
              box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
            }
          }

          .flight-block {
            // height: 384px !important;
            // width: 95%;
            padding: 30px 24px;
            border-radius: 22px;
            border: 3px solid $cl-white;
            background: $cl-white;
            box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
          }
        }

        .block-2 {
          // height: 578px;
        }
      }

      .hotel-block {
        padding: 20px 25px;
        border-radius: 22px;
        background: $cl-white;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);

        .row {
          padding-top: 0;
        }
      }
    }
  }

  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .row {
          .block-1 {
            .flight-lists {
              .flight-list {
                .stops-position {
                  .stops {
                    &::after {
                      width: 100%;
                    }
                  }
                }

                .no-stops-position {
                  .stops {
                    &::after {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .time-cost-block {
    // gap: 20px !important;
  }

  // .flight-list1 {
  //   display: block !important;
  //   .flight-detail {
  //     padding: 20px 0 12px 0 !important;
  //   }
  //   .flight-location {
  //     padding: 0 0 20px 0;
  //   }
  //   &.flight-list2 {
  //     .flight-location {
  //       padding: 0px !important;
  //     }
  //   }
  // }
  // .flight-list {
  //   display: none !important;
  // }
  .block-2 {
    // height: 597px !important;
  }

  .width-590 {
    max-width: 611px !important;
  }
}

@media (max-width: 992px) {
  .trip-itnerary {
    .itnerary-area {
      .row {
        flex-direction: column;

        .block-1 {
          // height: 621px !important;
          width: 100%;

          .time-cost-block {
            width: 100% !important;

            img {
              width: 60px;
            }
          }

          .flight-block {
            width: 100% !important;
          }
        }

        .block-2 {
          width: 100%;
        }

        .row {
          flex-direction: unset;
        }
      }
    }
  }

  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .row {
          .block-1 {
            .flight-lists {
              .flight-list {
                .stops-position {
                  position: absolute;
                  bottom: 0;
                  width: 70%;
                  left: 50%;
                  transform: translateX(-50%);

                  .stops {
                    width: 73%;
                    margin: 0 auto;

                    &::after {
                      left: 0;
                      width: 100%;
                    }
                  }
                }

                .no-stops-position {
                  position: absolute;
                  bottom: 0;
                  width: 40%;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 50%;

                  .stops {
                    width: 100%;
                    margin: 0 auto;

                    &::after {
                      width: 100%;
                      left: 0;
                    }
                  }
                }

                .flight-detail {
                  .location-1 {
                    font-size: 26px;
                  }

                  .location-2 {
                    font-size: 26px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .itnerary-area {
      padding: 34px 20px 0px 20px !important;
    }
  }

  .timeline-area {
    .breakdown-header {
      .action-buttons {
        gap: 12px;
      }
    }
  }

  .break-down-bg {
    .breakdown-nav {
      margin-top: 45px;
      flex-wrap: nowrap;
      justify-content: space-between;
      display: flex;
      // overflow: auto;
      overflow: hidden;

      button {
        width: 100%;
        text-align: center;
        border: none;
        border-bottom: 1.5px solid #e9e8e5;
        padding: 10px 20px;
        font-weight: 500;
        color: $cl-blue-black;
        opacity: 60%;
        background-color: transparent;
        white-space: nowrap;
      }

      .active {
        color: $cl-orange;
        font-weight: 600;
        opacity: 100%;
        border-bottom: 2px solid $cl-orange;
      }
    }

    .tab-pane {
      display: none;

      .change-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }

      .slider-left-arrow {
        display: none !important;
      }

      .slider-right-arrow {
        display: none !important;
      }

      .timing-card {
        border-radius: 22px;
        background: $cl-white;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 30px 18px;
        position: relative;

        &.img {
          padding: 16px 14px 30px;
        }

        .card-image {
          width: 100%;
        }

        .clock-with-time {
          display: flex;
          align-items: center;
          gap: 12px;

          img {
            width: 30px;
            height: 30px;
          }

          p {
            font-size: 18px !important;
          }
        }

        a {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 121px;
            height: 2px;
            background-color: $cl-orange;
          }
        }

        .clock-with-time-small {
          display: flex;
          align-items: center;
          gap: 12px;

          img {
            width: 22px;
            height: 22px;
          }
        }

        a {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 121px;
            height: 2px;
            background-color: $cl-orange;
          }
        }

        .rating-tab {
          top: 15px;
          right: 11px;
          height: 24px;
          width: 44px;
          padding: 4px 5px;
          display: flex;
          gap: 3px;
          align-items: center;

          .rating-bg {
            background-image: url('../../../../public/Assets/icons/rating-tab-bg.svg');
            background-size: contain;
            transform: rotate(90deg);
            background-repeat: no-repeat;
            position: absolute;
            top: -16px;
            right: 12px;
            height: 58px;
            width: 30px;
            // width: 100%;
          }

          img {
            width: 100%;
            z-index: 1;
          }

          p {
            padding-top: 2px;
            z-index: 1;
          }
        }
      }

      .packing-card {
        border-radius: 22px;
        background: $cl-white;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 30px 23px;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          background-repeat: no-repeat;
          object-fit: contain;
        }

        .headphone-img {
          width: 100%;
          height: 143px;
        }

        .watch-img {
          width: 100%;
          height: 143px;
        }

        .dryer-img {
          width: 100%;
          height: 143px;
        }
      }

      .slick-track {
        //   margin-bottom: 30px;
        padding: 20px 0;
      }
    }
  }

  .cta {
    padding: 40px;

    .cta-content {
      width: 80%;
    }

    .orange-circle {
      top: -342px;
      left: -249px;
      width: 400px;
      height: 400px;
    }

    .orange-circle1 {
      bottom: -342px;
      right: -249px;
      width: 400px;
      height: 400px;
    }

    .blue-circle {
      top: -380px;
      right: -73px;
      width: 400px;
      height: 400px;
    }

    .blue-circle1 {
      bottom: -380px;
      left: -73px;
      width: 400px;
      height: 400px;
    }
  }
}

@media (max-width: 768px) {
  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .row {
          .block-1 {
            .flight-lists {
              .flight-list {
                .stops-position {
                  position: absolute;
                  bottom: 0;
                  width: 40%;
                  left: 50%;
                  transform: translateX(-50%);

                  .stops {
                    width: 100%;
                    margin: 0 auto;

                    &::after {
                      left: 0px;
                      width: 100%;
                    }
                  }
                }

                .flight-detail {
                  .location-1 {
                    font-size: 26px;
                  }

                  .location-2 {
                    font-size: 26px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .itnerary-area {
    padding: 34px 20px 40px 20px !important;

    .block-2 {
      width: 100%;
      // height: 538px !important;
    }

    .label {
      gap: 10px;

      img {
        width: 40px;
      }
    }

    .change-button {
      img {
        width: 20px;
      }
    }
  }

  .timeline-area {
    .breakdown-header {
      h2 {
        font-size: 24px !important;
      }

      .action-buttons {
        gap: 12px;

        a {
          p {
            display: none;
          }
        }
      }
    }
  }

  .review-section {
    .container {
      width: 100%;

      .review-container {
        .review-box {
          padding: 25px 19px;

          .stars {
            img {
              width: 24px;
              height: 24px;
            }
          }

          input {
            padding: 17px;

            &::placeholder {
              font-size: $fs-14;
            }
          }
        }
      }
    }
  }

  .cta {
    padding: 40px 20px;

    .cta-content {
      width: 100%;
    }

    .orange-circle,
    .orange-circle1,
    .blue-circle,
    .blue-circle1 {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .block-1 {
          .row {
            .cost {
              img {
                width: 50px;
                height: 50px;
              }
            }
            .flight-lists {
              .flight-list {
                .stops-position {
                  position: relative;
                  bottom: 0;
                  width: 100%;
                  left: 50%;
                  transform: translateX(-50%);

                  .stops {
                    &::after {
                      left: 0px;
                      width: 100%;
                    }
                  }

                  .no-stops {
                    &::after {
                      left: 0px;
                      width: 100%;
                    }
                  }
                }

                .no-stops-position {
                  position: relative;
                  width: 100%;
                }

                .flight-detail {
                  .location-1 {
                    font-size: 16px;
                  }

                  .location-2 {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .col-xxs-12 {
    width: 100% !important;
  }
  .mt-xxs-3 {
    margin-top: 16px;
  }
}

// @media (max-width: 992px) @media (max-width: 768px) @media (max-width: 576px) @media (max-width: 400px) @media (min-width: 576px) @media (min-width: 768px) @media (min-width: 992px) @media (min-width: 1200px);

@media (min-width: 992px) {
  .timeline-area {
    margin: 60px 0;
  }
}
@media (min-width: 1200px) {
  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .row {
          .block-1 {
            .flight-lists {
              .flight-list {
                .stops-position {
                  position: absolute;
                  bottom: 0;
                  width: 40%;
                  left: 50%;
                  transform: translateX(-50%);

                  .stops {
                    width: 100%;
                    margin: 0 auto;

                    &::after {
                      width: 100%;
                      left: 0;
                    }
                  }
                }

                .no-stops-position {
                  position: absolute;
                  bottom: 0;
                  width: 40%;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 64%;

                  .stops {
                    width: 100%;
                    margin: 0 auto;

                    &::after {
                      width: 100%;
                      left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .trip-itnerary-header {
    .trip-itnerary {
      .itnerary-area {
        .row {
          .block-1 {
            .flight-lists {
              .flight-list {
                .stops-position {
                  position: absolute;
                  bottom: 0;
                  // width: 70%;
                  left: 50%;
                  transform: translateX(-50%);

                  .stops {
                    width: 73%;
                    margin: 0 auto;

                    &::after {
                      left: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
