.our-story-hero {
  background-image: url('../../../public/Assets/Img/png/our-sotry-bg.png');
  height: 550px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.our-story-hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.our-story-hero-content img {
  margin-top: 110px;
  margin-bottom: 64px;
  width: 367px;
  height: 102px;
}
.our-story-hero-content h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.96;
}

.our-story-content {
  width: 70%;
  margin: 96px auto;
}
.our-story-content-text {
  width: 55%;
  margin: 0 auto;
}

.our-story-content-text h1 {
  color: #101012;
  font-size: 36px;
  font-weight: 600;
}

.our-story-content-text p {
  color: #101012;
  font-size: 18px;
  font-weight: 400;
}

.our-story-facts-about-explrar {
  width: 100%;
  background: #fbfbfd;
  /* margin: 96px 0; */
}
.our-story-facts-about-explrar-container {
  width: 70%;
  margin: 0 auto;
  padding: 96px 0;
}
.our-story-facts-about-explrar-container h1 {
  color: #101012;
  font-size: 36px;
  font-weight: 600;
}

.our-story-facts-about-explrar-container-box {
  display: flex;
  gap: 32px;
}
.our-story-facts-about-explrar-container-item h1 {
  color: #fda400;
  font-size: 60px;
  font-weight: 600;
}

.our-story-facts-about-explrar-container-item h4 {
  color: #101012;
  font-size: 18px;
  font-weight: 600;
}
.our-story-facts-about-explrar-container-item p {
  color: #5f5f5f;
  width: 384px;
}

.explrar-story-item {
  background-color: #fbfbfd;
}

.explrar-story-about {
  width: 70%;
  margin: 0 auto;
  padding: 96px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
}
.explrar-story-about h1 {
  color: #31449b;
  font-size: 36px;
  font-weight: 600;
}

.explrar-story-about p {
  color: #101012;
  font-size: 18px;
}
.explrar-story-blog {
  width: 70%;
  margin: 96px auto;
}
.explrar-story-blog h1 {
  color: #101012;
  font-size: 36px;
  font-weight: 600;
}
.explrar-story-blog p {
  color: #101012;
  font-size: 20px;
}

.explrar-story-blog-item {
  margin: 64px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.explrar-story-blog-item-container h4 {
  margin-top: 20px;
  color: #fda400;
  font-size: 14px;
  font-weight: 600;
}

.explrar-story-blog-item-container p {
  color: #101012;
  font-size: 16px;
  /* margin-bottom: 24px; */
}

.explrar-story-blog-item-container h1 {
  color: #101012;
  font-size: 24px;
  font-weight: 600;
}

.explrar-story-blog-item-container-tags {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.explrar-story-blog-item-container-tags p {
  padding: 2px 10px;
  border-radius: 16px;
  border: 1px solid #d5d9eb;
  background: #fafafc;
  font-size: 14px;
  color: #31449b;
  font-weight: 500;
}

.explrar-story-blog-hr-line {
  border-bottom: 1px solid rgba(161, 161, 161, 0.24);
}

.explrar-story-blog-btn {
  margin-top: 32px;
  display: flex;
  justify-content: end;
}
.explrar-story-blog-btn button {
  padding: 14px 20px;
  background-color: #31449b;
  border-radius: 6px;
  border: 1px solid #31449b;
  color: #fff;
}
