.events-hero-bg {
  background-image: url('../../../../public/Assets/Img/png/events-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 0;
}

.cursor-pointer {
  cursor: pointer;
}
/* Event home page css 26/2/2024 */
form.event-form.mt-4.pt-md-3 {
  position: absolute !important;
  left: 10%;
  right: 10%;
}
.event-slider {
  margin-top: 225px;
  text-align: center;
}

.event-form {
  select {
    border: 0;
    outline: 0;
    background-image: url('../../../../public/Assets/icons/drop-down-arrow.svg');
  }

  .input-decoration {
    border: 1px solid rgba(0, 0, 0, 0.1294117647);
  }
}
.event-page-vh {
  min-height: 48vh !important;
}
.event-details-page-vh {
  min-height: 0vh !important;
}
.event-slider {
  // margin-top: 52px;

  .event-card {
    border: 1px solid #0000000a;
    padding: 11px 11px 16px 11px;
    background-color: $cl-white;
    border-radius: 14px;

    // .event-card-content {
    //   display: flex;
    // }

    .event-img {
      border-radius: 8px;
      max-width: 284px;
      max-height: 295px;
      min-height: 295px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.chatbot {
  position: fixed;
  right: 30px;
  bottom: 20px;
  z-index: 3;

  .chatbot-content {
    overflow: hidden;
    width: 628px;
    border-radius: 8px;
    height: 999px;
    background-color: $cl-white;
    transition: 0.2s;
    transform: scale(0);
    transform-origin: bottom right;

    .support {
      padding: 30px 19px;
      background-color: $cl-blue;
    }

    .chat-and-input {
      height: calc(100% - 94px);

      .chats {
        padding: 33px 30px 0 30px;

        .explrar-chat {
          padding: 8px 13px 9px 18px;
          border: 0.62px solid #0000001a;
          box-shadow: 0px 27.2421875px 74.296875px -17.3359375px #0000000a;
          border-radius: 0 4.5px 4.5px 4.5px;
        }

        .mt-29 {
          margin-top: 29px;
        }

        .select-service {
          padding: 8px 20px 24px 20px;
          border: 0.62px solid #0000001a;
          box-shadow: 0px 27.2421875px 74.296875px -17.3359375px #0000000a;
          border-radius: 0 4.5px 4.5px 4.5px;

          .service {
            height: 109px;
            width: 109px;
            border: 1px solid #0000001a;
            border-radius: 8px;
          }
        }
      }

      .chatbot-input {
        margin: 20px 15px;
        border: 1.24px solid #1010121a;
        padding: 14px 22px;
        border-radius: 49px;

        ::placeholder {
          color: #00000099;
        }

        .file-attech-btn {
          height: 39px;
          width: 39px;
          border-radius: 50%;
          background-color: $cl-white;
          border: 1.24px solid #0000001a;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .msg-send-btn {
          height: 39px;
          width: 39px;
          border-radius: 50%;
          background-color: $cl-blue;
          border: 1.24px solid #0000001a;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }

  .chatbot-open {
    transform: scale(1);
  }

  .chatbot-btn {
    width: 92px;
    height: 92px;
    background-color: $cl-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px 14px 0px #0000001a;
    margin-top: 17px;
    cursor: pointer;
  }
}

.event-serach-city-list
  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
// event home page media query 26/02/2024
@media (max-width: 767px) {
  .event-slider {
    margin-top: 455px;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .events-hero-bg {
    .input-decoration {
      padding: 11px 16px 11px 20px;
    }
  }
}

@media (max-width: 576px) {
  .events-hero-bg {
    padding: 65px 0;

    .event-city-dropdown {
      width: 177px;
    }

    .event-input {
      width: 322px;
    }
  }
}

.events-price-slider {
  position: relative;

  .events-price-slider-box {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    padding: 15px 30px;
    box-shadow: 2px 2px 6px 0px #00000024;
    border-radius: 10px;

    .MuiSlider-root {
      color: var(--color_blue) !important;
    }
  }
}

// event drawer CSS
.event-drawer-main-div {
  padding: 30px 20px 40px 30px;
  .event-city-drawer-close-icon {
    display: none;
  }
  span.city-name-span.select {
    color: #fff;
    background-color: #31449b;
  }
  .event-city-drawer-close-icon path {
    fill: #000;
  }
  .drawer-tag-line-sub {
    color: orangered;
    font-weight: 600;
  }
  .main-content-div {
    margin-top: 40px;
  }
  div.event-drawer-tag-line {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
    height: 70px;
    padding-top: 30px;
    white-space: nowrap;
  }
  .country-and-city-list-div {
    border: 1px solid #7c7a7a4d;
    border-radius: 15px;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  .country-name-div {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: #000;
    cursor: pointer;
  }
  .country-name-p {
    color: #000;
    font-weight: 600;
  }
  .right-icon-event-drawer path {
    fill: #000;
  }
  .left-icon-event-drawer path {
    fill: #000;
  }
  .city-list-div {
    border-top: 1px solid #7c7a7a4d;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    margin-top: 10px;
  }

  .city-name-span {
    border: none;
    border-radius: 16px;
    padding: 5px 10px;
    margin-right: 3px;
    color: #000;
    margin-bottom: 5px;
    background-color: #85858550;
    height: fit-content;
    cursor: pointer;
  }
  .scroll-note-div {
    color: orangered;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
    text-align: center;
    background-color: #fff;
    height: 40px;
    white-space: nowrap;
  }
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  max-width: 495px !important;
  scrollbar-width: thin;
}

.event-city-list-skeleton {
  border: none;
  border-radius: 16px !important;
}

@media (max-width: 460px) {
  .event-city-drawer-close-icon {
    display: block !important;
  }
}
// event slider media query

@media (max-width: 1312px) {
  .event-slider .event-card .event-img {
    max-width: 245px;
  }
}
@media (max-width: 1200px) {
  .event-slider .event-card .event-img {
    max-width: 300px;
  }
}
@media (max-width: 1199px) {
  .container.event-container-div-slider {
    max-width: 1000px !important;
  }
}
@media (max-width: 1033px) {
  .event-slider {
    margin-right: 20px;
    margin-left: 20px;
  }
  .event-slider .event-card .event-img {
    max-width: 265px;
  }
}
@media (max-width: 940px) {
  .event-slider .event-card .event-img {
    max-width: 250px;
  }
}
@media (max-width: 886px) {
  .event-slider .event-card .event-img {
    max-width: 235px;
  }
}
@media (max-width: 842px) {
  .event-slider .event-card .event-img {
    max-width: 220px;
  }
}
@media (max-width: 794px) {
  .event-slider .event-card .event-img {
    max-width: 208px;
  }
}
@media (max-width: 768px) {
  .event-img {
    max-width: 312px !important;
    max-height: 295px !important;
  }
}

@media (max-width: 575px) {
  .event-slider .col-3.px-2 {
    width: 69% !important;
  }
}

@media (max-width: 440px) {
  .event-slider .col-3.px-2 {
    width: 90% !important;
  }
}
@media (max-width: 343px) {
  .event-slider .col-3.px-2 {
    width: 100% !important;
  }
}
