.hotel-check-in-out-dtls {
  margin: 10px 16px 15px 16px;
  padding: 16px 13px;
  border: 1px solid #0000001a;
  border-radius: 8px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #0000000d;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #0000000d;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
.price-details {
  border-top: 1px solid #0000000d;
}
.p-c-active {
  border-bottom: 2px solid $cl-orange !important;
}

.hotel-transaction-table {
  margin-top: 34px;
  border: 1px solid #0000001a;
  border-radius: 8px;
  overflow: hidden;

  td,
  th {
    border: 1px solid #0000001a;
  }
}

.back-to-home-btn {
  padding: 19px 80px;
  background-color: $cl-blue;
  border-radius: 6px;
  margin-top: 32px;
  &:hover {
    background-color: $cl-orange;
    color: $cl-white !important;
  }
}
@media (max-width: 768px) {
  .back-to-home-btn {
    padding: 14px 44px;
    margin-top: 32px;
  }
  .hotel-transaction-table {
    margin-top: 34px;
    border: 1px solid #0000001a;
    border-radius: 8px;
    overflow: hidden;

    td,
    th {
      border: 1px solid #0000001a;
    }
  }
}
