$blue: #3b5998;
$blue-black: #101012;
$gray-500: #a1a1a1;
$white: #ffffff;

.change-flight-container {
  padding: 1rem;
  width: 1261px;
  margin: auto;

  .b-btn {
    width: 73px;
    background: none;
    border: none;
  }
  .change-flight-header {
    min-height: 0 !important;
  }
  .change-flight-content {
    display: flex;
    gap: 2rem;
    .change-flight-left {
      flex: 1;

      .flight-segment-change,
      .select-new-dates {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 9px;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
        margin-bottom: 30px;
        margin-top: 30px;
        justify-content: space-between;
      }

      .section-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .flight-option {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin-left: 25px;
        .form-check {
          padding-left: 0;
        }
        .form-check-input {
          border: 1px solid $blue;
          border-radius: 0.25rem;
          margin-right: 0.5rem;
          &:checked {
            background-color: $blue;
            border-color: $blue;
          }
        }

        .form-check-label {
          font-size: 1rem;
          font-weight: 500;
          color: $blue-black;
          opacity: 0.7;
          cursor: pointer;

          .flight-date {
            display: block;
            font-size: 0.875rem;
            font-weight: 400;
            color: $blue-black;
            opacity: 0.5;
            margin-top: 0.25rem;
          }
        }
      }

      .date-picker {
        margin-bottom: 1.5rem;
        .form-control {
          border: 1px solid $blue;
          border-radius: 0.25rem;
          padding: 0.5rem 0.75rem;
          font-size: 1rem;
          color: $blue-black;
          width: 40%;
          margin-right: 0.5rem;
        }

        .form-label {
          font-size: 1rem;
          font-weight: 500;
          color: $blue-black;
          opacity: 0.7;
        }
      }

      .search-flights-btn {
        width: 100%;
        background-color: $blue;
        border-color: $blue;
        font-weight: 500;
        width: 151px;
        border-radius: 8px;
        height: 47px;
      }

      .date-input {
        height: 41px;
      }

      .confirm-pay-btn {
        width: 100%;
        background-color: $gray-500;
        border-color: $gray-500;
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        padding: 10px, 20px, 10px, 20px;
        border-radius: 8px;
        margin-top: 10px;
      }

      .confirm-pay-btn-active {
        background-color: $blue;
        border-color: $blue;
        width: 100%;
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        padding: 10px, 20px, 10px, 20px;
        border-radius: 8px;
        margin-top: 10px;
      }

      .div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
      }

      .div-11 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 20px;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
        background-color: white;
        border-radius: 8px;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
      }

      .text-wrapper-13 {
        position: relative;
        align-self: stretch;
        margin-top: -1px;
        font-family: 'Inter', Helvetica;
        font-weight: 700;
        color: var(--blue-black);
        font-size: 18px;
        letter-spacing: 0;
        line-height: normal;
      }

      .vector-3 {
        position: relative;
        align-self: stretch;
        width: 100%;
        height: 2px;
        margin-top: -1px;
      }

      .div-12 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        align-self: stretch;
        width: 100%;
        flex: 0 0 auto;
      }

      .div-13 {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        flex: 0 0 auto;
      }

      .text-wrapper-14 {
        position: relative;
        width: fit-content;
        margin-top: -1.33px;
        opacity: 0.7;
        font-family: 'Poppins', Helvetica;
        font-weight: 500;
        color: var(--blue-black);
        font-size: 17.3px;
        letter-spacing: 0;
        line-height: normal;
      }

      .text-wrapper-15 {
        position: relative;
        width: fit-content;
        font-family: 'Poppins', Helvetica;
        font-weight: 500;
        color: var(--blue-black);
        font-size: 20px;
        letter-spacing: 0;
        line-height: normal;
      }

      .chevron-right {
        position: relative;
        width: 18px;
        height: 18px;
      }
    }

    .change-flight-right {
      .total-summary {
        border-radius: 0.25rem;
        padding: 1.5rem;
        background-color: #ffffff;
        min-width: 300px;
        border-radius: 9px;
        box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.0588235294);
        .section-title {
          font-size: 1.125rem;
          font-weight: 500;
          color: $blue-black;
          margin-bottom: 1rem;
        }
        .rp-blue {
          color: #31449b;
          font-size: 14px;
          font-weight: 600;
        }
        .dashed-line {
          border: none;
          border-top: 1px dotted black;
          color: #fff;
          background-color: #fff;
          height: 1px;
          width: 100%;
        }

        .total-amount {
          display: flex;
          justify-content: space-between;
          font-size: 1.125rem;
          font-weight: 500;
          color: $blue-black;
        }
      }
    }
  }
}
