.section {
  display: flex;
  flex-direction: column;
  width: 984px;
  align-items: flex-start;
  gap: 24px;
  position: relative;
}

.section .header {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Inter', Helvetica;
  font-weight: 700;
  color: var(--blue-black);
  font-size: 24.1px;
  letter-spacing: 0;
  line-height: normal;
}

.section .noticeError {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ea33331a;
  border-radius: 8px;
  border: 1px solid;
  border-color: #ea333380;
}

.section .notice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: rgba(51, 234, 51, 0.4);
  border-radius: 8px;
  border: 1px solid;
  border-color: green;
}

.section .text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.section .exclamation {
  position: relative;
  width: 24px;
  height: 24px;
}

.section .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Inter', Helvetica;
  font-weight: 700;
  color: var(--red);
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.section .supporting-text {
  position: relative;
  align-self: stretch;
  font-family: 'Inter', Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: normal;
}

.section .div {
  position: relative;
  align-self: stretch;
  font-family: 'Inter', Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.section .loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.section .button {
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: #31449b;
  border-radius: 8px;
  border: 0px none;
  border-color: #31449b;
}

.section .text-2 {
  position: relative;
  width: fit-content;
  font-family: 'Inter', Helvetica;
  font-weight: 600;
  color: var(--white);
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
