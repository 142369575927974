.flight-confirm-vh {
  min-height: 0vh;
}
.flight-booking-confirmation-dtls {
  .flight-booking-conf {
    border: 2px solid #0000001a;
    padding: 26px 22px;
    border-radius: 8px;
    margin-top: 38px;
  }

  .flight-logo-name {
    all: unset !important;

    &::after {
      display: none;
    }
  }

  .flight-information {
    border: 2px solid #0000001a;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 8px;
    padding-top: 10px;
    padding-left: 11px;
  }

  .width-219 {
    width: 219px;
  }

  .cancel-booking {
    padding: 8px 40px;
    border: 1px solid #31449b;
    border-radius: 6px;
  }

  .border-top-gray {
    padding: 13px 0;
    border-top: 2px solid #0000000d;
  }

  .flight-times {
    width: 66%;
  }

  .depart-btn {
    padding: 6px 10px;
    background-color: #31449b;
    border-radius: 4px;
  }
}

.border-right-gray {
  border-right: 1px solid #0000000d;
}

.flight-time-price .flight-shadules .flight-price-time {
  margin-right: 0;
}

@media (max-width: 992px) {
  .flight-booking-confirmation-dtls {
    .flight-times {
      width: 100% !important;
    }

    .flight-logo {
      width: 100px !important;
    }
  }
}

@media (min-width: 992px) {
  .flight-booking-confirmation-dtls {
    .white-space-md-nowrap {
      white-space: nowrap !important;
    }
  }
}
