#flight-search-result {
  .body-overlay-color {
    background-color: rgba(0, 0, 0, 0.71);
    min-height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 3;
  }

  .hero-bg-blue {
    background-color: $cl-blue;
    width: 100%;
    height: 320px;
  }

  .add-flight-btn {
    padding: 8px 13px;
    border: 1px solid $cl-white;
    border-radius: 7px;
  }
  .add-flight-btn:hover {
    border: none;
    background-color: $cl-orange;
  }

  .flight-book-form {
    background-color: $cl-white;
    padding: 28px 17px 42px 21px;
    box-shadow: 3px 4px 26px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    .tablinks {
      border: none;
      outline: none;
      background-color: transparent;

      input[type='radio'] {
        width: 15px;
        height: 15px;
        accent-color: $cl-blue;
      }

      &.active {
        label {
          color: $cl-blue;
          font-weight: 600;
          opacity: 100%;
        }
      }
    }

    .flight-route-dropdown {
      position: absolute;
      background-color: $cl-white;
      padding: 15px 10px;
      border: 1px solid #00000022;
      z-index: 1;
      border-radius: 5px;
      top: 30px;
      display: none;
    }

    .economy {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      background-image: url('../../../../public/Assets/icons/drop-down-arrow.svg');
      background-repeat: no-repeat;
      background-position: right center;
      width: 113px;
    }

    .adult {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      outline: none;
      background-image: url('../../../../public/Assets/icons/drop-down-arrow.svg');
      background-repeat: no-repeat;
      background-position: right center;
      width: 81px;
    }

    input[type='date'] {
      & ::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    #multicity1 {
      .w-49 {
        width: 49% !important;
      }
    }

    .flight-search-btn {
      padding: 18px 50px;
      border-radius: 6px;
      background-color: $cl-blue;
      border: none;
      outline: none;
      transition: 0.4s;

      &:hover {
        background-color: $cl-orange;
      }
    }

    .switch-icon {
      top: 10px;
    }
  }
}
.search-slot-close-btn {
  padding: 5px;
  border-radius: 50%;
  background-color: rgba(191, 189, 189, 0.612);
  margin-top: 0px;
  margin-left: -10px;
  margin-right: -10px;
}
.sider-filters .depart-arrival-time {
  border: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
  padding: 5px 5px;
  width: 100%;
}
.color-green {
  color: #4be55b;
}
.sider-filters {
  border: 1px solid #0000001a;
  padding: 22px 16px 31px 11px;
  border-radius: 8px;
  transition: all linear;
  position: relative;
  // top: 100px;
  max-height: 99vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $cl-orange #f4f4f4;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $cl-orange;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f4f4f4;
  }

  .filter-hide-btn {
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  .clear-btn {
    all: unset;
    color: $cl-blue;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: $cl-orange;
    }
  }

  .depart-arrival-time {
    border: 1px solid #0000001a;
    border-radius: 6px;
    padding: 5px 5px;
    width: 100%;
  }

  .flight-price-range {
    background: #dee2e6;
    border-radius: 28px;
    height: 2.72px;
    width: 100%;
    outline: none;
    transition: 450ms ease-in;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      width: 9px;
      -webkit-appearance: none;
      height: 9px;
      border-radius: 50%;
      background: $cl-blue;
    }
  }
}

.other-classes {
  color: #000000;
  background-color: #f4f4f4;
  border-left: 3px solid $cl-orange;
}
.select-btn-active {
  background-color: $cl-orange !important;
  color: $cl-white !important;
}
.all-air-lines {
  border: 1px solid #00000021;
  padding: 8.5px 16px;
  border-radius: 5px;
}
.border-top-dashed {
  border-top: 3px dashed #0000000d; /* Adjust color and size as needed */
}

.flight-time-price {
  .flight-count {
    position: absolute;
    right: -14px;
    top: -8px;
  }
  .tab-heading {
    background-color: $cl-white;
    z-index: 0;

    h3 {
      background-color: $cl-blue;
      border-radius: 28px 28px 0px 0px;
      padding: 27px 31px;
    }

    .price-tabs {
      padding: 15px 0;

      .sort-drop-down {
        position: absolute;
        z-index: 1;
        width: 100%;
        background-color: $cl-white;
        top: 80px;
        border: 1px solid #00000012;
        border-radius: 5px;
        display: none;
      }

      .price-tab-border-bottom {
        border-bottom: 1px solid #0000000d;
      }

      .price-tab {
        border: 0;
        outline: 0;
        background-color: transparent;
        padding: 10px;
      }

      .sortby-dropdown {
        padding: 10px;
      }

      .price-alert-btn {
        padding: 10px;
      }
    }
  }
  .flight-shadules-vh {
    min-height: 73vh !important;
  }
  .flight-result-scroll {
    margin-top: 25px;
    overflow-y: auto;
  }

  .flight-result-scroll::-webkit-scrollbar {
    width: 10px;
  }

  .flight-result-scroll::-webkit-scrollbar-thumb {
    height: 8rem;
    border-radius: 0.5rem;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: #31449b;

    &:hover {
      background-color: #fda400;
    }
  }
  .flight-shadules {
    .border-top-gray {
      border-top: 1px solid #0000001a;
    }
    .flight-price-time {
      border: 1px solid #0000001a;
      border-radius: 8px;
      margin-right: 10px;
      .layover-time {
        border-top: 1px solid #0000001a;
        border-bottom: 1px solid #0000001a;
      }
      .flight-logo {
        padding: 20px 20px 30px 20px;
        border-right: 1px solid #0000000d;
      }
      .flight-logo-tiny {
        width: 28px;
        height: 28px;
      }
      .flight-logo-name {
        // width: 190px;
        height: 100%;
        // height: 160px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 1px;
          height: 80%;
          background-color: #0000000d;
        }
      }
      .w-90 {
        width: 88%;
      }
      .w-full {
        width: 100%;
      }
      .flight-times {
        // width: 76%;
      }
      .border-left-dashed {
        border-left: 3px dashed #0000000d; /* Adjust color and size as needed */
      }

      // .flight-count {
      //   position: absolute;
      //   right: -16px;
      //   top: -8px;
      // }

      .flight-start-stop-line {
        width: 295px;
        position: relative;

        .stop-point {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          z-index: 1;

          span {
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: $cl-orange;
            display: inline-block;
          }
        }

        .duration {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }
        .flight-name {
          position: absolute;
          top: 50px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }

        .plan-takeoff-icon {
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }

        .plain-takeoff-icon {
          width: 20px;
          height: 20px;
        }

        .strate-icon {
          transform: translateX(-50%) rotate(30deg);
        }
        .flight-start-end-line {
          border-top: 2px dotted #0000000d;
          width: calc(100% - 26px);
          display: inline-block;
        }

        .start-point {
          height: 13px;
          width: 13px;
          border-radius: 100%;
          border: 1px solid $cl-blue;
          background-color: $cl-white;
        }

        .end-point {
          height: 13px;
          width: 13px;
          border-radius: 100%;
          background-color: $cl-blue;
          border: 1px solid $cl-blue;
        }
      }
      .select-btn {
        padding: 70px 20px;
        border-radius: 0px 8px 8px 0px;
        background-color: $cl-white;
        border: none;
        border-left: 1px solid #00000019;
      }
      .less-seats {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        color: #fff;
        padding: 5px 10px;
        width: 100%;
      }
      .flights-name-dtls {
        width: 36%;
      }
      .view-flight-dtls {
        display: none;
      }
    }
  }
  .flight-plans {
    background-color: #f0f0f0;
    padding: 17px 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: none;
    margin-right: 10px;
    box-shadow: 3px 4px 26px 0px rgba(0, 0, 0, 0.05);
    .flight-plans-tab-btn {
      border: none;
      outline: none;
      padding-bottom: 10px;
      background-color: transparent;
      border-bottom: 2px solid #00000012;
      &.active {
        border-bottom: 2px solid $cl-orange;
      }
    }
    .flight-plan-content {
      .flight-plan {
        padding: 17px 12px 14px 12px;
        border: 1px solid #00000030;
        border-radius: 8px;
        background-color: $cl-white;
        height: 100%;

        .plan-price {
          border-top: 1px solid #00000029;
          padding: 9px 0;
          margin-top: 18px;
        }
        .flight-plan-select-btn {
          background-color: $cl-blue;
          padding: 12px 10px;
          border-radius: 6px;
          width: 100%;
          &:hover {
            background-color: $cl-orange;
            color: $cl-white !important;
          }
        }
      }
    }
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .flight-time-price {
    .tab-heading {
      .price-tabs {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .flight-time-price {
    .flight-shadules {
      .flight-price-time {
        .w-90 {
          width: 86%;
        }
        .flight-times {
          // width: 76%;
        }

        .flight-start-stop-line {
          width: 195px;
        }
      }
      .flights-name-dtls {
        width: 39% !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .sider-filters {
    min-width: 335px;
    position: fixed;
    min-height: 100vh;
    top: 0;
    left: -100%;
    transition: 0.4s;
    background-color: $cl-white;
    z-index: 4;
  }

  .flight-time-price {
    .tab-heading {
      .filters-show-btn {
        border: 1px solid rgba(0, 0, 0, 0.1019607843);
        padding: 10px 50px;
        border-radius: 7px;
      }
    }

    .flight-shadules {
      .flight-price-time {
        .w-90 {
          width: 89%;
        }
        .flight-times {
          // width: 76%;
        }
        .flight-start-stop-line {
          // width: 270px;
          width: 310px;
        }
        .flight-plans {
          width: 92%;
          .flight-plan-select-btn {
            padding: 13px 10px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  #flight-search-result {
    .flight-book-form {
      .flight-search-btn {
        padding: 18px 44px;
      }

      #multicity1 {
        .w-49 {
          width: 100% !important;
        }
      }
    }
  }

  .flight-time-price {
    .tab-heading {
      h3 {
        background-color: $cl-blue;
        border-radius: 28px 28px 0px 0px;
        padding: 27px 31px;
      }

      .price-tabs {
        padding: 15px 0;

        .sort-drop-down {
          top: 78px;
        }

        .filters-show-btn {
          border: 0;
          padding: 10px 50px;
          border-radius: 7px;
          width: 50%;
        }

        .width-50 {
          width: 50%;
        }

        .border-right {
          border-right: 1px solid #0000000d;
        }

        .price-tab-show {
          width: 50%;
          margin: 0 auto;
        }

        .price-tab {
          border: 0;
          outline: 0;
          background-color: transparent;
          padding: 10px;
        }

        .sortby-dropdown {
          padding: 10px;
          border-right: 1px solid #0000000d;
        }

        .price-alert-btn {
          padding: 10px;
        }
      }
    }

    .flight-shadules {
      .flight-price-time {
        .w-90 {
          width: 100%;
        }
        .p-20 {
          padding: 20px;
        }
        .px-10 {
          padding: 0 10px;
        }
        .flight-logo-name {
          position: relative;
          &::after {
            height: 80%;
          }
        }
        .flight-times {
          // width: 76%;
        }

        .flight-start-stop-line {
          width: 155px;
        }

        .flights-name-dtls {
          width: 23% !important;
        }
      }
    }
    .flignt-plan-card-none {
      display: none;
    }
    .flight-plans {
      .flight-plan {
        .flight-plan-content {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #flight-search-result {
    .flight-book-form {
      .switch-icon {
        right: -60px;
        left: unset;
        transform: translateX(-50%) rotate(0);
      }
    }
  }

  .flight-search-btn {
    width: 100%;
  }

  .flight-time-price {
    .tab-heading {
      .price-tabs {
        .filters-show-btn {
          border: 0;
          padding: 0;
          border-radius: 7px;
          width: 50%;
        }
      }
    }

    .flight-shadules {
      .flight-price-time {
        .px-10 {
          padding: 0 20px;
        }
        .flight-logo {
          border: 0;
          padding: 0;
          width: 40px;
        }
        .flight-logo-name {
          align-items: start !important;
          &::after {
            display: none;
          }
        }
        .flight-times {
          width: 100%;
        }

        .flight-start-stop-line {
          width: 140px;
        }

        .price-width {
          width: 100%;
        }
        .flights-name-dtls {
          width: 100% !important;
        }

        .flight-count {
          // right: -8px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .sider-filters {
    min-width: 290px;
  }

  #flight-search-result {
    .flight-book-form {
      .switch-icon {
        top: 13px;
        right: -53px;
        left: unset;
        width: 31px;
        height: 31px;
      }

      button {
        label {
          font-size: 12px;
        }
      }

      .economy {
        font-size: 12px;
        width: 87px;

        option {
          font-size: 12px;
        }
      }

      .adult {
        font-size: 12px;
        width: 64px;

        option {
          font-size: 12px;
        }
      }
    }
  }

  .flight-time-price {
    .tab-heading {
      h3 {
        background-color: $cl-blue;
        border-radius: 12px 12px 0px 0px;
        padding: 15px 27px;
        font-size: 18px;
      }

      .price-tabs {
        padding: 0;

        .sort-drop-down {
          top: 62px;
        }
      }
    }

    .flight-shadules {
      .flight-price-time {
        .flight-logo {
          width: 40px;
        }

        .flight-times {
          width: 100%;
        }

        .flight-start-stop-line {
          width: min(130px, 100%);
        }

        .price-width {
          width: 100%;
        }
        .flight-plans {
          width: 97%;
          padding: 15px 10px;
          .flight-plan-select-btn {
            padding: 13px 10px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .tab-heading {
    h3 {
      background-color: $cl-blue;
      border-radius: 28px 28px 0px 0px;
      padding: 27px 31px;
    }

    .price-tabs {
      padding: 15px 0;

      .price-tab-border-bottom {
        border-bottom: 1px solid #0000000d;
      }

      .price-tab {
        border: 0;
        outline: 0;
        background-color: transparent;
        border-right: 1px solid #0000000d;
        padding: 10px;
        width: 21%;
      }

      .sortby-dropdown {
        padding: 10px 20px;
        border-right: 1px solid #0000000d;
      }

      .price-alert-btn {
        padding: 10px 20px;
      }
    }
  }
}

@media (min-width: 1400px) {
  #flight-search-result {
    .flight-book-form {
      .w-71 {
        width: 71%;
      }
    }
  }
}

.table {
  box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px 0px !important;
}

@media screen and (max-width: 767px) {
  table tbody tr {
    border-bottom: 1px solid #000000;
    margin-bottom: 0px !important;
    box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px 0px !important;
  }
}
