* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cst-support-main-container {
  background-color: #fbfbfd;
}

:root {
  --primary1: #31449b;
  --primary2: #fda400;
  --white: #fff;
}

.cst-support-hero-container {
  background-color: var(--primary1);
}

.cst-support-hero {
  display: flex;
  gap: 5%;
  width: 67%;
  margin: 0 auto;
  justify-content: center;
  padding: 4% 0 0.2% 0;
}

.cst-support-hero-title {
  font-size: 58px;
  color: var(--white);
}
.cst-support-hero-sub-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: var(--white);
  margin-top: 3%;
}
.cst-support-hero-contact-all-data-container {
  background-color: var(--primary2);
}

.cst-support-hero-contact-container {
  width: 55%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cst-support-hero-contact {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 30px 0 30px 0;
}

.cst-support-hero-contact-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
}
.cst-support-hero-contact-line {
  background-color: var(--white);
  width: 2px;
  height: 60.703px;
}

.cst-support-tabs-container {
  width: 69%;
  margin: 0 auto;
  color: #101012;
}
