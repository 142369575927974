.overlay {
  position: fixed;
  width: 0;
  height: 100%;
  background-color: #000000b5;
  z-index: 2;
}

.user-profile-and-another-dtls {
  border: 1px solid #1010121a;
  border-radius: 8px;
  padding: 31px 0px 31px 28px;
  margin-top: 32px;

  .side-tab-btn {
    border-right: 1px solid #10101214;
    padding: 0 42px 0 0;

    a {
      padding: 16px 37px;
      border-radius: 46px;
      margin-top: 21px;

      &:hover {
        background-color: #fda4001c;
        opacity: 100%;
        font-weight: 700;
      }
    }

    .sign-out-btn {
      width: 100%;
      border-top: 1px solid #10101214;
      padding: 28px 0 16px 0;
      margin-top: 0;
      border-radius: 0;

      p {
        color: #fd2e00;
        padding-left: 37px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  .side-tab-content {
    padding-left: 20px;

    .profile {
      padding-right: 17px;

      .user-profile {
        border: 1px solid #1010121a;
        border-radius: 8px;
        padding: 28px 19px;
        margin-top: 22px;
        position: relative;
        .profile-pic {
          border-radius: 50%;
          width: 85px;
          height: 85px;
          object-fit: cover;
        }

        .edit-profile-pic {
          width: 436px;
          border-radius: 8px;
          background-color: $cl-white;
          padding: 28px 0;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          display: none;

          .close-btn {
            position: absolute;
            top: 24px;
            right: 22px;
          }

          .change-profile-pic {
            border-radius: 50%;
          }

          .change-profile-btn {
            padding: 13px 27px;
            margin-top: 4px;
          }

          .save-btn {
            padding: 13px 67px;
            background-color: $cl-blue;
            border-radius: 8px;
            margin-top: 17px;
          }
        }

        .user-level {
          width: 96px;
          height: 35px;
          // p {
          //   padding: 0 17px 0 8px;
          // }

          // img {
          //   padding: 0 2px 0 2px;
          // }
        }

        .edit-btn {
          padding: 12px 27px;
          border-radius: 8px;
          border: 1px solid #0000001c;
          position: absolute;
          top: 13px;
          right: 26px;
        }

        .mail-id {
          padding-right: 43px;
          margin-right: 43px;
          border-right: 1px solid #0000001c;
        }

        .phn-number {
          padding-right: 43px;
          margin-right: 43px;
          border-right: 1px solid #0000001c;
        }
      }

      .persnal-info {
        padding: 13px 26px 65px 23px;
        border: 1px solid #1010121a;
        border-radius: 8px;
        margin-top: 13px;

        // ::placeholder {
        //   color: red;
        // }
        .cancel-btn {
          border: 1px solid #0000001c;
          padding: 13px 27px;
          border-radius: 8px;
        }

        .save-btn {
          padding: 13px 27px;
          border-radius: 8px;
          background-color: $cl-blue;
          margin-left: 9px;
        }

        .edit-btn {
          border: 1px solid #0000001c;
          padding: 13px 27px;
          border-radius: 8px;
        }
      }

      .persnal-info-editable {
        ::placeholder {
          color: $cl-blue-black;
          opacity: 70%;
        }

        .phn-number {
          select {
            border: none;
            outline: none;
            width: 80px;
          }
        }
      }

      .explrar-point-balance {
        padding: 45px 28px 45px 33px;
        border: 1px solid #1010121a;
        border-radius: 8px;
        margin-top: 13px;
      }

      .color-red {
        color: #fd2e00;
      }
    }

    .my-trip {
      .my-trip-height {
        max-height: 762px;
        overflow-y: scroll;
        padding-right: 14px;
        margin-top: 34px;

        .border-gray {
          border: 1px solid #1010121a;
          padding: 27px 24px 18px 33px;
          border-radius: 8px;

          .share-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 8px 13px;
            border: 1px solid $cl-orange;
            border-radius: 7px;
          }

          .print-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 8px 13px;
            border: 1px solid $cl-orange;
            border-radius: 7px;
          }

          .delete-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
          }

          .dropdown-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
          }

          .trip-top-card {
            background-color: $cl-white;
            box-shadow: 4.301164627075195px 2.8674428462982178px
              14.337214469909668px 0px #0000001a;
            width: 100%;
            padding: 19px;
            border-radius: 15px;
          }

          .hotels-cards {
            padding: 11px 21px 9px 20px;
            background: $cl-white;
            box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            max-height: 335px;
            overflow-y: scroll;

            .hotels {
              border-radius: 20px 20px 0 0;
              overflow: hidden;
            }
          }

          .flight-block {
            height: 361px;
            padding: 30px 24px;
            border-radius: 14px;
            border: 3px solid $cl-white;
            background: $cl-white;
            box-shadow: 6px 4px 20px 0px rgba(0, 0, 0, 0.1);
          }

          .flight-lists {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 35px;

            .flight-list {
              position: relative;

              .stops-position {
                position: absolute;
                bottom: 0;
                top: 64%;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);

                .stops {
                  position: relative;

                  &::after {
                    content: '';
                    height: 1px;
                    width: 100%;
                    border-top: 2px dotted $cl-blue;
                    position: absolute;
                    top: -13.8px;
                  }

                  .paln-takeoff {
                    position: absolute;
                    left: -2px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    border: 1px solid $cl-blue;
                    top: -20px;
                    z-index: 1;
                    background-color: $cl-white;
                  }

                  .paln-land {
                    position: absolute;
                    right: -2px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    border: 1px solid $cl-blue;
                    top: -20px;
                    z-index: 1;
                    background-color: $cl-blue;
                  }

                  .first-stop {
                    height: 13px;
                    width: 13px;
                    border-radius: 50%;
                    background-color: $cl-orange;
                    display: inline-block;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                  }

                  .second-stop {
                    height: 13px;
                    width: 13px;
                    border-radius: 50%;
                    background-color: $cl-orange;
                    display: inline-block;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                  }
                }
              }

              .no-stops-position {
                position: absolute;
                bottom: 0;
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
                top: 64%;

                .stops {
                  position: relative;

                  &::after {
                    content: '';
                    height: 1px;
                    width: 100%;
                    border-top: 2px dotted $cl-blue;
                    position: absolute;
                    top: -13.8px;
                  }

                  .paln-takeoff {
                    position: absolute;
                    left: -2px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    border: 1px solid $cl-blue;
                    top: -20px;
                    z-index: 1;
                    background-color: $cl-white;
                  }

                  .paln-land {
                    position: absolute;
                    right: -2px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    border: 1px solid $cl-blue;
                    top: -20px;
                    z-index: 1;
                    background-color: $cl-blue;
                  }
                }
              }
            }
          }
        }

        .total-trip {
          border: 1px solid #1010121a;
          padding: 24px 24px 24px 33px;
          border-radius: 8px;

          .share-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 8px 13px;
            border: 1px solid $cl-orange;
            border-radius: 7px;
          }

          .print-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
            padding: 8px 13px;
            border: 1px solid $cl-orange;
            border-radius: 7px;
          }

          .delete-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
          }

          .dropdown-btn {
            border: 0;
            outline: 0;
            background-color: transparent;
          }
        }
      }
    }

    .manage-booking {
      .manage-booking-hight {
        max-height: 762px;
        overflow-y: scroll;
        padding-right: 28px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $cl-orange;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f4f4f4;
        }

        .manage-booking-tabs {
          .manage-booking-tab {
            border: 0;
            border: 1px solid #31449b;
            background-color: transparent;
            outline: 0;
            border-radius: 4px;
            width: 100%;
            padding: 4px;
          }
        }

        .flight-ticket-manage {
          box-shadow: 3px 4px 26px 0px rgba(211, 211, 211, 0.5);
          background-color: #ffffff;
          padding: 10px;
          border-radius: 8px;
          margin-top: 36px;
          border: 1px solid #e2e2e2;

          .ticket-issued-tag {
            padding: 5px 15px;
            background-color: #fda40033;
          }

          .ticket-completed-tag {
            padding: 5px 15px;
            background-color: #31449b47;
          }

          .flight-takeoff-icon {
            margin-top: -23px;
          }

          .border-right-gray {
            border-right: 1px solid #0000000d;
          }

          .border-left-gray {
            border-left: 1px solid #0000000d;
          }

          .height-100 {
            height: 100px;
          }

          .border-bottom-ligth-gray {
            border-bottom: 2px solid #0000000d;
          }

          .dotted-line {
            border-top: 2px dashed #0000000d;
            width: 97px;
            margin: 0 20px;
            position: relative;
            margin-top: -13px;

            &::after {
              content: '';
              position: absolute;
              width: 7px;
              height: 7px;
              left: 0;
              top: -4px;
              border: 1px solid #31449b;
              border-radius: 50%;
            }

            &::before {
              content: '';
              position: absolute;
              width: 7px;
              height: 7px;
              right: 0;
              top: -4px;
              background-color: $cl-blue;
              border-radius: 50%;
            }
          }

          .change-btn {
            border: 0;
            outline: 0;
            padding: 10px 20px;
            background-color: $cl-orange;
            border-radius: 8px;
          }

          .cancel-btn {
            border: 0;
            outline: 0;
            padding: 10px 20px;
            background-color: $cl-blue;
            border-radius: 8px;
          }
        }

        .hotel-booking-manage {
          box-shadow: 3px 4px 26px 0px rgb(211 211 211 / 50%);
          background-color: #ffffff;
          padding: 10px;
          border-radius: 8px;
          margin-top: 36px;
          border: 1px solid #e2e2e2;

          .cancel-btn {
            border: 0;
            outline: 0;
            padding: 10px 20px;
            background-color: $cl-blue;
            border-radius: 8px;
          }

          .dotted-line {
            border-top: 2px dashed #0000000d;
            width: 97px;
            margin: 0 20px;
            position: relative;
            margin-top: -13px;

            &::after {
              content: '';
              position: absolute;
              width: 7px;
              height: 7px;
              left: 0;
              top: -4px;
              border: 1px solid #31449b;
              border-radius: 50%;
            }

            &::before {
              content: '';
              position: absolute;
              width: 7px;
              height: 7px;
              right: 0;
              top: -4px;
              background-color: $cl-blue;
              border-radius: 50%;
            }
          }

          .border-right-gray {
            border-right: 1px solid #0000000d;
          }

          .border-left-gray {
            border-left: 1px solid #0000000d;
          }

          .height-100 {
            height: 100px;
          }

          .border-bottom-ligth-gray {
            border-bottom: 2px solid #0000000d;
          }

          .ticket-issued-tag {
            padding: 5px 15px;
            background-color: #fda40033;
          }
        }
      }
    }

    .elite-explrar-tiers {
      margin-top: -31px;

      .plr-28-12 {
        padding: 0 12px 20px 28px;
      }

      .tier-and-progress {
        max-height: 762px;
        overflow-y: scroll;
        margin-left: -31px;
        margin-right: -12px;
        margin-top: 0px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $cl-orange;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f4f4f4;
        }

        .explrar-point-bronze {
          background: linear-gradient(
            180deg,
            rgba(205, 127, 47, 0.3) 35.61%,
            rgba(255, 255, 255, 0.3) 102.08%
          );
          margin: 0px;
          padding: 0;
          padding-bottom: 40px;
          border-radius: 0;
          box-shadow: unset;
          .color-bronze {
            color: #cd7f2f;
          }
        }
        .explrar-point-silver {
          background: linear-gradient(
            180deg,
            rgba(191, 194, 201, 0.644) 35.61%,
            rgba(255, 255, 255, 0.3) 102.08%
          );
          margin: 0px;
          padding: 0;
          padding-bottom: 40px;
          border-radius: 0;
          box-shadow: unset;
          .color-bronze {
            color: #101012;
          }
        }
        .explrar-point-gold {
          background: linear-gradient(
            180deg,
            rgba(246, 189, 52, 0.3) 35.61%,
            rgba(255, 255, 255, 0.3) 102.08%
          );
          margin: 0px;
          padding: 0;
          padding-bottom: 40px;
          border-radius: 0;
          box-shadow: unset;
          .color-bronze {
            color: #000000;
          }
        }
        .explrar-point-platinum {
          background: linear-gradient(
            180deg,
            rgba(107, 119, 122, 0.3) 35.61%,
            rgba(255, 255, 255, 0.3) 102.08%
          );
          margin: 0px;
          padding: 0;
          padding-bottom: 40px;
          border-radius: 0;
          box-shadow: unset;
          .color-bronze {
            color: #101012;
          }
        }

        .current-tier {
          padding: 40px 15px;
          background-color: $cl-blue;
          border-radius: 8px;
          height: 100%;
        }

        .valid-until {
          padding: 40px 15px;
          background-color: $cl-orange;
          border-radius: 8px;
          height: 100%;
        }

        .tier-point {
          padding: 40px 15px;
          background-color: #33e12f;
          border-radius: 8px;
          height: 100%;
        }

        .my-progress {
          border: 1px solid #1010121a;
          border-radius: 8px;
          padding: 26px 21px 72px 21px;
          margin-top: 37px;

          .tiers {
            .tier-type {
              position: relative;
              z-index: 1;
              margin-top: -45px;

              // &::after {
              //   content: '';
              //   height: 19px;
              //   width: 100%;
              //   top: 17px;
              //   position: absolute;
              //   background-color: #1010120d;
              //   z-index: -1;
              // }
            }

            .tier-points {
              background-image: url('../../../../public/Assets/icons/tier-point-bg.svg');
              padding: 11px 36px 25px 36px;
              display: inline-block;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }

        .tier-point-dtls {
          margin-top: 15px;

          table {
            border-collapse: separate;
            border-spacing: 0;

            .border-top-left-rds-8 {
              border-top-left-radius: 8px;
            }

            .border-top-right-rds-8 {
              border-top-right-radius: 8px;
            }

            .border-bottom-right-rds-8 {
              border-bottom-right-radius: 8px;
            }

            .border-bottom-left-rds-8 {
              border-bottom-left-radius: 8px;
            }

            tbody,
            td,
            tfoot,
            th,
            thead,
            tr {
              border: 1px solid #1010121a;
            }

            th {
              padding: 23px 10px 20px 10px;
            }

            td {
              height: 82px;
              padding: 6px 10px;
            }
          }
        }
      }
    }

    // .elite-explrar-tiers {
    //   margin-top: -31px;
    //   .plr-28-12 {
    //     padding: 0 12px 20px 28px;
    //   }
    //   .tier-and-progress {
    //     max-height: 762px;
    //     overflow-y: scroll;
    //     margin-left: -31px;
    //     margin-right: -12px;
    //     margin-top: 0px;
    //     &::-webkit-scrollbar {
    //       width: 6px;
    //     }
    //     &::-webkit-scrollbar-thumb {
    //       background-color: $cl-orange;
    //       border-radius: 6px;
    //     }
    //     &::-webkit-scrollbar-track {
    //       background: #f4f4f4;
    //     }
    //     .explrar-point-bronze {
    //       background: linear-gradient(
    //         180deg,
    //         rgba(205, 127, 47, 0.3) 35.61%,
    //         rgba(255, 255, 255, 0.3) 102.08%
    //       );
    //       margin: 0px;
    //       padding: 0;
    //       padding-bottom: 40px;
    //       border-radius: 0;
    //       box-shadow: unset;
    //       .color-bronze {
    //         color: #cd7f2f;
    //       }
    //     }
    //     .explrar-point-silver {
    //       background: linear-gradient(
    //         180deg,
    //         rgba(191, 194, 201, 0.644) 35.61%,
    //         rgba(255, 255, 255, 0.3) 102.08%
    //       );
    //       margin: 0px;
    //       padding: 0;
    //       padding-bottom: 40px;
    //       border-radius: 0;
    //       box-shadow: unset;
    //       .color-bronze {
    //         color: #101012;
    //       }
    //     }
    //     .explrar-point-gold {
    //       background: linear-gradient(
    //         180deg,
    //         rgba(246, 189, 52, 0.3) 35.61%,
    //         rgba(255, 255, 255, 0.3) 102.08%
    //       );
    //       margin: 0px;
    //       padding: 0;
    //       padding-bottom: 40px;
    //       border-radius: 0;
    //       box-shadow: unset;
    //       .color-bronze {
    //         color: #000000;
    //       }
    //     }
    //     .explrar-point-platinum {
    //       background: linear-gradient(
    //         180deg,
    //         rgba(107, 119, 122, 0.3) 35.61%,
    //         rgba(255, 255, 255, 0.3) 102.08%
    //       );
    //       margin: 0px;
    //       padding: 0;
    //       padding-bottom: 40px;
    //       border-radius: 0;
    //       box-shadow: unset;
    //       .color-bronze {
    //         color: #101012;
    //       }
    //     }
    //     .current-tier {
    //       padding: 40px 15px;
    //       background-color: $cl-blue;
    //       border-radius: 8px;
    //       height: 100%;
    //     }
    //     .valid-until {
    //       padding: 40px 15px;
    //       background-color: $cl-orange;
    //       border-radius: 8px;
    //       height: 100%;
    //     }
    //     .tier-point {
    //       padding: 40px 15px;
    //       background-color: #33e12f;
    //       border-radius: 8px;
    //       height: 100%;
    //     }
    //     .my-progress {
    //       border: 1px solid #1010121a;
    //       border-radius: 8px;
    //       padding: 26px 21px 72px 21px;
    //       margin-top: 37px;
    //       .tiers {
    //         .tier-type {
    //           position: relative;
    //           z-index: 1;
    //           &::after {
    //             content: '';
    //             display: block;
    //             position: absolute;
    //             top: -50px; /* Adjust as necessary */
    //             left: 0;
    //             width: 100%;
    //             height: 20px; /* Height of the icon container */
    //             background: none;
    //             z-index: -1;
    //           }
    //           .icon {
    //             position: absolute;
    //             top: -50px; /* Adjust as necessary */
    //             width: 224px;
    //             text-align: start;
    //           }
    //           .icon:nth-child(1) {
    //             left: 0%;
    //           }
    //           .icon:nth-child(2) {
    //             left: 25%;
    //           }
    //           .icon:nth-child(3) {
    //             left: 50%;
    //           }
    //           .icon:nth-child(4) {
    //             left: 75%;
    //           }
    //         }
    //         .tier-points {
    //           background-image: url('../../../../public/Assets/icons/tier-point-bg.svg');
    //           padding: 11px 36px 25px 36px;
    //           display: inline-block;
    //           background-size: contain;
    //           background-repeat: no-repeat;
    //         }
    //       }
    //     }
    //     .tier-point-dtls {
    //       margin-top: 15px;
    //       table {
    //         border-collapse: separate;
    //         border-spacing: 0;
    //         .border-top-left-rds-8 {
    //           border-top-left-radius: 8px;
    //         }
    //         .border-top-right-rds-8 {
    //           border-top-right-radius: 8px;
    //         }
    //         .border-bottom-right-rds-8 {
    //           border-bottom-right-radius: 8px;
    //         }
    //         .border-bottom-left-rds-8 {
    //           border-bottom-left-radius: 8px;
    //         }
    //         tbody,
    //         td,
    //         tfoot,
    //         th,
    //         thead,
    //         tr {
    //           border: 1px solid #1010121a;
    //         }
    //         th {
    //           padding: 23px 10px 20px 10px;
    //         }
    //         td {
    //           height: 82px;
    //           padding: 6px 10px;
    //         }
    //       }
    //     }
    //   }
    // }

    .explrar-points {
      .point-available-redeemed-expired {
        .explrar-point {
          background: linear-gradient(
            180deg,
            rgba(255, 218, 88, 0.3) 35.61%,
            rgba(255, 255, 255, 0.3) 102.08%
          );
          margin-top: -31px;
          margin-left: -31px;
          margin-right: -12px;
          border-radius: 0;
          box-shadow: unset;
        }

        .available-redeemed-exp-point-dtls {
          border: 1px solid #1010121a;
          border-radius: 8px;
          margin-top: 26px;
          padding: 34px 5px 30px 19px;

          .explar-points-table-tab-btns {
            width: 75%;
            margin: 0 auto;

            button {
              border: none;
              outline: none;
              background-color: transparent;
              width: 100%;
              padding: 10px 10px;
              border-bottom: 2px solid #1010121a;
            }
          }

          tr {
            &:nth-child(even) {
              background-color: #fda4000d;
            }

            td,
            th {
              padding: 16px 0;
            }
          }

          .table-data {
            max-height: 350px;
            overflow-y: scroll;
            padding-right: 30px;

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $cl-orange;
              border-radius: 6px;
            }

            &::-webkit-scrollbar-track {
              background: #f4f4f4;
            }

            .w-130 {
              width: 90px;
            }

            .border-top-bottom-left-rds-11 {
              border-radius: 11px 0 0 11px;
            }

            .border-top-bottom-right-rds-11 {
              border-radius: 0 11px 11px 0;
            }
          }
        }
      }
    }

    .payment-mathod {
      min-height: calc(100vh - 130px);
      margin-right: 15px;

      .credit-card-dtls {
        border: 1px solid #1010121a;
        border-radius: 8px;
        padding: 26px 14px 34px 21px;
        margin-top: 40px;

        .remove-account-btn {
          padding: 13px 27px;
          border: 1px solid #0000001c;
          border-radius: 8px;
        }

        .edit-account-btn {
          padding: 13px 27px;
          border: 1px solid #0000001c;
          border-radius: 8px;
          margin-left: 18px;
        }
      }
    }

    .account-security {
      min-height: calc(100vh - 130px);
      margin-right: 15px;

      .user-pass-border {
        padding: 22px 21px 22px 33px;
        border: 1px solid #1010121a;
        border-radius: 8px;
        margin-top: 35px;

        .change-user-pass-btn {
          border: 1px solid #fda400;
          padding: 11px 21px;
          border-radius: 7px;
        }
      }

      .color-red {
        color: #fd2e00;
      }
    }
  }
}

@media (max-width: 1400px) {
  .user-profile-and-another-dtls {
    .side-tab-content {
      .profile {
        .user-profile {
          .mail-id {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #0000001c;
          }

          .phn-number {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #0000001c;
          }
        }
      }

      .manage-booking {
        .manage-booking-hight {
          .flight-depart-details {
            .flight-shadules {
              .flight-price-time {
                .cancel-booking-width {
                  width: 40%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .user-profile-and-another-dtls {
    padding: 25px 20px;

    .side-tab-btn {
      padding: 0;
      border: 0;
      margin-bottom: 30px;

      .overflow-xl-scroll {
        max-width: 100%;
        padding-bottom: 15px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          height: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $cl-orange;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f4f4f4;
        }
      }

      a {
        padding: 16px 25px;
        border-radius: 46px;
        margin-top: 0px;
      }

      .sign-out-btn {
        width: 19%;
        border: 0;
        padding: 16px 0 16px 30px;
        margin: 0 0 18px 20px;

        p {
          padding: 0 10px 0 0;
        }
      }
    }

    .side-tab-content {
      padding: 0;

      .profile {
        padding-right: 0;

        .user-profile {
          .mail-id {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #0000001c;
          }

          .phn-number {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #0000001c;
          }
        }
      }

      .elite-explrar-tiers {
        .plr-28-12 {
          padding: 0 12px 20px 28px;
        }

        .tier-and-progress {
          margin-left: -20px;
          margin-right: -20px;
        }
      }

      .explrar-points {
        .point-available-redeemed-expired {
          .explrar-point {
            margin-top: 0;
            margin-left: -20px;
            margin-right: -20px;
            border-radius: 0;
            box-shadow: unset;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .user-profile-and-another-dtls {
    .side-tab-content {
      .profile {
        .user-profile {
          padding: 14px 10px;

          .profile-pic {
            width: 102px;
            height: 102px;
          }

          .edit-btn {
            padding: 7px 15px;
            right: 10px;
          }

          .mail-id {
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid #0000001c;
          }

          .phn-number {
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid #0000001c;
          }
        }

        .persnal-info {
          padding: 14px 10px;

          .edit-btn {
            padding: 7px 15px;
          }

          .save-btn {
            padding: 7px 20px;
          }

          .cancel-btn {
            padding: 7px 15px;
          }
        }

        .explrar-point-balance {
          padding: 30px 25px;
        }
      }

      .manage-booking {
        .manage-booking-hight {
          .w-100px {
            width: 100px;
          }

          .hotel-booking-manage {
            .border-lg-0 {
              border: 0 !important;
            }

            .height-lg-100 {
              height: 100%;
            }
          }
        }
      }

      .elite-explrar-tiers {
        .plr-28-12 {
          padding: 0 20px;
        }

        .tier-and-progress {
          .explrar-point {
            padding-bottom: 40px;

            .coin-img {
              width: 200px;
            }
          }

          .tier-point-dtls-dropdown {
            margin-top: 26px;

            .dropdown-tier {
              border: 1px solid #0000001c;
              border-radius: 6px;
              padding: 21px 15px;
              transition: 1s;

              .w-140 {
                width: 140px;
              }
            }

            .dropdown-dtls {
              display: none;

              .dropdown-dtls-points {
                span {
                  width: 7px;
                  height: 7px;
                  background-color: #101012;
                  border-radius: 50%;
                  opacity: 70%;
                }
              }
            }
          }
        }
      }

      .explrar-points {
        .point-available-redeemed-expired {
          .explrar-point {
            .coin-img {
              width: 150px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .user-profile-and-another-dtls {
    .side-tab-btn {
      a {
        padding: 16px;
      }

      .sign-out-btn {
        padding: 16px 0 16px 0px;
        margin: 0 0 18px 20px;
      }
    }

    .side-tab-content {
      .profile {
        .user-profile {
          .profile-pic {
            width: 85px;
            height: 85px;
          }
          .mail-id {
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }

          .phn-number {
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }
        }
      }

      .manage-booking {
        .manage-booking-hight {
          .manage-booking-tabs {
            overflow-x: scroll;
            padding-bottom: 15px;

            &::-webkit-scrollbar {
              height: 3px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $cl-orange;
              border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
              background-color: rgba(238, 236, 236, 0.431);
              border-radius: 8px;
            }

            .manage-booking-tab {
              min-width: 140px;
            }
          }

          .flight-ticket-manage {
            .height-100 {
              height: 100%;
              padding: 20px 0;
            }
          }

          .w-100px {
            width: 60px;
          }

          .border-none {
            border: 0 !important;
          }

          .w-md-100 {
            width: 100%;
          }
        }
      }

      .elite-explrar-tiers {
        .tier-and-progress {
          .explrar-point {
            padding-bottom: 30px;

            .coin-img {
              width: 150px;
            }
          }

          .current-tier {
            padding: 30px 15px;
          }

          .valid-until {
            padding: 30px 15px;
          }

          .tier-point {
            padding: 30px 15px;
          }

          .my-progress {
            padding: 26px 15px;

            .tiers {
              .tier-points {
                width: 70px;
                height: 49px;
                display: flex;
                justify-content: center;
                padding: 9px 0 0 0;
              }

              .tier-type {
                &::after {
                  height: 15px;
                  top: 14px;
                }

                .w-26 {
                  width: 40px;
                }

                p {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .explrar-points {
        .point-available-redeemed-expired {
          .explrar-point {
            margin-top: 0;
            margin-left: -20px;
            margin-right: -20px;
            border-radius: 0;
            box-shadow: unset;

            .coin-img {
              width: 100px;
            }
          }

          .available-redeemed-exp-point-dtls {
            .table-data {
              overflow: scroll;
              padding-right: 20px;

              &::-webkit-scrollbar {
                height: 4px;
              }

              .w-130 {
                width: 70px;
              }
            }

            .explrar-available-point-data {
              table {
                min-width: 400px;
              }
            }

            .explrar-redeemed-point-data {
              table {
                min-width: 500px;
              }
            }

            .explrar-expired-point-data {
              table {
                min-width: 400px;
              }
            }
          }
        }
      }

      .payment-mathod {
        margin-right: 0;

        .credit-card-dtls {
          padding: 20px 14px 28px 21px;
          margin-top: 40px;

          .remove-account-btn {
            padding: 8px 16px;
          }

          .edit-account-btn {
            padding: 8px 16px;
          }
        }
      }

      .account-security {
        margin-right: 0;

        .user-pass-border {
          padding: 17px 13px 17px 14px;

          .change-user-pass-btn {
            padding: 7px 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .user-profile-and-another-dtls {
    margin: 30px 0 0 0;
    padding: 13px 10px;

    .side-tab-btn {
      a {
        padding: 16px 10px;
      }

      .sign-out-btn {
        width: 35%;
        padding: 16px 0 16px 0px;
        margin: 0 0 18px 20px;
      }
    }

    .side-tab-content {
      .profile {
        .user-profile {
          .edit-btn {
            padding: 7px;
            top: 10px;
            right: 10px;
          }
        }

        .persnal-info {
          padding: 13px 10px;

          .edit-btn {
            padding: 7px;
          }

          .save-btn {
            padding: 7px 13px;
          }

          .cancel-btn {
            padding: 7px 10px;
          }
        }

        .explrar-point-balance {
          padding: 20px 10px;
        }
      }

      .manage-booking {
        .manage-booking-hight {
          .flight-ticket-manage {
            .dotted-line {
              border-top: 2px dashed rgba(0, 0, 0, 0.0509803922);
              width: 80px;
              margin-left: 0;
              margin-right: 0;
            }

            .height-100 {
              height: 100%;
              padding: 20px 0;
            }
          }

          .hotel-booking-manage {
            .dotted-line {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }

      .elite-explrar-tiers {
        .plr-28-12 {
          padding: 0 12px;
        }
        .tier-and-progress {
          margin-left: -10px;
          margin-right: -10px;

          .explrar-point {
            padding-bottom: 40px;

            .coin-img {
              width: 100px;
            }
          }

          .current-tier {
            padding: 22px 10px;
          }

          .valid-until {
            padding: 22px 10px;
          }

          .tier-point {
            padding: 22px 10px;
          }

          .my-progress {
            padding: 15px;

            .tiers {
              .tier-points {
                width: 53px;
                height: 40px;
                display: flex;
                justify-content: center;
                padding: 3px 0 0 0;
              }

              .tier-type {
                &::after {
                  height: 9px;
                  top: 9px;
                }

                .w-26 {
                  width: 26px;
                }

                p {
                  font-size: 12px;
                }
              }
            }
          }

          .tier-point-dtls-dropdown {
            .dropdown-tier {
              .w-140 {
                width: 114px;
              }
            }
          }
        }
      }

      .explrar-points {
        .point-available-redeemed-expired {
          .explrar-point {
            margin-left: -10px;
            margin-right: -10px;

            .coin-img {
              width: 70px;
            }
          }

          .available-redeemed-exp-point-dtls {
            padding: 10px 5px 15px 10px;

            .explar-points-table-tab-btns {
              width: 90%;
              margin: 0 auto;
            }
          }
        }
      }

      .payment-mathod {
        margin-right: 0;

        .credit-card-dtls {
          padding: 15px;
        }
      }
    }
  }
}

// default style for all screens
.header-profile-pic {
  border-radius: 50%;
  object-fit: cover;
}

// for mobile devices
@media (max-width: 576px) {
  .header-profile-pic {
    width: 25px;
    height: 25px;
  }
}

// for tablets
@media (min-width: 577px) and (max-width: 768px) {
  .header-profile-pic {
    width: 36px;
    height: 36px;
  }
}
//  for large screen devices
@media (min-width: 769px) {
  .header-profile-pic {
    width: 42px;
    height: 42px;
  }
}

// default style for all screens
.header-profile-pic {
  border-radius: 50%;
  object-fit: cover;
}

// for mobile devices
@media (max-width: 576px) {
  .header-profile-pic {
    width: 25px;
    height: 25px;
  }
}

// for tablets
@media (min-width: 577px) and (max-width: 768px) {
  .header-profile-pic {
    width: 36px;
    height: 36px;
  }
}
//  for large screen devices
@media (min-width: 769px) {
  .header-profile-pic {
    width: 42px;
    height: 42px;
  }
}

.d-flex {
  position: relative; /* Ensure the container is relative for absolute positioning */
}

.popups {
  position: absolute;
  bottom: 139%;
  left: 97%;
  transform: translateX(-50%);
  width: 200px; /* Set a fixed width or adjust as needed */
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
}

.popups-notch {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fda400;
}

.info-icon {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .popups {
    bottom: 139%;
    left: 97%;
    transform: translateX(-50%);
  }
}
