.my-trip-cards {
  .add-trip-btn {
    border: 0;
    outline: 0;
    padding: 14px 30px;
    border-radius: 6px;
    background-color: $cl-blue;
  }

  .trip-btns {
    border-radius: 7px;
    width: 475px;
    overflow: hidden;

    .upcoming-btn {
      border: 0;
      outline: 0;
      background-color: $cl-orange;
      padding: 14px 77px;
    }

    .past-btn {
      border: 0;
      outline: 0;
      background-color: #ededed;
      padding: 14px 102px;
    }
  }

  .my-trip-card {
    padding: 9px 9px 18px 9px;
    border: 1px solid #0000000a;
    border-radius: 14px;
    width: 424px;
    height: 360px;

    .trip-img {
      border-radius: 14px 14px 0 0;
      width: 100%;
      height: 250px;
    }
  }
}

@media (min-width: 1200px) {
  .your-trips {
    .header-height-control {
      max-height: 225px;
    }
  }
}

@media screen and (min-width: 768px) {
  .trips {
    column-gap: 1.5rem;
    row-gap: 2rem;

    .col-4 {
      width: 424px;
    }
  }
}
@media (max-width: 1200px) {
  .your-trips {
    .header-height-control {
      max-height: 160px;
    }
  }
}
@media screen and (max-width: 768px) {
  .trip-btns {
    width: 100% !important;

    .upcoming-btn {
      border: 0;
      outline: 0;
      background-color: $cl-orange;
      text-align: center;
      width: 50%;
      padding: 10px 20px !important;
    }

    .past-btn {
      border: 0;
      outline: 0;
      background-color: #ededed;
      text-align: center;
      width: 50%;
      padding: 10px 20px !important;
    }
  }

  .your-trips {
    .header-height-control {
      max-height: 200px;
    }
  }

  .my-trip-cards {
    .trips {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;

      div {
        width: 90%;
      }

      .my-trip-card {
        width: 100%;
      }
    }
  }
}
