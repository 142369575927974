#hotel-compare {
  .hero-bg-blue {
    height: 320px;
    width: 100%;
    background-color: $cl-blue;
  }
  .border-gray {
    border: 1px solid #0000001a;
  }
  .pt-28 {
    padding-top: 28px;
  }
  .pb-37 {
    padding-bottom: 37px;
  }
  .row {
    .col-4 {
      &:last-child {
        .hotel-compare-card {
          border: 0 !important;
        }
      }
    }
  }
}
.hotel-compair-scroll {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 3px;
  }
  // .hotel-compair-container {
  //   min-width: 940px;
  // }
}
.hotel-compare-hv {
  min-height: 0vh;
}
.hotel-compare-cards {
  // margin-top: 42px;
  // padding: 28px 0 37px 0;
  background-color: $cl-white;
  // border: 1px solid #0000001a;
  // border-radius: 8px;

  .hotel-compare-card {
    padding: 0 23px 0px 20px;
    // border-right: 1px solid #0000001a;
    height: 100%;
    .border-right-gray {
      border-right: 3px solid #0000001a;
    }
    .remove-compair {
      height: 51px;
      width: 51px;
      border-radius: 50%;
      margin-left: 11px;
      border: 1px solid #00000026;
    }
    .compare-hotel {
      margin-right: 11px;
    }
    .hotel-main-img {
      border-radius: 8px;
    }
    .hotel-mini-img {
      margin-left: 10px;
      width: 88px;
      border-radius: 1.8px;
    }
    .hotel-book-btn {
      padding: 18px 10px;
      width: 100%;
      background-color: $cl-blue;
      border-radius: 6px;
      margin-top: 33px;
      &:hover {
        background-color: $cl-orange;
        color: $cl-white !important;
      }
    }
  }
}

@media (max-width: 1400px) {
  .hotel-compare-cards {
    .hotel-compare-card {
      .hotel-mini-img {
        width: 73px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .hotel-compare-cards {
    .hotel-compare-card {
      padding: 0 14px 0px 15px;
      .hotel-mini-img {
        width: 58px;
      }
      .hotel-main-img {
        height: 250px;
      }
    }
    .fs-24 {
      font-size: 19px;
    }
  }
}
// @media (max-width: 992px) {
//   .hotel-compare-cards {
//     .hotel-compare-card {
//      width: 100%;
//     }
//   }
// }

@media (max-width: 768px) {
  .hotel-compare-cards {
    .hotel-compare-card {
      .fs-24 {
        font-size: 18px;
      }
      .fs-26 {
        font-size: 20px;
      }
      .remove-compair {
        height: 37px;
        width: 37px;
        border-radius: 50%;
        margin-left: 11px;
        border: 1px solid #00000026;
        img {
          width: 30px;
        }
      }
      .hotel-mini-img {
        width: 40px;
      }
      .star-img-width {
        width: 15px;
      }
      .hotel-book-btn {
        padding: 13px 10px;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 576px) {
  .hotel-compare-cards {
    .hotel-compare-card {
      padding: 8px;
      .fs-24 {
        font-size: 16px;
      }
      .hotel-mini-img {
        width: 59px;
      }
      .hotel-book-btn {
        padding: 13px 10px;
      }
    }
  }
}
