:root {
  --privacy-policy-main-color: #101012;
}

.similar-design-p,
.similar-font-size {
  font-size: 18px;
  color: var(--privacy-policy-main-color);
  font-weight: 400;
  line-height: 155.56%;
}

.similar-design-h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 126.66%;
}
.similar-margin-div {
  margin-top: 40px;
}

.margin-top-20px {
  margin-top: 20px;
}

.privacy-policy-main {
  width: 40%;
  margin: 96px auto;
}

.privacy-policy-container {
  margin-bottom: 96px;
}

.privacy-policy-update-date {
  text-align: center;
  color: #fda400;
  font-weight: 600;
  /* margin-bottom: 12px; */
}

.privacy-policy-container-h1 {
  color: var(--privacy-policy-main-color);
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.96px;
  line-height: 125%;
  margin-top: 12px;
  margin-bottom: 24px;
}
.privacy-policy-container-p2 {
  text-align: center;
  color: var(--privacy-policy-main-color);
  font-size: 20px;
  line-height: 150%;
}

.privacy-policy-content-container {
  margin: 0 24px;
}
.explrar-privacy-policy-text p {
  color: var(--privacy-policy-main-color);
  line-height: 155.56%;
}

.privacy-policy-list-item {
  display: flex;
  align-items: center;
  gap: 12px;
}
.privacy-policy-list-item-container {
  margin: 20px 0;
}
.privacy-policy-list-item-container-2 {
  margin-top: 20px;
}
