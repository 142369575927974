.payment-dtls-and-order-summery {
  margin-top: 70px;

  .payment-dtls {
    padding: 31px 17px 33px 17px;
    border-radius: 8px;
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;

    .payment-options {
      margin-top: 40px;

      .payment-option {
        border: 1px solid #1010121a;
        padding: 10px 10px 10px 16px;
        border-radius: 8px;
        width: 100%;
      }
    }

    .input-decoration {
      margin-top: 34px;
      padding: 9px 13px 11px 13px;

      input {
        &::placeholder {
          opacity: 70%;
        }
      }
    }

    .none-btn,
    .max-btn {
      width: 106px;
      padding: 13px 10px;
      background-color: $cl-orange;
      border-radius: 6px;
      margin: 0 36px;
    }

    .book-now {
      font-size: 18px;
      padding: 19px 10px;
      border-radius: 6px;
      border: none;
      background-color: $cl-blue;
      width: 100%;
    }
  }

  .order-summery {
    padding: 29px 9px 44px 9px;
    border-radius: 8px;
    background-color: $cl-white;
    box-shadow: 3px 4px 26px 0px #0000000d;

    .order-summery-item {
      padding: 13px 30px 13px 13px;
      border-bottom: 2px solid #0000000d;

      .border-left-gray {
        border-left: 2px solid #0000004e;
      }
    }

    .border-top-dashed-gray {
      border-top: 2px dashed #0000000d;
    }

    .apply-discount-coupen-popup {
      background-color: $cl-white;
      box-shadow: 3px 4px 26px 0px #0000000d;
      padding: 28px 19px;
      border-radius: 8px;
      max-width: 594px;
      width: 100%;
      position: absolute;
      top: -86%;
      left: 50%;
      transform: translateX(-50%);
      display: none;

      .discount-coupen-input {
        padding: 11px 16px;
        border: 1px solid #00000021;
        border-radius: 5px;
        margin: 38px 0 32px 0;
      }

      .apply-code-btn {
        padding: 19px 10px;
        background-color: $cl-blue;
        border-radius: 6px;
        border: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .payment-dtls-and-order-summery {
    .order-summery {
      .w-130 {
        width: 130px;
      }
    }
  }
}

@media (max-width: 992px) {
  .payment-dtls-and-order-summery {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .payment-dtls-and-order-summery {
    margin-top: 30px;

    .payment-dtls {
      padding: 31px 17px 33px 17px;

      .input-decoration {
        margin-top: 25px;
        padding: 5px 13px 7px 13px;
      }

      .none-btn,
      .max-btn {
        width: 70px;
        padding: 13px 10px;
        margin: 0 20px;
      }

      .book-now {
        padding: 13px;
      }
    }

    .order-summery {
      padding: 29px 9px 44px 9px;
      border-radius: 8px;
      background-color: $cl-white;
      box-shadow: 3px 4px 26px 0px #0000000d;

      .order-summery-item {
        padding: 13px;

        .w-130 {
          width: 110px;
        }

        .w-40px {
          width: 40px;
        }
      }

      .apply-discount-coupen-popup {
        padding: 20px 12px;

        .discount-coupen-input {
          padding: 2px 16px;
          border: 1px solid rgba(0, 0, 0, 0.1294117647);
          border-radius: 5px;
          margin: 24px 0 19px 0;
        }

        .apply-code-btn {
          padding: 13px 10px;
          background-color: $cl-blue;
          border-radius: 6px;
          border: 0;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .payment-dtls-and-order-summery {
    .payment-options {
      position: relative;

      .payment-options-dropdown {
        position: absolute;
        width: 100%;
        background-color: $cl-white;
        top: 50px;
        z-index: 1;
        transform: scaleY(0);
        transform-origin: top;
        box-shadow: 3px 19px 26px 0px #0000000d;
        transition: 0.1s;
        padding: 20px 10px;
        border-radius: 0 0 8px 8px;
      }

      .payment-optn-drop-down-show {
        transform: scaleY(1);
      }
    }

    .order-summery {
      .order-summery-item {
        .w-130 {
          width: 90px;
        }

        .w-40px {
          width: 35px;
        }
      }
    }
  }
}
