// .slick-slider.slick-initialized {
//     padding-left: 0px;
//     padding-right: 0px;
// }

.top-destination {
  .slick-slide {
    height: min-content;

    .timing-card {
      height: 100% !important;
    }

    .col-3 {
      height: 100%;
    }
  }

  .slick-slide > div:nth-child(1) {
    height: 100%;
  }
}

.blue-header-top {
  background-color: #31449b;
}

.input-decoration {
  .css-cveggr-MuiListItemIcon-root {
    min-width: 20px;
    margin-right: 10px;
  }
}

.hotel-view-cards {
  .img-wrapper {
    object-fit: cover;
  }
}

.guest-rating-item {
  p {
    width: 30% !important;
  }
}

.hero-links.active {
  a {
    color: #fda400;
  }

  svg path {
    stroke: #fda400;
  }
}

.user-login-wrapper {
  img {
    width: 30px;
    margin: 0px 0px 0px 20px;
  }
}

.sort-wrapper {
  width: 100%;
}

.carousal-main-image {
  height: 80vh !important;
  width: 70% !important;
}

.MuiFab-root {
  flex-shrink: 0;
}

.hotel-booking-dtls-outer-container {
  align-items: start !important;

  .hotel-booking-dtls-image-container {
    width: 50% !important;
  }

  .hotel-booking-dtls-details {
    display: flex;
    flex-direction: column;
    width: 50% !important;
    gap: 10px;

    .hotel-booking-dtls-first {
      flex-direction: column;
      gap: 5px;
      align-items: start !important;

      :nth-child(2) {
        margin: 0 !important;
      }
    }

    p {
      margin: 0 !important;
    }

    div {
      margin-top: 0 !important;
    }
  }
}

@media screen and (max-width: 1400px) {
  .carousal-main-image {
    height: 75vh !important;
    width: 75% !important;
  }

  .hotel-stars {
    label {
      span {
        svg.MuiSvgIcon-root {
          width: 30px !important;
        }
      }
    }
  }

  .hotel-view {
    .hotels {
      .hotel-price-distance-rating {
        .hotel-tabs {
          .most-popular-btn,
          .distance-btn {
            width: 50%;
          }
        }
      }
    }
  }

  // hotel details Page

  .hotel-details {
    .hotel-detals-tabs {
    }

    .room {
      .rooms {
        .hotel-Img,
        .hotel-mini-img {
          width: 100% !important;
        }
      }
    }

    .hotel-details-tab-content .border-rds-8 {
      object-fit: cover;
    }
  }
}

.flight-price-time {
  .my-flight-logo {
    object-fit: contain !important;
    width: 3rem !important;
    padding: 0 !important;
    margin: 20px 20px 30px 20px;
    border: none !important;
  }
}

.flight-logo-container {
  border-right: 1px solid rgba(0, 0, 0, 0.0509803922);
}

.hotel-price-distance-rating {
  z-index: 1 !important;
}

.shownav {
  z-index: 4 !important;
  position: fixed !important;
  height: 100vh !important;
}

.card-image {
  object-fit: cover !important;
}

@media screen and (max-width: 1200px) {
  .carousal-main-image {
    height: 70vh !important;
    width: 80% !important;
  }

  .trip-itnerary .itnerary-area .row .block-1,
  .trip-itnerary .itnerary-area .row .block-2 {
    width: 100%;
  }

  .guest-rating-item {
    p {
      width: 40% !important;
    }
  }

  .hotel-view {
    .filter-show-btn {
      transition: 0.3s all ease-in-out;
      margin-bottom: 0px !important;

      &:hover {
        border-color: #fda400 !important;
      }

      img {
        width: 20px;
        height: auto;
      }
    }

    .hotels {
      .hotel-price-distance-rating {
        box-shadow: 3px 8px 21px 9px rgb(0 0 0 / 3%);
        padding: 12px 0px !important;
        border-bottom: none !important;

        .hotel-tabs {
          &::after {
            display: none;
          }

          .price-rating-drop-down {
            gap: 10px;
            width: 100%;

            // .star-rating-btn,
            // .lowest-price-btn {
            //     width: fit-content;
            //     border: 1px solid rgba(17, 15, 15, 0.102) !important;
            //     border-radius: 8px;
            // }

            // .most-popular-btn {
            //     border: 1px solid rgba(17, 15, 15, 0.102) !important;
            //     border-radius: 8px;
            // }

            // .star-rating-btn {
            //     padding-left: 12px;
            //     padding-right: 12px;
            // }

            button,
            div {
              font-size: 14px !important;
            }

            // .distance-btn {
            //     border: 1px solid rgba(17, 15, 15, 0.102) !important;
            //     border-radius: 8px;
            // }

            // .button-sort-active {
            //     border-color: #fda400 !important;
            // }
          }

          .sort-box-wrapper {
            gap: 10px;
          }
        }
      }
    }
  }

  .hotel-room-book-form {
    margin: 30px 0px 12px 0px !important;
    padding: 12px !important;

    .input-decoration {
      width: 100% !important;
    }
  }

  .show-filters {
    .hotel-sider-filters {
      position: fixed !important;
      left: 0px !important;
      min-width: 350px;
      padding: 20px;
    }
  }

  #hotel-search-result-header {
    .hotel-result-scroll {
      padding-bottom: 30px;
    }

    .hero-bg-blue {
      height: 305px !important;
    }

    .hotel-view {
      margin-top: 0px;
    }
  }
}

.flight-multi-city-date-picker {
  width: 50%;
}

@media screen and (max-width: 992px) {
  .generated-trip-grid-child {
    flex-basis: 40% !important;
  }

  .flight-first-row {
    flex-direction: column;
    align-items: stretch;

    .flight-search-btn {
      width: 100% !important;
    }
  }

  .flight-search-main-row,
  .oneway-search-main-row,
  .multicity-search-main-row {
    flex-direction: column;
    align-items: stretch !important;
    gap: 1rem !important;
  }

  .flight-date-range-picker,
  .one-way-date-picker {
    width: 100% !important;
  }

  .multi-city-main-row {
    flex-direction: column;
    align-items: stretch;

    .flight-multi-city-date-picker {
      width: 100% !important;
    }
  }

  .guest-rating-item {
    p {
      width: 30% !important;
    }
  }

  .carousal-main-image {
    height: 65vh !important;
    width: 85% !important;
  }

  footer {
    .flex-wrap {
      justify-content: flex-start;
      gap: 30px;

      ul {
        flex: 0 0 30%;
      }
    }
  }

  .hotel-detals-tabs {
    align-items: stretch;

    .hotel-dtl-tab {
      flex-direction: column;
      justify-content: space-between !important;
    }
  }

  .hotel-view {
    .hotels {
      .hotel-price-distance-rating {
        .hotel-tabs {
          .price-rating-drop-down {
            flex-wrap: wrap;
            justify-content: space-around !important;
            gap: 0px;

            .most-popular-btn,
            .distance-btn {
              width: auto !important;
              padding-left: 0 !important;
              padding-right: 0 !important;
            }

            .lowest-price-btn {
              padding-left: 0px !important;
            }

            .filter-show-btn {
              border: none;

              p {
                font-size: 14px !important;
              }
            }

            .sort-wrapper,
            .filter-show-btn-width {
              width: 32%;
            }
          }
        }
      }
    }
  }

  .br-right-m {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      left: auto;
      background-color: rgba(17, 15, 15, 0.102);
      top: 0;
      right: -3%;
      bottom: 0;
    }
  }

  .popular-hotels {
    .popular-hotels-card {
      img.w-100 {
        height: 200px;
      }
    }
  }

  .hotel-view-cards {
    .location-wrapper {
      img {
        width: 15px;
      }
    }

    .feature-icon-wrapper {
      img {
        width: 20px;
      }
    }
  }

  .guest-rating-row {
    flex-direction: column !important;
    gap: 10px !important;

    .guest-rating-column {
      margin: 0 !important;
      gap: 10px !important;

      div {
        gap: 10px;
      }
    }

    :nth-child(2) {
      margin: 0 !important;
    }
  }

  .hotel-room-book-form {
    .input-decoration {
      padding: 12px 12px !important;
    }

    .hotel-search-btn {
      height: auto !important;
      min-height: 100%;
      padding: 12px 30px !important;
    }
  }

  #hotel-search-result-header {
    .hero-bg-blue {
      height: 335px !important;
    }
  }

  .sort-wrapper {
    .sort-box-wrapper {
      flex-direction: column;
      position: absolute;
      min-width: 200px;
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 3px 8px 21px 9px rgba(0, 0, 0, 0.03);
      display: none !important;

      button {
        width: auto !important;
        border: none !important;
      }

      .button-sort-active {
        color: #fda400 !important;
      }
    }

    .show-sort {
      display: block !important;
    }
  }

  .hotel-details {
    .room {
      .rooms {
        .hotel-Img,
        .hotel-mini-img {
          height: auto !important;
        }
      }
    }
  }

  .break-down-bg .tab-pane .timing-card a::after {
    width: 100%;
  }
}

.guest-rating-column {
  display: flex !important;
  flex-direction: column !important;
}

.hotel-facilities-list {
  a {
    height: 3rem !important;
  }
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.my-switch-icon {
  background-color: #31449b;
  border-radius: 50%;
  flex-shrink: 0;
  height: 2.5rem;
  width: 2.5rem;
  display: grid;
  place-content: center;
}

.multi-city-counter {
  background-color: #fda400;
  border-radius: 20%;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.8rem;
  aspect-ratio: 1;
  display: grid;
  margin: 10px 0;
  place-items: center;
}

.flight-logo-tiny {
  width: 4rem !important;
  height: 2rem !important;
  object-fit: cover !important;
}

.hotel-card-first-item {
  width: 40%;

  .rating-tab {
    top: 0;
    right: 5% !important;
    left: unset !important;
  }
}

@media (max-width: 767px) {
  .round-flight-location-row,
  .oneway-flight-location-row,
  .multicity-flight-location-row {
    flex-direction: column !important;
    align-items: center !important;
    gap: 0.3rem !important;

    .my-switch-icon {
      transform: rotate(90deg);
    }
  }

  .hotel-cards .hotel-card {
    flex-direction: column !important;

    .hotel-card-first-item {
      width: 100% !important;
    }
  }

  .packing-list-first-row {
    flex-direction: column !important;
    align-items: stretch !important;
    gap: 1rem !important;

    .change-button {
      justify-content: space-between !important;
    }
  }

  .flight-start-stop-line {
    flex-basis: 20% !important;
  }

  .flight-times-clock {
    flex-basis: 40% !important;
  }

  .flight-times-inner-text-end {
    text-align: end !important;
  }

  .flight-times-inner-text-start {
    text-align: start !important;
  }

  .flight-times-inner-text-start.fs-32,
  .flight-times-inner-text-end.fs-32 {
    font-size: 1.5rem !important;
  }

  .flight-times-inner-text-start.fs-16,
  .flight-times-inner-text-end.fs-16 {
    font-size: 0.8rem !important;
  }

  .flight-details-middle-item {
    display: none !important;
  }

  .price-width {
    margin-top: 0.5rem !important;
  }

  .flight-details-middle-child-text {
    text-wrap: nowrap;
    overflow: visible;
  }

  .flight-logo-container {
    align-self: center;
    border: none !important;

    .my-flight-logo {
      object-fit: cover !important;
      margin: 0 !important;
      width: 6rem !important;
      height: 3rem !important;
    }
  }

  .oneway-city-selector,
  .round-city-selector,
  .multi-city-selector {
    flex-direction: column;
    align-items: stretch !important;

    .my-switch-icon {
      align-self: center;
      transform: rotate(90deg);
    }
  }

  .flight-search-main-row {
    .flight-search-row {
      flex-direction: column;

      .flight-date-range-picker {
        width: 100%;
      }
    }
  }

  .oneway-search-main-row {
    gap: 1rem !important;

    .oneway-search-row {
      flex-direction: column;
    }
  }

  .form-row {
    flex-direction: column !important;
    gap: 10px !important;
  }

  .hotel-booking-dtls-outer-container .hotel-booking-dtls-image-container {
    width: 100% !important;
  }

  .hotel-booking-dtls-outer-container .hotel-booking-dtls-details {
    width: 100% !important;
  }

  .hotel-booking-dtls-outer-container {
    flex-direction: column !important;
  }

  .guest-rating-item {
    p {
      width: 40% !important;
    }
  }

  .carousal-main-image {
    height: 60vh !important;
    width: 90% !important;
  }

  .check-in-out-dtls {
    padding: 26px 10px !important;
  }

  .user-rating {
    padding: 27px 23px 15px 15px !important;

    .row {
      flex-direction: column !important;
      gap: 20px !important;
      align-items: center !important;

      .profile-dtl {
        border-right: none !important;
        width: 80% !important;
      }

      .review {
        width: 100% !important;
      }
    }
  }

  .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    width: 90%;
  }

  .input-decoration.input-filters {
    flex-grow: 1;
    padding: 10px !important;
  }

  footer {
    .flex-wrap {
      justify-content: flex-start !important;
      gap: 30px;

      ul {
        flex: 0 0 45%;
      }
    }
  }

  // .hotel-view-cards {
  //     .img-wrapper {
  //         height: auto;
  //     }
  // }

  #hotel-search-result-header {
    .hero-bg-blue {
      height: 325px !important;
    }
  }

  .hotel-view {
    .hotels {
      .hotel-price-distance-rating {
        padding: 7px 12px !important;

        .hotel-tabs {
          margin: 0px !important;

          .hotel-sort-dd {
            position: absolute;
            width: 234px;
            background-color: var(--color-white);
            padding: 12px 12px;
            border: 1px solid rgba(0, 0, 0, 0.0784313725);
            box-shadow: -5px 4px 20px 0px rgba(0, 0, 0, 0.0901960784);
            border-radius: 11px;
            z-index: 10;
          }

          .filter-show-btn-width {
            margin-bottom: 0px;
            border-left: none;
          }

          .price-rating-drop-down {
            border: none !important;
            padding: 0px !important;

            .most-popular-btn,
            .distance-btn {
              width: auto !important;
            }

            .lowest-price-btn {
              width: auto !important;
              padding-left: 12px !important;
              padding-right: 12px !important;
            }
          }
        }
      }
    }
  }

  .hotel-name {
    font-size: 22px;
  }

  .hotel-room-book-form {
    .input-decoration {
      font-size: 14px !important;

      input {
        font-size: 14px !important;
      }

      img {
        width: 18px !important;
        height: auto;
      }
    }

    .date-decoration {
      gap: 10px !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding-right: 10px;
      }
    }

    .hotel-search-btn {
      padding: 12px 20px !important;
    }
  }

  input#departing {
    padding-left: 0px !important;
  }

  #hotel-search-result-header {
    .hotel-result-scroll {
      margin-top: 10px;
    }
  }
}

.hero-bg-img {
  background-size: cover !important;
}

.css-tlelie-MuiListItemText-root {
  margin: 0 !important;
}

.flight-result-middle-item {
  padding: 1.5rem !important;
  margin: 0 !important;
}

.flight-plans {
  overflow-x: hidden;
}

.generated-trip-grid-parent {
  width: 100% !important;
}

.packingList-div {
  height: unset !important;
  overflow: unset !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.hero-form {
  width: 100% !important;
}

.cta {
  .orange-circle,
  .orange-circle1,
  .blue-circle,
  .blue-circle1 {
    filter: blur(60px) !important;
  }
}

.progress-bar#progress-bar {
  position: relative !important;
}

.breakdown {
  margin: 35px 0;

  .timeline {
    ol {
      .progress {
        width: 100% !important;
      }
    }
  }
}

.filter-card {
  border: 1px solid transparent;
  padding: 10px !important;
  transition: all 200ms ease-in-out;
}

.filtercard-active {
  border: 2px solid #31449b;
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .breakdown {
    .timeline {
      .progress {
        height: 6px !important;
      }
    }
  }

  .timeline-stage-indication {
    background-color: transparent !important;
  }

  .generated-trip-grid-child {
    flex-basis: 90% !important;
  }

  .timeline-stage-indication {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }

  .timeline .progress {
    top: 17px !important;
  }

  .flight-result-middle-item {
    padding: 0.8rem !important;
  }

  .flight-price-time:not(.result-screen) {
    padding: 10px !important;
  }

  .persnal-information {
    padding: 20px !important;
  }

  .crib-and-beds-child {
    width: 100% !important;

    .age-fee-dtls {
      width: 100% !important;
    }
  }

  .carousal-main-image {
    height: 55vh !important;
    width: 95% !important;
  }

  .check-in-out-dtls.extra-beds {
    flex-direction: column;
    gap: 20px !important;

    .col-3 {
      width: 100% !important;
    }
  }

  .facilitys {
    div {
      flex-direction: column;
      width: 100%;
      align-items: start !important;
      gap: 0.3rem;

      span {
        width: 100% !important;
      }
    }
  }

  .hotel-details {
    .hotel-detals-tabs {
      p {
        display: none;
      }
    }
  }

  header.hotel-result-page-vh {
    min-height: auto !important;
  }

  .hr-line.d-sm-none.d-flex {
    width: 80%;
    height: 1px;
    background: #ffffff2e;
    margin: 0 auto;
  }

  footer {
    .flex-wrap {
      gap: 15px;

      ul {
        flex: 0 0 100%;
        overflow: hidden;
        padding-bottom: 15px !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.0509803922);

        li {
          a {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }

      .btn-link {
        background-color: #14161b;
        margin: 0 auto;
        width: 100%;

        &:hover {
          color: #fff;
          background-color: #ffffff1a;
        }
      }

      .collapsed {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }

      .open {
        max-height: 1000px;
        /* Set a large enough value to accommodate the content */
        animation: slideDown 0.3s ease-out;
      }

      .br-top {
        padding-top: 25px !important;
        border-top: 2px solid rgba(255, 255, 255, 0.0509803922);
      }
    }
  }

  .hotel-view {
    .hotels {
      .hotel-price-distance-rating {
        .hotel-tabs {
          margin: 0px !important;

          .filter-show-btn-width {
            border: none !important;
            margin-bottom: 0px !important;

            .filter-show-btn {
              position: unset !important;
              padding: 8px 10px;
            }
          }

          .price-rating-drop-down {
            position: unset !important;
            width: 100% !important;
            transform: none !important;

            .lowest-price-btn {
              width: auto !important;
              padding-left: 0px !important;
              padding-right: 7px !important;
            }
          }
        }
      }

      .hotel-view-cards {
        .img-wrapper {
          height: 100%;
        }

        .rating-img-wrapper {
          svg {
            width: 15px !important;
            height: 15px !important;
          }
        }

        .guest-rating {
          padding: 0px 5px !important;
          font-size: 10px;
          height: 25px !important;
        }

        .btn-wrapper {
          width: 100%;

          .view-hotel-btn,
          .sold-out-btn {
            width: 100%;
            text-align: center;
          }
        }

        .fs-22 {
          font-size: 16px !important;
        }

        .add-compare-wrapper {
          label {
            width: max-content;
          }
        }
      }

      .hotel-result-scroll {
        .hotel-view-cards:first-child {
          margin-top: 10px;
        }
      }
    }
  }

  .hotel-room-book-form {
    margin: 12px 0px !important;

    input#departing {
      font-weight: 600;
    }

    .room-selector-wrapper {
      width: 100%;
      border-left: 1px solid black;
    }

    .hotel-search-btn {
      padding: 10px !important;

      img {
        width: 100% !important;
        height: 20px !important;
      }
    }

    .search-input-col {
      width: calc(100% - 80px);
    }

    .search-btn-col {
      width: 80px;
    }

    .date-picker-wrapper {
      min-width: auto !important;
      gap: 5px;
    }
  }

  #hotel-search-result-header {
    .hero-bg-blue {
      height: 240px !important;
    }

    .hotel-view {
      margin-top: 0px;
    }
  }

  .search-wrapper {
    .input-decoration {
      border: none;
      padding: 0px !important;

      img {
        display: none;
      }

      input {
        margin-left: 0px !important;
      }
    }

    .mobile-input {
      border: 1px solid rgba(17, 15, 15, 0.102) !important;
      border-radius: 8px;
      padding: 6px 0px;
      margin-left: 0px;
    }

    .date-decoration {
      width: fit-content !important;

      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 0px !important;
      }
    }

    .list-group-city {
      top: 20px;
    }

    .hotel-guest-card-w svg {
      display: none !important;
    }
  }

  .MuiDateRangeCalendar-root {
    flex-direction: column !important;

    .MuiDateRangeCalendar-monthContainer:nth-child(3) {
      .MuiPickersArrowSwitcher-root.css-1v8yqdb-MuiPickersArrowSwitcher-root {
        justify-content: center !important;
      }
    }
  }

  .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
    position: absolute !important;
    top: 16px;
    right: 16px;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.policies {
  .check-in-out-dtls:not(.extra-beds) {
    flex-wrap: wrap !important;
    gap: 10px;

    div {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      gap: 5px !important;
      justify-items: stretch;
      align-items: center !important;
      width: max-content !important;
    }
  }
}

.round-flight-location-row,
.oneway-flight-location-row,
.multicity-flight-location-row {
  align-items: end;
}

.popular-attraction-card-container a {
  width: 100% !important;
}

.sleeps-label {
  display: none;
}

.guest-rating-item {
  .mprogress-bar {
    flex-shrink: 0 !important;
    width: max-content !important;
    flex-grow: 1 !important;
  }
}

.guest-rating-row {
  width: 100% !important;
}

.passengers-box.flight-modal,
.passengers-box.trip-generator,
.passengers-box.hotel-modal {
  width: 100% !important;
}

.slider-right-arrow-back {
  border: none !important;
  background-color: transparent !important;

  svg {
    filter: brightness(0);
  }
}

.popular-attractions-btn {
  padding: 8px 16px !important;
}

.popular-attractions-btn {
  transition: all 200ms ease-in-out;
}

.flight-search-tabbar {
  display: none;
}

.select-tab.active {
  border-bottom: 2px solid $cl-orange !important;
}

@media screen and (max-width: 480px) {
  .flight-search-tabbar {
    display: block !important;
  }

  .flight-search-radio-button {
    display: none !important;
  }

  .flight-search-label {
    display: none;
  }

  .booking-slot-close-btn {
    top: -30px !important;
  }

  .round-city-selector,
  .oneway-city-selector,
  .multi-city-selector {
    z-index: 0;
    flex-direction: row !important;
    gap: 1.5rem !important;

    .my-switch-icon {
      transition: all 100ms ease-in-out;
      z-index: 3;
      transform: rotate(0deg);
      position: absolute;
      background-color: transparent !important;
      border: 2px solid #d3d9f1;

      img {
        filter: invert(73%) sepia(20%) saturate(3405%) hue-rotate(204deg)
          brightness(90%) contrast(98%);
      }
    }
  }

  .flight-search-btn,
  .form-submit-btn {
    padding: 10px 44px !important;
  }

  .tab-heading-bg {
    h3 {
      font-size: 20px !important;
      margin: 0 !important;
    }
  }

  .flight-shadules.flight-result-scroll {
    margin: 0 !important;
  }

  .price-tab.price-tab-show {
    margin: 0 !important;
    text-align: center;
    width: 100% !important;
  }

  .less-seats {
    text-align: center !important;
  }

  #RoundFlight,
  #OneWay,
  #MultiCity,
  #trip-gen {
    .input-decoration,
    .MuiInputBase-input {
      font-size: 13px !important;
      padding: 10px !important;
    }

    .MuiInputBase-input {
      padding-left: 10px !important;
    }

    .MuiInputAdornment-root {
      margin-left: 2px !important;
    }

    .my-switch-icon {
      height: 2rem !important;
      width: 2rem !important;
    }
  }

  .more-explore-btn-container {
    width: 100%;

    .more-explore-btn {
      justify-content: space-between;
      width: 100% !important;
    }
  }

  .flight-book-form {
    padding: 28px 10px 42px 10px !important;
  }

  .counter {
    margin: 0 10px !important;
  }

  .MuiFab-root {
    height: 25px !important;
    width: 36px !important;
  }

  .MuiListItem-padding {
    padding: 10px !important;
  }

  .passengers-box {
    width: 120% !important;
  }

  .hotel-sider-filters .view-map,
  .hotel-view-page-map,
  .hotel-details-heading {
    display: none;
  }

  .choice-sleep-price {
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;

    div {
      width: 100% !important;

      .choices {
        padding: 10px 0 !important;

        div {
          width: 100% !important;

          img {
            min-width: 8% !important;
          }
        }
      }
    }

    :first-child {
      div {
        border: none !important;
      }
    }

    .sleeps-main-wrapper {
      justify-content: space-between !important;

      .sleeps-label {
        display: block;
      }

      .sleeps {
        width: max-content !important;
        padding: 10px 0 !important;

        img {
          min-width: 8% !important;
        }
      }
    }

    .room-count-wrapper {
      border: none !important;
      height: max-content !important;
    }

    .room-count-reserve-row {
      flex-direction: row !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }

  .inner-row-homepage {
    img {
      display: none !important;
    }

    p {
      padding: 10px 0 !important;
    }
  }

  .date-decoration.des-input-decoration {
    padding: 6px !important;
  }

  .hero-form {
    padding: 20px !important;
  }

  .trip-generator-container,
  .flight-search-container,
  .hotel-search-container,
  .flight-book-container {
    padding: 0 !important;

    h1 {
      padding: 0 0.8rem;
    }

    .hero-form,
    .book-flight-form,
    .flight-book-form {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-radius: 0 !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    }
  }

  .flight-book-form {
    padding-bottom: 20px !important;
    margin-bottom: 20px !important;
  }

  .carousal-main-image {
    height: 50vh !important;
    width: 100% !important;
  }

  .policies {
    padding: 20px 10px !important;
    overflow-x: hidden;
  }

  .guest-rating-item {
    p {
      width: 40% !important;
    }

    .mprogress-bar {
      width: 100% !important;
    }
  }

  .guest-rating-column {
    div {
      flex-direction: column !important;
      align-items: start !important;
      gap: 0px !important;
      justify-content: end !important;
    }
  }

  .guest-ratings {
    padding-right: 10px !important;
  }

  .top-cities-card {
    p.mt-3 {
      margin-top: 5px !important;
    }
  }

  .hotel-details {
    padding: 15px 0 !important;
  }

  .user-rating {
    padding: 27px 0 !important;

    .row {
      .profile-dtl {
        width: 95% !important;
      }
    }
  }

  .review {
    padding: 15px !important;

    img {
      width: 100% !important;
    }
  }

  .choice-sleep-price {
    flex-wrap: wrap !important;

    .choice-sleep-price-first {
      min-width: 20%;

      div {
        p {
          text-wrap: wrap !important;
          text-overflow: ellipsis !important;
          overflow: hidden;
        }
      }
    }

    :nth-child(3) {
      .reserve-btn {
        margin: 0 !important;
      }
    }
  }

  .search-wrapper {
    .input-decoration {
      font-size: 12px !important;

      .MuiTypography-root {
        font-size: 14px;
        position: unset;
      }
    }
  }

  .hotel-view {
    .hotels {
      .hotel-view-cards {
        .fs-26 {
          font-size: 16px;
          line-height: 20px;
        }

        p.fs-16 {
          font-size: 12px;
          padding-top: 3px;
        }

        .view-hotel-btn,
        .sold-out-btn {
          font-size: 12px !important;
        }

        .add-compare-wrapper {
          label {
            span {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }

  .book-flight-form {
    padding: 10px !important;
  }
}

@media screen and (max-width: 420px) {
  .passengers-box {
    width: 140% !important;
  }

  .carousal-main-image {
    height: 45vh !important;
    width: 100% !important;
  }

  .view-rating-amenities {
    padding: 19px 5px !important;
  }

  .popular-hotels {
    .popular-hotels-card {
      p.pt-3 {
        padding-top: 5px !important;
      }
    }
  }

  .top-cities {
    .slick-slide {
      .col-3.px-2.mx-1.cursor-pointer {
        padding: 0px !important;
        margin: 0px !important;
      }
    }
  }

  .country-drop-down,
  .currency-drop-down {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .show-filters .hotel-sider-filters {
    min-width: 300px;
    padding: 12px;
  }

  .hotel-card-slider {
    img {
      width: 100% !important;
    }
  }
}

@media (max-width: 1200px) {
  .flight-shadules {
    .baggage-details {
      display: block;
    }
  }
}

@media (min-width: 1201px) {
  .flight-shadules {
    .baggage-details {
      display: none;
    }
  }
}

@media (max-width: 640px) and (min-width: 400px) {
  footer {
    .footer-box {
      justify-content: center !important;
    }
  }
}

@media (max-width: 400px) {
  footer {
    .footer-box {
      flex-direction: column !important;
      gap: 10px;
      justify-content: center !important;
    }
  }
}
