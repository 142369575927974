/* style for tour guide screen */
.tg-main-layout {
  display: flex;
  height: 100vh;
  margin-right: 80px;
}

.sidebar-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-left: 8em;
}

.tg-chat-container {
  flex: 1;
  margin-top: 10px;
  border-radius: 8px;
  height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.tg-chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  border: 1.24px solid #1010121a;
  margin-top: 10px;
  overflow: hidden;
}

.tg-chat-history {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tg-chat-history::-webkit-scrollbar {
  display: none;
}

.msg-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 40px;
  margin: 10px;
}

.ms-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: black;
}

.ms-header span {
  display: flex;
  align-items: center;
}

.ms-header svg {
  margin-right: 5px;
  fill: currentColor !important;
}

.ms-header-text {
  font-family: Inter, sans-serif;
  font-size: 23.14px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

.service-type-title-box {
  background-color: #31449b;
  color: white;
  padding: 12px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px;
  border-radius: 8px;
}

.service-type-title-box span {
  display: flex;
  align-items: center;
}

.service-type-title-box svg {
  fill: currentColor !important;
}

.sidebar {
  flex: 1;
  border: 1px solid #1010121a;
  margin-top: 10px;
  border-radius: 8px;
  height: 90vh;
}

.sidebar-title {
  color: #101012;
  padding: 10px;
  display: flex;
  font-weight: 700;
  font-size: 23.14px;
  margin-left: 5px;
  line-height: 28px;
}

.tg-search-input {
  color: #00000080;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 10px;
}

.tg-search-input input {
  border: none;
  outline: none;
  flex: 1;
}

.tg-search-input svg {
  fill: currentColor !important;
}

.contact {
  padding: 10px;
  display: flex;
}

.contact img {
  width: 40px;
  height: 40px;
  border-radius: 64px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  // height: 40px;
  // width: fit-content;
}

.contact-name {
  font-weight: 700;
}

.contact-msg {
  color: black;
  opacity: 0.7;
}

.tg-chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  border: 1.24px solid #1010121a;
  margin-top: 10px;
}

.tg-chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #1010121a;
}

.tg-chat-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tg-chat-header svg {
  fill: currentColor !important;
}

.tg-chat-header-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.tg-chat-header-center {
  flex-grow: 1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.tg-chat-header-end {
  display: flex;
  align-items: center;
}

.tg-chat-header-help {
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
}

.tg-chat-header-help-text {
  color: #00000080;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 5px;
  position: relative;
}

.tg-chat-header-info span {
  font-weight: 700;
}

.user-status-online {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2ecc71;
  margin-left: 5px;
}

.no-user-selected {
  display: flex;
  align-content: center;
  font-weight: 700;
  align-items: center;
}

.tg-chat-msg {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 10px;
}

.tg-chat-msg.sender {
  justify-content: flex-start;
}

.tg-chat-msg.receiver {
  justify-content: flex-end;
}

.tg-chat-msg.sender img {
  order: -1;
  margin-right: 10px;
}

.tg-chat-msg.receiver img {
  margin-left: 10px;
}

.tg-chat-msg img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tg-chat-msg-content {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.tg-chat-msg-info {
  display: flex;
  flex-direction: column;
}

.tg-chat-msg-info-name {
  font-weight: bold;
}

.tg-chat-msg-info {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
}

.tg-chat-msg-time {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  align-self: flex-start;
}

.tg-chat-msg.sender .tg-chat-msg-info {
  background: #daf8cb;
}

.tg-chat-msg.receiver .tg-chat-msg-info {
  background: #f1f1f1;
}

.tg-chat-body {
  display: flex;
  flex-direction: column;
}

.tg-chat-msg {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.msg-input {
  flex: 1;
  padding-right: 10px;
}

.msg-input input[type='text'] {
  width: 100%;
  border: none;
  outline: none;
  color: black;
}

.msg-input-container button {
  background-color: #1449b2;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  color: white !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg-input-container button:hover {
  background-color: #1449b3;
}

.msg-input-container button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(20, 73, 178, 0.5);
}

.msg-input-container button svg {
  fill: currentColor !important;
}

.tg-file-icon {
  rotate: 45deg;
  color: black;
  cursor: pointer;
  border: 1.24px solid #0000001a;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}

.tg-file-icon svg {
  fill: currentColor !important;
}

input[type='text'] {
  flex: 1;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  cursor: pointer;
}
