* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --downloadAppPrimary: #101012;
  --downloadAppSecondary: #fda400;
  --downloadAppTertiary: #fbfbfd;
  --downloadAppQuaternary: #fda4000d;
}
/* Experience a World of Travel at Your Fingertips SECTION START */
.experience-a-world-of-travel-container {
  display: flex;
  flex-direction: column;
}
.experience-a-world-of-travel-hero-bg1 {
  position: relative;
  width: 100%;
  /* height: 80vh; */
  background-repeat: no-repeat;
  background-size: cover;
}
.experience-a-world-of-travel-hero-bg2 {
  width: 100%;
  position: absolute;
  bottom: 14%;
}
.experience-a-world-of-travel-data-container {
  position: absolute;
  left: 12%;
  bottom: 20%;
  width: 70%;
  margin: 0 auto;
  display: flex;
  gap: 120px;
  justify-content: center;
}
.experience-a-world-of-travel-data-title-info {
  font-size: 48px;
  color: var(--downloadAppPrimary);
  flex-wrap: 700;
  margin-top: 8%;
}
.experience-a-world-of-travel-data-sub-info {
  color: var(--downloadAppPrimary);
  font-size: 18px;
  line-height: 30px;
  margin-top: 16px;
}
/* Experience a World of Travel at Your Fingertips SECTION End */

/* Why Download the Explrar App section start */
.download-the-explrar-app-dots-elements1 {
  position: absolute;
  top: 20%;
  left: -32%;
}
.download-the-explrar-app-dots-elements2 {
  position: absolute;
  top: 50%;
  right: -32%;
}
.download-the-explrar-app-dots-elements3 {
  position: absolute;
  top: 80%;
  left: -32%;
}
.download-the-explrar-app-main-section-container {
  position: relative;
  width: 55%;
  margin: 0 auto;
  margin-bottom: 96px;
  margin-top: -50px;
}
.download-the-explrar-app-section-info {
  display: flex;
  gap: 160px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.download-the-explrar-app-section-header-title {
  text-align: center;
  margin: 170px 0 64px 0;
}
.download-the-explrar-app-text-color {
  color: var(--downloadAppSecondary);
}
.download-the-explrar-app-title-text {
  font-size: 30px;
  font-weight: 700;
  color: var(--downloadAppPrimary);
}
.download-the-explrar-app-sub-title-text {
  font-size: 18px;
  color: var(--downloadAppPrimary);
  font-weight: 30px;
  line-height: 30px;
  margin-top: 19px;
}
/* Why Download the Explrar App section End */

/* Get Started in Seconds section Start   */
.get-started-section-all-data-container {
  background: var(--downloadAppQuaternary);
}
.get-started-section-container {
  padding: 96px 0 96px 0;
  width: 70%;
  margin: 0 auto;
}
.get-started-section--heder-title-data {
  font-size: 44px;
  font-weight: 600;
}
.get-started-section-data-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.get-started-section-sub-data-info-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.get-started-section-data-title-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--downloadAppPrimary);
}
.get-started-section-sub-data-title-text {
  line-height: 24px;
  color: var(--downloadAppPrimary);
  margin-top: 8px;
}
/* Get Started in Seconds section End   */

/* Join Our Community Section Start */
.join-our-community-all-data-section-container {
  background-color: var(--downloadAppTertiary);
  padding: 96px 0 96px 0;
}
.join-our-community-section-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  gap: 72px;
  align-items: center;
  justify-content: center;
}

.progress-blue {
  border: 4px solid #31449b;
  position: absolute;
  height: 70px;
}

.total-progress {
  border: 4px solid rgba(49, 68, 155, 0.12);
  position: relative;
}

.parent-container {
  height: 900px;
  position: relative;
  top: 7px;
}

@media (max-width: 1024px) {
  .join-our-community-section-container {
    flex-direction: column;
    gap: 50px;
  }

  .get-started-section-data-container {
    flex-direction: column;
    gap: 50px;
  }

  .download-the-explrar-app-section-header-title {
    margin: 70px 0px;
  }

  .get-started-section--heder-title-data {
    text-align: center;
  }

  .download-the-explrar-app-main-section-container {
    margin-top: 120px;
  }

  .download-the-explrar-app-dots-elements2,
  .download-the-explrar-app-dots-elements3,
  .download-the-explrar-app-dots-elements1 {
    display: none;
  }

  .download-the-explrar-app-section-info {
    margin: 70px 0px;
    text-align: center;
  }

  .download-the-explrar-app-section-info:nth-last-of-type(odd) {
    flex-direction: column;
    gap: 40px;
  }

  .download-the-explrar-app-section-info:nth-last-of-type(even) {
    flex-direction: column-reverse;
    gap: 40px;
  }

  .get-started-section-sub-data-info-container {
    margin: 40px 0px;
    text-align: center;
  }

  .progress,
  .total-progress {
    display: none;
  }
}

@media (max-width: 1210px) {
  .experience-a-world-of-travel-hero-bg1 {
    display: none;
  }
  .experience-a-world-of-travel-data-container > div:nth-of-type(2) {
    display: none;
  }

  .experience-a-world-of-travel-data-container {
    position: static;
    margin-top: 50px;
  }

  .experience-a-world-of-travel-hero-bg2 {
    top: 325px;
    display: none;
  }

  .parent-container {
    height: auto;
  }

  .experience-a-world-of-travel-data-title-info {
    margin: 0;
  }

  .get-started-hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .get-started-hero {
    width: 80%;
  }
}

.join-our-community-section-title-text {
  font-size: 48px;
  font-weight: 600;
  color: var(--downloadAppPrimary);
}
.join-our-community-section-sub-title-text {
  font-size: 18px;
  color: var(--downloadAppPrimary);
  line-height: 30px;
}

/* Join Our Community Section End */

/* Ready To Explr section start */
.ready-to-explr-main-section-container {
  background-color: #fff;
  margin: 96px 0 96px 0;
}
.ready-to-explr-section-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  gap: 150px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .ready-to-explr-section-container {
    flex-direction: column;
    gap: 50px;
  }
}

.ready-to-explr-section-title-text {
  font-size: 48px;
  font-weight: 600;
  color: var(--downloadAppPrimary);
}
.ready-to-explr-sub-title-text {
  font-size: 18px;
  line-height: 30px;
  color: var(--downloadAppPrimary);
}
/* Ready To Explr section End */
