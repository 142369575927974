.events-dtls {
  .back-btn-position {
    position: absolute;
    top: 32px;
    left: 26px;

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .max-height-60vh {
    max-height: 60vh;
  }

  .brs-8 {
    border-radius: 0 0 8px 8px;
  }

  .event-book-btn {
    padding: 18px 58px;
    background-color: $cl-blue;
    border-radius: 6px;
  }

  .event-location-price {
    border-top: 1px solid #0000000f;
    margin-top: 36px;
    padding: 28px 0px;

    .event-date {
      padding-right: 28px;
      border-right: 1px solid #0000003d;
    }

    .event-location {
      padding: 0 28px;
      border-right: 1px solid #0000003d;
    }
  }
}

.brs-all-8 {
  border-radius: 8px;
}

.musician-event-dtls {
  margin-top: 33px;

  .musician-social-media-dtls {
    top: 20px;

    .musician-dtls {
      padding: 24px 18px 28px 18px;
      background-color: $cl-white;
      border-radius: 8px;
    }

    .social-media-handles {
      padding: 24px 18px 28px 18px;
      background-color: $cl-white;
      border-radius: 8px;
      margin-top: 32px;
    }
  }

  .event-city-location {
    position: sticky;
    top: 20px;
    padding: 24px 18px 28px 18px;
    background-color: $cl-white;
    border-radius: 8px;

    .view-full-map-btn {
      position: absolute;
      bottom: 14px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $cl-orange;
      padding: 11px 18px;
      border-radius: 5px;
      white-space: nowrap;
      color: $cl-white !important;
      outline: none;
      border: none;
    }
  }

  .event-about {
    padding: 24px 18px 28px 18px;
    background-color: $cl-white;
    border-radius: 8px;

    .about-points {
      .list-style-dot {
        width: 12px;
        height: 5px;
        background-color: #101012;
        border-radius: 50%;
        opacity: 70%;
        margin: 10px 10px 0 0;
        display: inline-block;
      }
    }

    .event-organizer-table {
      border: 1px solid #0000001a;
      border-radius: 8px;
      margin: 26px 24px 41px 16px;

      td,
      th,
      tr {
        border: 1px solid #0000001a;
      }

      .py-28 {
        padding-top: 28px;
        padding-bottom: 28px;
      }

      .ps-13 {
        padding-left: 13px;
      }

      .pe-26 {
        padding-right: 26px;
      }

      .ps-41 {
        padding-left: 41px;
      }
    }
  }
}

.book-event-popup {
  padding: 29px 15px 23px 29px;
  border-radius: 8px;
  background-color: $cl-white;
  max-width: 1189px;
  width: 100%;
  box-shadow: 3px 4px 26px 0px #0000000d;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: none;

  .book-event-dtls {
    width: 65%;
    margin: 0 auto;

    .back-arrow {
      position: absolute;
      left: 18px;
    }

    .close-btn {
      position: absolute;
      right: 23px;
    }

    .event-date {
      border-right: 1px solid #0000003d;
    }

    .max-height-425 {
      max-height: 425px;
      padding-right: 18px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $cl-blue;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #10101229;
      }

      .add-pass {
        padding: 26px 35px 22px 26px;
        border-radius: 8px;
        border: 1px solid $cl-orange;

        .pass-add-btn {
          padding: 11px 28px;
          background-color: $cl-orange;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }

    .add-cart-btn {
      padding: 18px 10px;
      border-radius: 6px;
      border: 1px solid $cl-blue;
      width: 100%;
    }

    .proceed-payment-btn {
      background-color: $cl-blue;
      padding: 19px 10px;
      border-radius: 6px;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .book-event-popup {
    width: 90%;
  }
}

@media (max-width: 992px) {
  .events-dtls {
    .back-btn-position {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .event-book-btn {
      padding: 12px 44px;
    }
  }

  .musician-event-dtls {
    .musician-social-media-dtls {
      .social-media-handles {
        margin-top: 20px;
      }
    }

    .event-city-location {
      position: static;
    }
  }
}

@media (max-width: 768px) {
  .events-dtls {
    .event-location-price {
      border-top: 1px solid #0000000f;
      margin-top: 25px;
      padding: 20px 0px;

      .event-date {
        padding-right: 28px;
        border-right: 1px solid #0000003d;
      }

      .event-location {
        padding: 0 28px 0 0;
        border-right: 1px solid #0000003d;
      }
    }
  }

  .book-event-popup {
    .book-event-dtls {
      width: 80%;
    }

    .book-event-dtls {
      .max-height-571 {
        .add-pass {
          padding: 15px;

          img {
            width: 30px;
          }

          .pass-add-btn {
            padding: 5px 20px;
          }
        }
      }

      .add-cart-btn {
        padding: 13px 10px;
      }

      .proceed-payment-btn {
        padding: 14px 10px;
      }
    }
  }
}

@media (max-width: 576px) {
  .events-dtls {
    .back-btn-position {
      position: absolute;
      top: 9px;
      left: 1px;

      svg {
        width: 19px;
        height: 19px;
      }
    }

    .w-sm-100 {
      width: 100%;

      .event-book-btn {
        padding: 8px 44px;
        width: 100%;
      }
    }
  }

  .musician-event-dtls {
    .event-about {
      padding: 14px 9px 28px 9px;

      .event-organizer-table {
        margin: 26px 7px 25px 7px;

        .py-28 {
          padding-top: 18px;
          padding-bottom: 18px;
        }

        .pe-26 {
          padding-right: 15px;
        }

        .ps-41 {
          padding-left: 16px;
        }
      }
    }
  }

  .book-event-popup {
    padding: 40px 12px 17px 12px;
    width: 95%;

    .book-event-dtls {
      width: 100%;

      .back-arrow {
        left: 10px;
        top: 10px;
      }

      .close-btn {
        right: 10px;
        top: 15px;
      }

      .max-height-571 {
        max-height: 430px;
      }

      .event-date {
        border-right: 0;
      }

      .add-cart-btn {
        padding: 9px;
      }

      .proceed-payment-btn {
        padding: 10px;
      }
    }
  }
}

.event-details-map {
  .gm-style-mtc-bbw {
    // display: none;
  }
}

.event-full-map {
  width: 70%;
  height: 100vh;
  background-color: red;
  position: fixed;
  top: 0;
  right: 0;
  margin: auto;
  left: 0;
  z-index: 20002;
}
